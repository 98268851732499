@import 'avalanchesass_base_typography/mixin';
@import 'avalanchesass_object_island';
@import 'avalanchesass_component_button';
@import 'avalanchesass_utility_background';
@import 'title';
@import 'icon';

/*---
title:   Box
name:    component_box
section: Component
---

```example:html
<div class="c-box o-island u-background-cs-c" style="width: 350px;">
  <h3 class="c-box__title c-title c-title--underline c-title--main">Title <div class="c-box__icon"><svg role="img" class="c-icon c-icon--arrow-right"><use xlink:href="#icon-arrow-right"></use></svg></div></h3>
  <div class="c-box__section">
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem. 
    </p>
  </div>
  <div class="c-box__section c-box__actions">
    <a href="#" class="c-button c-box__button">Lorem Ipsum <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
    <a href="#" class="c-button c-box__button">Lorem Ipsum dolor <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
  </div>
</div>
```
*/
.c-box__icon {
  float: right;
  font-size: 2em;
  line-height: 1;
}

.c-box__content {
  line-height: 1.3333333;
}

.c-box__section {
  @include base-typography-whitespace();
}

.c-box__actions {
  * + * {
    margin-top: map-get($spacing, xs);
  }
}
 