.c-data-technical-data {
  td:last-child {
    text-align: right;
  }
}

.node-type-used-equipment {
	.c-page__content {
		.c-field__label {
			font-weight: 600; 
			padding-right: 0.5em;
			display: inline-block;
		}
		.c-field__item {
			display: inline-block;
		}
	}
}