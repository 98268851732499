.c-block-manufacturers-block-1 {
  .c-view__row {
    a {
      display: block; 
    }
  }
}
.c-node-used-equipment__ue-kom-entry-id {
	div {
		display: inline-block; margin-right: 10px;
	}
}

.c-node-pre-built-truck__pre-truck-image {
	.c-node-pre-built-truck__pre-truck-image__item {
		&:not(:first-child) {
			padding: 2px 2px 2px 0;
			display: inline-block;
		}
		&:not(:nth-child(-1n+2)) {
			padding: 2px;
		}
	}
}