.c-view-board-members--block {
  .o-product_grid {
    background: #f5f5f5;
    padding: 1em;
    text-align: center;
    h3 {
      font-size: 0.8em;
      margin-bottom: 0.5em;  
    }
    .c-node-board-member__board-member-job-title, .c-node-board-member__board-member-job-title-2{
      font-size: 0.8em;
    }
  }
}
