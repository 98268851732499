.c-table {
  th {
    text-transform: uppercase;
  }
  td {
    border-bottom: initial;/*!remove*/
  }
  a {
    color: $font-base-color;
  }

  th, td {
    &.views-align-right {
      text-align: right;
    }
  }

}
