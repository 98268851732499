.c-section-bar {
  padding: map-get($spacing, s);
  text-transform: uppercase;
  background: map-get($cs-grey, b);
  &,
  a,
  a:visited {
    color: #222;
  }
}
