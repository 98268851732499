@import 'avalanchesass_component_button';
@import '../object/image_background';
@import 'icon';
@import 'title';

/*---
title:   Advertisement
name:    component_advertisement
section: Component
---

```example:html
<div class="c-advertisement" style="width: 350px;">
  <div class="o-image-background">
    <img class="o-image-background__image" src="https://placeimg.com/350/200/arch" alt="">
    <div class="o-image-background__content">
      <div class="o-image-background__inner o-island">
        <h3 class="c-advertisement__title c-title c-title--main">Lorem Ipsum dolor</h3>
      </div>
    </div>
  </div>
  <a href="#" class="c-button c-button--full-width c-button--font-size-xl c-button--multi-line has-icon"><svg role="img" class="c-button__multi-line-icon c-icon c-icon--arrow-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Lorem Ipsum<br />dolor sit</a>
</div>
```
*/
.c-advertisement__title {
  color: #fff;
  text-shadow: 0 0 0.125em $font-base-color;
  line-height: 1.1;
  font-size: map-get($font-sizes, xl);
}

.c-advertisement {
	.o-image-background {
		img {
			width: 100%;
		}
	}
}

.c-ankuendigung {
	.o-image-background {
		img {
			width: 100%;
		}
	}
}
