.c-icon--angle-double-right {
  width: 1em;
  height: 1em;
}

.c-icon--angle-down {
  width: 1em;
  height: 1em;
}

.c-icon--angle-right {
  width: 1em;
  height: 1em;
}

.c-icon--arrow-right {
  width: 1em;
  height: 1em;
}

.c-icon--clock-o {
  width: 1em;
  height: 1em;
}

.c-icon--download {
  width: 1em;
  height: 1em;
}

.c-icon--envelope-o {
  width: 1em;
  height: 1em;
}

.c-icon--external-link {
  width: 1em;
  height: 1em;
}

.c-icon--help-alt {
  width: 1em;
  height: 1em;
}

.c-icon--home {
  width: 1em;
  height: 1em;
}

.c-icon--layout-grid2-alt {
  width: 1em;
  height: 1em;
}

.c-icon--layout-list-thumb-alt {
  width: 1em;
  height: 1em;
}

.c-icon--link {
  width: 1em;
  height: 1em;
}

.c-icon--map-marker {
  width: 0.706em;
  height: 1em;
}

.c-icon--phone-square {
  width: 1em;
  height: 1em;
}

.c-icon--phone {
  width: 1em;
  height: 1em;
}

.c-icon--world {
  width: 1em;
  height: 1em;
}

.c-icon--user {
  width: 1em;
  height: 1em;
}

.c-icon--yt {
  width: 1em;
  height: 1em;
}
