.is-front,
.page-taxonomy-term,
body[class*="node-type"] {
  // Hide the page title on some content / page types.
  .c-page-title {
    display: none;
  }
}

#flexslider-1 {
  // height: 46.375em;
  overflow: hidden;
}

// Responsive images.
img {
  width: auto;
  max-width: 100%;
  height: auto;
}

.c-bean-hotline {
  a {
    &,
    &:visited {
      color: map-get($cs, a) !important;
    }
  }
}

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

.TEMP-POSTSCRIPT {
  text-align: center;
  background: #000;
}

.c-legal {
  padding: 1em 0;
  background: map-get($cs, a);
  a,
  a:visited {
    color: #fff;
  }
}

/**
 * Flexslider
 */
.slides {
  padding: 0;
}

p:not(:first-child),
ul:not(:first-child),
ol:not(:first-child),
dl:not(:first-child),
img:not(:first-child),
pre:not(:first-child),
form:not(:first-child),
table:not(:first-child),
figure:not(:first-child),
address:not(:first-child),
fieldset:not(:first-child),
blockquote:not(:first-child),
h1:not(:first-child),
h2:not(:first-child),
h3.c-accordion__title:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child) {
  margin-top: 0;
}

.o-grid--xs {
  margin-left: -map-get($spacing, xs) !important;
}

.c-main-hometeaser {
  /*margin-top: 1.5em;*/
}

.path-location body {
  background: green;
}

/* Warum -1.5em gesetzt ist auf o-grid????
Fixes it  */
.o-grid {
  margin-top: 0;
}

#edit-category .c-view__widget--label {
  display: none;
}

.node-type-news #main {
  margin-bottom: 2em;
  @extend .o-container;
  h2 {
    font-size: 1.7em;
  }
  .c-node-news__body {
    margin: 2em 0;
  }
  .file {
    margin-top: 2em;
    display: block;
  }
}

.is-front.domain-kuhn-hu {
  .c-postscript {
    margin-top: 10px;
  }
}

.c-field-collection-item-pg-youtube-video__youtube-embed-code {
  max-width: 35em;
  margin: 0 auto;
}

.c-view-product-main-pages__footer .c-mega-menu__man-img {
  margin-bottom: 35px;
}

.mfp-content .c-node-popup__popup-picture img {
	// padding-left: 40%;
  margin-bottom: .5rem;
}

.node--977479 .c-hero__content {
	display: none;
}


// make a 2 col layout
@media all and (min-width: 1024px) {
	.node--982468,
	.node--982583,
	.node--982470,
	.node--982469,
	.node--982584,
	.node--982585,
	.node--982586 {
		.c-node-page__body {
			width: 50%;
			float: left;
		}
		.c-paragraphs-item-block {
			width: 45%;
			float: left;
			margin-left: 5%;
			margin-top: -60px !important;
		}
	}
}

.oh-display-times {
	padding-left: 3em;
}
