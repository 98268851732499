@import 'avalanchesass_function_strip_unit';

.u-spacing-section {
  margin-top: strip-unit(map-get($spacing, l)) * 1rem !important;
}

.u-spacing-top-xs {
  margin-top: map-get($spacing, xs) !important;
}

.u-spacing-top-s {
  margin-top: map-get($spacing, s) !important;
}

.u-spacing-top-m {
  margin-top: map-get($spacing, m) !important;
}

.u-spacing-base-whitespace {
  @include base-typography-whitespace();
}

.u-spacing-wrap {
  > :not(:first-child) {
    margin-top: map-get($spacing, m) !important;
  }
}
