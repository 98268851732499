.c-mega-menu {
  position: absolute;
  right: 0;
  left: 0;
  min-height: 29em;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
  z-index: 100;
  *:hover > & {
    visibility: visible;
    opacity: 1;
  }
}

.c-mega-menu__main-cat { 
  position: relative;
  text-transform: uppercase;
  border-bottom: 1px solid map-get($cs-grey, b);
}

.c-mega-menu__main-cat-item {
  padding-right: map-get($spacing, s);
  padding-top: map-get($spacing, xs);
  padding-bottom: map-get($spacing, xs);
  &:not(:first-child) {
    padding-left: map-get($spacing, s);
    border-left: 1px solid map-get($cs-grey, b);
  }
}

.c-mega-menu__main-cat-link {
  display: block;
  padding: map-get($spacing, s);
  &,
  &:visited {
    color: #222 !important;
  }
  &.is-active,
  &:hover,
  &:focus {
    color: #fff !important;
    background: map-get($cs, a);
    text-decoration: none;
  }
}

.c-mega-menu__sub-cat {
  position: relative;
  width: 100%;
  padding-right: 100px;
  text-transform: none;
  border-bottom: 1px solid map-get($cs-grey, b);
  &.application-area-list {
    display:none;
  }
}

.c-mega-menu__sub-cat-item {
  display: inline-block;
  padding-top: map-get($spacing, xs);
  padding-bottom: map-get($spacing, xs);
}

.c-mega-menu__sub-cat-link {
  display: block;
  padding: map-get($spacing, xs) map-get($spacing, s);
  &,
  &:visited {
    color: $font-base-color !important;
  }
  &.is-active,
  &:hover,
  &:focus {
    color: #fff !important;
    background: map-get($cs-grey, c);
    text-decoration: none;
  }
}

.c-mega-menu__mini-teaser {
  display: none;
  min-height: 18rem;
  position: relative;
  overflow: auto;
  padding-top: map-get($spacing, m);
  padding-bottom: map-get($spacing, m);
  &.is-active {
    display: block;
  }
  li {
    font-size: map-get($font-sizes, s);
  }
}

.c-view-product-main-pages__footer {
  position: absolute;
  top: 2.5em;
  right: 0;
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  h3, p {
    margin-top: .25em;
    color: black !important;
  }
  a {
    color: map-get($cs, a) !important;
  }
  .c-view-product-main-pages__row {
    width: 240px;
  }
  .c-mega-menu__man-img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 60px;
  }
}
