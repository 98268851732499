.c-page__content {
  @media (min-width: map-get($breakpoints, m)) {
    padding-right: map-get($spacing, m);
  }
}

.c-page__section-bar {
  @media (min-width: map-get($breakpoints, m)) {
    margin-right: map-get($spacing, m) * -1;
  }
}

.c-page__sidebar {
  background: map-get($cs-grey, a);
  @media (min-width: map-get($breakpoints, m)) {
    padding-top: 3.5em;
    padding-right: map-get($spacing, m);
  }
}

.c-page__content {
  padding-bottom: map-get($spacing, xxxl);
}
