.c-view-news.slick--slider {
	
	&:after {
		content: " ";
		width: 79px;
		height:47px;
		background-image: url("../img/icon-drag.png");
		display: block;
    	margin: 0 auto;
    	background-repeat: no-repeat;
	}

  .slick-prev::before, 
  .slick-next::before {
    color: #696969;
  }
}


button.slick-arrow {
  position: absolute;
  top: 192px;
  @media (max-width: map-get($breakpoints, s)) {
		top: 172px;
	} 
}
button.slick-next.slick-arrow {
  z-index:100;
  //background: #e9e9e9 !important;
  left: 0;
  width: 50px;
  height: 50px;
}
button.slick-prev.slick-arrow {
  z-index:100;
  //background: #e9e9e9 !important;
  right: 0;
  width: 50px;
  height: 50px;
}

