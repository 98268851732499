/* Christians take on a mobile menu using mmenu */

#block-menu-block-1 {
  display: none;
}

#block-menu-menu-domain-selector {
	color: #000;
	&>ul {
		padding-left: 40px;
		padding-top: 0;
		&:before {
  		background:url('../logo.png');
  		background-position: center;
  		background-repeat: no-repeat;
  		content: '';
  		width: 100%;
  		height: 60px;
  		display:block;
  		position: relative;
    	z-index: 100;
    	margin: 10px 0 20px 0;
  	}
	}
	ul.c-menu__list>ul.mm-list {
		padding: 0;
		margin-left: 0;
		margin-right: 0;
	}
	.c-menu-domain-selector__link {
		padding: 0 !important;
	}
	.mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
		margin-left: 0 !important;

	}

	li.main-menu-item {
		display: none;
		&.last-main-menu-item {
			margin-bottom: 2em;
		}
		@media (max-width: 720px) {
			display: block;
		}
	}
	.mm-list > li > a, .mm-list > li > span {
		padding-left: 0;
    padding: 10px !important;

	}
}

.c-domain-switcher__selector {
	i {
		display: none;
		font-size: 2em;
	}
	@media (max-width: map-get($breakpoints, m)) {
		.c-domain-switcher__world, .c-domain-switcher__angle-right {
			display: none;
		}
		i {
			display: block;
		}
	}
}

@media (max-width: 720px) {
  body.is-not-front {
    .c-main-header__details {
      height: 80px;
    }
    .c-yt-channel-switcher {
      padding-top: 24px !important;
    }
    .c-main-header__facebook {
      padding-top: 24px !important;
    }
.c-view-locations--block-1 .c-view-locations__content {
 a {
        display: none !important;
        &.u-color--yellow {
          display: block !important;
        }
        &.u-text-transform--uppercase {
          display: block !important;
        }
      }
}
    .c-view-locations__content {
      a {
        //display: none !important;
        &.u-color--yellow {
          display: block !important;
        }
        &.u-text-transform--uppercase {
          display: block !important;
        }
      }
    }
  }
}
