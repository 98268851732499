h3 {
  text-transform: uppercase;
  line-height: 1em;
  margin-top: .75em;
}

// a {
//   font-weight: bold;
//   text-transform: uppercase;
// }
.u-h4--line {
  &:after {
    content: "___";
    display: block;
    color: map-get($cs, a);
    margin-bottom: 1em;
  }
}

.c-paragraphs-item-section,
.c-paragraphs-item-teaser-text-only {
  margin-top: 4em;
  margin-bottom: 4em;
}

.c-paragraphs-item-teaser-text-only {
  text-align: center;
}

.c-paragraphs-item-teaser-text-only__pg-headline {
  margin-top: 0;
}

.slick--slider {
  padding-top: 2em;
  padding-bottom: 0;
  cursor: move;
}

.c-block-news-block,
.c-block-news-block-carousel-1 {
  position: relative;
  margin-top: 3em;
  background: map-get($cs-grey, a) url('../img/icon-drag.png') bottom center no-repeat;

  &:after {
    position: absolute;
    top: 0;
    content: " ";
    background: url(../img/greyshadow.png) top left repeat-y;
    width: 113px;
    height: 100%;
  }
}

.c-block-news-block,
.c-block-news-block-carousel-2 {
  position: relative;
  margin-top: 3em;
  background: map-get($cs-grey, a) url('../img/icon-drag.png') bottom center no-repeat;

  &:after {
    position: absolute;
    top: 0;
    content: " ";
    background: url(../img/greyshadow.png) top left repeat-y;
    width: 113px;
    height: 100%;
  }
}

.c-news__item-wrapper--right {
  margin-right: 1em;
}

.c-news__item-wrapper--left,
.c-news__item-wrapper--right {
  float: left;
  cursor: default;

  .c-node-news__body {
    text-align: right;
  }
}

.c-news__item-wrapper--right {
  background: #fff;
  border-bottom: 3px solid map-get($cs, a);
  width: 20em;
  padding: 1em;
}

.c-news__header {
  margin-bottom: 2em;
}

.u-color--black {
  color: black !important;
}

.c-news__item--body {
  .c-file__icon {
    display: none;
  }

  a {
    color: #000 !important;
  }
}

.c-news__item--download {
  height: 1em;
}

.c-node-news__news-date {
  font-size: .85em;
  margin-top: .5em;
}

.tipsy {
  font-size: 1em;
}

.u-text-align--right {
  text-align: right !important;
}

.node-type-landing-page {
  .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed {
    h4 {
      @extend .u-h4--line;
    }

    /*
    .o-grid__item:first-child > .c-paragraphs-item-textarea:first-child {
      text-align: left;
    }
    */
    .c-paragraphs-item-textarea:last-child {
      text-align: center;
    }

    .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty {
      background: url(../img/checkmark.png) center center no-repeat;
    }

    .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child .o-grid__item:first-child {
      h2 {
        margin-top: 64px;

        &:before {
          content: " ";
          position: absolute;
          margin-top: -64px;
          margin-left: -18px;
          width: 64px;
          height: 64px;
          background: url(../img/clock.png) center center no-repeat;
        }
      }
    }

    .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child {
      h2 {
        margin-top: 64px;

        &:before {
          content: " ";
          position: absolute;
          margin-top: -64px;
          margin-left: -18px;
          width: 64px;
          height: 64px;
          background: url(../img/tools.png) center center no-repeat;
        }
      }
    }
  }
}

.mm-menu.mm-right {
  background: white;

  .c-menu__list {

    a,
    span {
      color: black !important;
    }
  }

  .c-menu-domain-selector__item {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.mm-menu .mm-list>li>a.mm-subopen:after,
.mm-menu .mm-list>li>a.mm-subclose:before {
  border-color: rgba(0, 0, 0, 1) !important;
}

.mm-menu.mm-right {
  .c-menu-domain-selector__item .c-menu__item--level2 {
    padding: 0;

    a {
      padding: 0;
      padding-left: 10px;
      color: map-get($cs, a) !important;
    }
  }

  min-width: 140px !important;
  max-width: 300px !important;
}

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -moz-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  -o-transform: translate(-80%, 0);
  transform: translate(-80%, 0);
}

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0);
  }
}

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-300px, 0) !important;
    -moz-transform: translate(-300px, 0) !important;
    -ms-transform: translate(-300px, 0) !important;
    -o-transform: translate(-300px, 0) !important;
    transform: translate(-300px, 0) !important;
  }
}

.c-homehighlights .o-grid {
  h2 {
    @extend .c-title--underline;
    @extend .c-title--main;
  }
}

#gmapjs2 {
  height: 500px;
}

.u-text-align--center {
  text-align: center !important;
}

.u-text-transform--uppercase {
  text-transform: uppercase !important;
}

.c-input--address {
  input {
    width: 80%;
    max-width: 500px;
  }
}

.c-node-location {
  font-style: normal;

  a {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.c-view-locations {
  margin-bottom: 0em;
}

.u-width-3_at_m {
  @extend .u-width-3\@m;
}

.u-width-4_at_m {
  @extend .u-width-4\@m;
}

.u-width-5_at_m {
  @extend .u-width-5\@m;
}

.u-width-6_at_m {
  @extend .u-width-6\@m;
}

.u-width-7_at_m {
  @extend .u-width-7\@m;
}

.u-width-8_at_m {
  @extend .u-width-8\@m;
}

.u-width-9_at_m {
  @extend .u-width-9\@m;
}

.u-spacing-bottom--xl {
  margin-bottom: 3em;
}

.u-spacing-top--xl {
  margin-top: 3em;
}

.u-color--yellow {
  color: map-get($cs, a) !important;
}

.group-footer-map {
  max-width: 100%;
  max-height: 265px;
}

.c-block-locations-footer,
.c-block-locations-footer-group {
  background: #000;
  color: #fff;

  a {
    color: #fff;
  }

  .c-title {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: .5em;
    color: #fff;
  }

  .u-vertical-align--center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.c-officehours {
  margin-top: 1em;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin-bottom: 1em;
  padding-top: .75em;
  padding-bottom: .75em;
}

.oh-display {
  padding-left: 1.5em;
}

.oh-wrapper:before {
  position: absolute;
  content: "\f017";
  font-family: FontAwesome;
}

.u-display-inline,
.u-display-inline-block {
  display: inline-block;
}

.c-field__label {

  .u-display-inline,
  .u-display-inline-block {
    font-weight: bold;
  }
}

.c-view-locations--page-all {
  margin-bottom: 3em;
}

.is-front .c-main-footer {
  margin-top: 1.5em;
}

.c-page {
  background: #fff;
}

.c-main-toolbar {
  background: #000;
  height: 40px;
  display: flex;

  ul {
    li {
      float: left;
      list-style-type: none;
      padding-bottom: 0;

      &:hover {
        background: map-get($cs, a);
      }

      a {
        display: inline-block;
        border-right: 1px solid #f3f3f3;
        padding: 1em;
        padding-top: .5em;
        padding-bottom: .5em;
        color: white;
      }
    }
  }
}

.u-position-fixed {
  position: fixed;
}

.c-node-product-highlight__link {
  @extend .u-text-transform--uppercase;
  font-weight: bold;
}

.c-view-used-equipment {
  .c-view__table td:nth-child(2) .c-field {
    margin-top: 24px;
    display: block !important;
  }
}

.c-used-equipment {

  .c-page__sidebar {
    text-align: right;
    padding: 1.3em;
  }

  .c-node-used-equipment__ue-gallery {
    margin-top: 1em;
  }

  .c-node-used-equipment__ue-gallery__item {
    float: left;
    border: 1px solid #ccc;
    background: #fff;
    margin: .1em;
    padding: .1em;
  }

  .c-field__label {
    text-transform: uppercase;
  }

  .c-node-used-equipment__ue-manufacturer,
  .c-node-used-equipment__title-field {
    display: inline;

    &:after {
      content: " ";
      display: block;
    }

    @extend .c-h2;
  }

  .c-node-used-equipment__title-field {
    &:after {
      content: " ";
      display: block;
    }
  }

  .c-node-used-equipment__ue-fl-liftingheight,
  .c-node-used-equipment__ue-fl-capacity,
  .c-node-used-equipment__ue-fl-techcond,
  .c-node-used-equipment__ue-fl-optcond,
  .c-node-used-equipment__ue-hours,
  .c-node-used-equipment__ue-year {
    display: inline-block;

    .c-field__item {
      font-weight: bold;
      margin-right: .5em;
    }
  }

  .c-node-used-equipment__ue-fl-dealerprice {
    font-weight: bold;
    @extend .c-h3;
  }

  .group-specs-table {
    margin-top: 1.5em;
    display: table;

    .c-field {
      display: table-row;

      .c-field__label,
      .c-field__item {
        display: table-cell;
      }

      .c-field__item {
        font-weight: bold;
        text-align: right;
      }
    }
  }
}

.c-node-product__product-brochure__label {
  margin-top: 1em;
}

.node-type-product {
  .group-specs-table {
    span.file {
      display: block;
    }

    margin-top: 1.5em;
    display: table;

    .c-field {
      display: table-row;

      .c-field__label,
      .c-field__item {
        display: table-cell;
      }

      .c-field__item {
        font-weight: bold;
        text-align: right;
      }
    }
  }
}

.c-table {

  .c-node-used-equipment__ue-fl-capacity,
  .c-node-used-equipment__ue-hours,
  .c-node-used-equipment__ue-fl-dealerprice,
  .c-node-used-equipment__ue-fl-custprice {
    text-align: right;
  }

  .c-view__field--view-node {
    font-weight: bold;
  }

  th {
    border-bottom: 3px solid #000;
    padding: 0 .5em;
  }

  .c-node-used-equipment__ue-year {
    text-align: center;
  }
}

.c-view__pager {
  text-align: center;
  border-top: 2px solid #ccc;
}

.c-pager {
  display: inline-block;
  text-align: center;
  height: 2em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 3em;

  .c-pager__item {
    float: left;
    list-style-type: none;
    margin-right: .75em;
    padding: .5em;
    background: #f3f3f3;
  }

}

.is-not-front .c-main-content {
  @media (min-width: map-get($breakpoints, m)) {
    margin-bottom: 1.5em;
  }
}


.c-page__sidebar {
  padding-bottom: 1.5em;
}

#mm-blocker {
  background: rgba(0, 0, 0, 0.7) !important;
}

.septem-menu {
  z-index: 10000 !important;
}

.c-mega-menu__sub-cat-link {
  color: #4e4e4e !important;
}

.flexslider {
  position: relative;
}

.flex-direction-nav {
  position: absolute;
  top: 40%;
  width: 100%;

  li {
    list-style-type: none;
  }

  .flex-nav-prev {
    position: absolute;
    left: 20px;
    color: #fff;
    visibility: hidden;

    & a:before {
      visibility: visible;
      position: absolute;
      z-index: 0;
      left: 0px;
      font-family: "Themify";
      content: "\e64a";
      font-size: 2em;
      color: white;
    }
  }

  .flex-nav-next {
    position: absolute;
    visibility: hidden;
    right: 20px;

    & a:after {
      visibility: visible;
      position: absolute;
      z-index: 0;
      right: 0px;
      font-family: "Themify";
      font-size: 2em;
      color: white;
      content: "\e649";
    }

    color: #fff;
  }
}

.c-hero__sec-title,
.c-hero__main-title,
.c-hero__third-title,
.c-slideshow__title {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 700;
  line-height: 1;
  background: rgba(0, 0, 0, .6);
  display: inline;
  padding: .1em;
  padding-right: .3em;
  padding-left: .3em;
  float: left;
  clear: both;
}

.c-hero__content {
  @media (max-width: map-get($breakpoints, m)) {
    position: absolute;
    bottom: 80px;
  }

  .c-slideshow__title {
    @media (max-width: map-get($breakpoints, m)) {
      font-size: 2em;
    }
  }
}

.c-hero__sec-title {
  margin-top: 0.2em;
}

.c-page__sidebar {
  .c-paragraphs-item-info-box a {
    font-weight: bold;
  }
}

// .domain-kuhn-at.is-front {
//   .c-main-menu {
//     display: none;
//   }
// }

.c-mega-menu__imc {
  position: absolute;
  right: 1em;
  top: 1em;
  text-align: right;
  z-index: 10;

  img {
    transform: rotate(10deg);
    width: 75%;
  }
}

.c-block--exp-used-equipment-block-1 {
  padding: 1em;
  padding-top: 0;
  margin-top: 1em;
  margin-bottom: .5em;

  .c-view__widget--filter-field_ue_kom_komatsu_category_tid {
    display: inline-block;
    margin: .5em;
  }
  
  .c-form__item--country {
		label {
margin-top:1rem;
font-size:20px;
    }
    select {
    font-size: 24px;
    padding: 0.25rem;
    margin-top: 1.1rem;
    margin-left: 2rem;
    /* width: 100%; */
    padding-left: 0.5rem;
    padding-right: 3rem;
    font-weight: bold;
    }
	}

  .c-view__widget--submit {
    display: inline-block;
  }
}

.c-paragraphs-item-download-__pg-download {
  margin-top: 2em;
}

.group-specs-table {
  width: 100%;
}

.c-paragraphs-item-download-__pg-download__item,
.group-specs-table .file {
  background: #f3f3f3;
  margin: .5em;
  margin-left: 0;
  padding: .5em;
  padding-left: 1em;
  padding-right: 1em;

  span.file-size {
    float: right;
  }
}

.u-float--right {
  float: right !important;
}

.u-text-transform--lowercase {
  text-transform: lowercase;

  &:before {
    content: "(";
  }

  &:after {
    content: ")";
  }
}

.c-block--exp-used-equipment-block-2 {
  padding: 2em;
  margin-bottom: 2em;

  input {
    padding: .2em;
    width: auto;
    max-width: auto;
  }

  .c-view__widget--label,
  .c-form__label {
    width: 19%;
    font-weight: bold;
    text-transform: capitalize;
  }
}

.c-view__widget--filter-field_ue_fl_masttype_value>label,
.c-view__widget--filter-field_ue_fl_enginetype_value>label,
.c-form__item--field-ue-fl-liftingheight-value-max>label,
.c-form__item--field-ue-fl-capacity-value-max>label {
  display: none;
}

.c-view__widget--filter-field_ue_fl_capacity_value,
.c-view__widget--filter-field_ue_fl_liftingheight_value {
  text-transform: lowercase;

  input {
    width: 78%;
  }

  .bef-select-as-checkboxes,
  .c-form__item--textfield {
    display: inline-block;
  }
}

.c-form__item--bef-checkbox {
  display: inline-block;
  padding: .5em;
}

.vertical-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.u-border-bottom--gray {
  border-bottom: 1px solid #ccc;
  padding: 1em;
  padding-bottom: 2em;
}

.u-list-indent {
  li {
    padding-left: 2em;
    margin-left: 1em;
  }
}

.o-media__figure-rev {
  order: 1;
}

.c-field-collection-item-pg-accordion-items__content-blocks__item {
  margin-bottom: 2em;
}

.c-node-job__job-category {
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 1px solid #ccc;
  margin-top: 2em;
}

.c-node-job {
  background: map-get($cs-grey, a);
  padding: 1em;
  padding-bottom: .5em;
  margin-bottom: .5em;
  margin-top: 1.5em;
  border-bottom: 1px dotted #ccc;
}

.c-node-job:last-child {

  border: 0;
}

.c-node-job__job-download {
  padding: 1em;
  margin-top: 1.5em;
  border-top: 1px solid map-get($cs, a);
}

.c-node-location__location-contact-person {
  .c-location-contact-person-label {
    display: inline-block;
  }

  .c-location-contact-person-data {
    float: right;
  }
}

.c-node-location__location-contact-person__label {
  display: block !important;
}

.c-node-location__contact-persons-coll__item {
  clear: both;

  .c-field {
    float: left;
    margin-right: 3px;
  }

  .c-field-collection-item-contact-persons-coll__cp-position {
    font-weight: 700;

    &:after {
      content: ": ";
    }
  }

  .c-field-collection-item-contact-persons-coll__cp-tel {
    float: right;
  }

  .c-field-collection-item-contact-persons-coll__cp-email {
    clear: both;
  }
}

.c-node-location__location-office-hours {
  clear: both;
  margin-top: 1em;
}

.c-bean-global-blocks__content-blocks-sidebar {
  border: 10px dashed red !important;

}

.c-block-locations-footer .oh-display {
  display: inline-block;
}

.c-view-pre-built-trucks--block h3 {
  float: none;
  width: 100%;
  clear: both;
}

.c-view-pre-built-trucks--block .c-field__label {
	padding-right: 5px;
	font-weight: 600;
}

.c-view-pre-built-trucks--block .c-node-pre-built-truck__body {
	min-height: 85px;
}
.c-node--pre-built-truck {
  .c-field__label {
    font-weight: bold;
		padding-right: 5px;
  }

  .c-node-pre-built-truck__pre-ft-download {
    margin-top: 5px;
  }

  .c-node-pre-built-truck__pre-fc-download {
    margin-top: 5px;
  }

  .c-node-pre-built-truck__pre-fs-download {
    margin-top: 5px;
  }
}


.node-type-product .o-media__body table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left;


  tr:nth-child(odd) {
    background-color: #F5F5F5;
  }

  td:first-child {
    font-weight: 700;
    padding: 0.5em 1em;
  }

  a {
    color: $font-base-color;
  }
}

.c-page__main-content .c-view__table {
  margin-top: 2em;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap;

  td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    div {
      display: inline-block;
    }
  }
}

.c-used-equipment--cranes .c-view__table {

  td,
  th {
    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 40%;
    }

    &:nth-child(3) {
      width: 18%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 8%;
      text-align: center;
    }

    &:nth-child(5) {
      width: 20%;
      text-align: right;
    }
  }
}

.c-used-equipment--forklifts .c-view__table {

  td,
  th {
    &:nth-child(1) {
      width: 20%;
    }

    &:nth-child(2) {
      width: 30%;
    }

    &:nth-child(3) {
      width: 10%;
      text-align: center;
    }

    &:nth-child(4) {
      width: 12%;
      text-align: center;
    }

    &:nth-child(5) {
      width: 8%;
      text-align: right;
    }

    &:nth-child(6) {
      width: 20%;
      text-align: right;
    }
  }
}

.c-view-used-equipment__content tbody tr:hover {
  background: map-get($cs, c);
  cursor: pointer;
}

.c-paragraph-admin-info {
  display: none;
}

div[class*="c-paragraphs"]:hover {
  .c-paragraph-admin-info {
    position: fixed;
    width: 200px;
    z-index: 1000;
    right: 20px;
    top: 20px;
    opacity: .95;
    border-radius: 16px;
    background: black;
    color: white;
    padding: 1em;
    display: block;
    font-size: 1rem;
  }
}

.c-page__main-content .c-mini-teaser-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  width: 100%;

  li {
    margin: 1%;
    text-align: center;
    box-sizing: border-box;

  }
}

.c-page__main-content .c-mega-menu__add {
  display: none;
}



.contextual-links-region {
  position: relative;
}

.contextual-links-wrapper {
  display: none;
}

.c-page__c-page__main-content .contextual-links-wrapper {
  display: none !important;
  visibility: hidden;
}

.contextual-links-wrapper {
  display: none;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  padding: .5em;
  background: black;
  border-radius: 1em;
  opacity: .8;

  a {
    color: white;
  }

  .c-link-list__item--views-ui-edit,
  .c-link-list__item--node-delete,
  .c-link-list__item--block-configure,
  .c-link-list__item--manage-display {
    display: none;
  }

}

.c-paragraphs-item-info-box__pg-info-box-download.c-button {
  .c-file__link {
    color: white;
  }

  img,
  .c-file__size {
    display: none;
  }
}

.c-block-menu-domain-selector {
  .mm-menu.mm-vertical .mm-list>li>a.mm-subopen {
    padding-top: 6px;
  }

  .mm-menu.mm-vertical .mm-list>li>a.mm-subopen:after {
    top: 8px;
  }

}

.slick-track {
  padding-right: 2em;
}

.ss-medium .ss-circle {
  width: 250px;
  height: 150px;
  box-shadow: none;
}

.c-pg-youtube-video-item .c-paragraphs-item-pg-video-carousel__pg-youtube-video__item {
  display: inline-block;
  width: 33%;
  padding: 1%;

  @media (max-width: map-get($breakpoints, m)) {
    width: 100%;
  }
}


.ss-not-mobile {
  .c-node-history__history-year {
    display: none;
  }
}

@media (max-width: map-get($breakpoints, m)) {
  .ss-not-mobile {
    display: none;

    .c-node-history__history-year {
      display: none;
    }
  }

  .ss-mobile {
    text-align: center;
    margin-bottom: 1.5em;

    .c-node-history__history-year {
      display: none;
    }

  }
}

#mc_embed_signup {
  input {
    margin-bottom: .5em;
  }
}


#views-exposed-form-used-equipment-block-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-view__widget--filter-field_ue_ref_domain_tid .c-view__widget--label {
  display: none;
}

.c-view__widget--filter-field_ue_ref_domain_tid {
  margin-top: 0 !important;
}

.c-form__item--category label {
  margin-top: 0 !important;
  display: block;
}

.c-form__item--category select {
  width: 70px;
}

div#edit-field-ue-ref-domain-tid {
  margin-top: 0 !important;
}

.c-yt-channel-switcher {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .ti-youtube {
    font-size: 36px;

  }
}

.c-main-header__facebook {
  a {
    &:hover {
      text-decoration: none;
    }
  }

  .ti-facebook {
    font-size: 36px;

  }
}


.configure-badge {
  /* -webkit-transform: rotate(-3deg); */
  /* transform: rotate(-3deg); */
  background-color: #ff9a25;
  color: white;
  padding: 0.5rem;
  /* border-radius: 30px; */
  /* right: 0; */
  font-size: 1rem;
  /* right: 0; */
  margin: 1.5rem 0;
  display: inline-block;
  // float:right;

  // margin-left: 1rem;

  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;


  /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2); */
  &:visited {
    color: white;
  }
}

.inquiry-button {
  position: fixed;
  bottom: 65px;
  right: 0;
  background: #ff9a25;
  width: 154px;
  text-align: left !important;
  height: auto;
  color: white;
  padding: 10px;
  z-index: 100;
  text-transform: uppercase;
  transition: all;
  transform: translateX(114px);
  transition-duration: 300ms;
  &:hover {
    transform: translateX(0px);
    text-decoration: none;
  }
}

.mx-auto {
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.rounded-full {
  border-radius: 9999px;
  overflow: hidden;
}

.mt-4 {
  margin-top: 1rem;
}
.mb-4 {
  margin-bottom: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.c-node-sales-person__zipcodes__label {
  font-weight: bold;
}

.sliding-popup-bottom {
box-shadow: 0 -200px 15000px 50000px rgba(0,0,0,.5);
}


.c-page__main-content .c-mini-teaser-list {
	justify-content: space-between;
  padding-right: 0 !important;
}
.c-node-manufacturer .c-node-manufacturer__work-area {
display: none;
}

.node-type-manufacturer {
	.c-node-manufacturer__title-field {
		a {
			color: #fff;
		}
	}
}
.node-type-manufacturer {
	article {
		.c-node-manufacturer__title-field {
			a {
				color: #000;
			}
		}
	}
}

/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v21-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v21-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-v21-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}