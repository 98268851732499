/*---
title:   Accordion
name:    component_accordion
section: component
---

```example:html
<div class="c-accordion">
  <div class="c-section-bar c-accordion__header is-active js-accordion-header">
    <h3 class="c-accordion__title">Default Hydraulikbagger</h3>
  </div>
  <div class="c-accordion__body is-open">
    Lorem Ipsum dolor sit amet.
  </div>
  <div class="c-section-bar c-accordion__header js-accordion-header">
    <h3 class="c-accordion__title">Default Hydraulikbagger</h3>
  </div>
  <div class="c-accordion__body">
    Lorem Ipsum dolor sit amet.
  </div>
</div>
```
*/
.c-accordion__header {
  .has-js & {
    cursor: pointer;
  }
  &:not(:first-child) {
    margin-top: map-get($spacing, xs);
  }
}

.c-accordion__title {
  margin-top: 0;
}

.c-accordion__icon {
  .c-accordion__header.is-active & {
    transform: rotate(90deg);
  }
}

.c-accordion__body {
  padding-top: map-get($spacing, l);
  padding-bottom: map-get($spacing, l);
  .has-js & {
    display: none;
  }
  &.is-open {
    display: block;
  }
}
