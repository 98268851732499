@charset "UTF-8";
/*---
title: kuhn-gruppe.com
---

This is the style guide for kuhn-gruppe.com
*/
/*---
title: Function
---

Custom SASS functions.
*/
/* No files to import found in function//*.scss */
/**
 * Variable
 *
 * If you want to **only** import the variables from a package import them here.
 */
/*---
title:   strip-unit
name:    function_strip_unit
section: Function
---

Strip units from numbers.

```css
strip-unit($-number)
```
```css
.foo {
  line-height: strip-unit(map-get($font-sizes, m));
}
```
*/
/*---
title:   breakpointmax
name:    function_breakpointmax
section: Function
---

Get the max-width value of a breakpoint.

```css
breakpointmax($-breakpoint[, $-base: $font-base-size])
```
```css
@media (max-width: breakpointmax(map-get($breakpoints, m))) {
  .foo { }
}
@media (max-width: breakpointmax(map-get($breakpoints, m), 14px)) {
  .foo { }
}
```
*/
/*---
title:   map-get-deep
name:    function_map_get_deep
section: Function
---

Get values from deep nested maps.

```css
map-get-deep($-map, $-keys...)
```
*/
/*---
title:   Variable
name:    variable
---

Basic system variables.
*/
/*---
title:   Color scheme
name:    variable_color_scheme
section: variable
---

It's mandatory to use color scheme variables
whenever you use colors in your styles.
Generator: [coolors.co](http://coolors.co/).
*/
/*---
title:   Basic
name:    variable_color_scheme_basic
section: variable_color_scheme
---

```example:color
@color: #ff9a25 @name: map-get($cs, a)
@color: #F4C93B @name: map-get($cs, b)
@color: #FFF4DA @name: map-get($cs, c)
```
```example:color
@color: #fff @name: map-get($cs-contrast, a)
@color: #fff @name: map-get($cs-contrast, b)
@color: #4e4e4e @name: map-get($cs-contrast, c)
```
*/
/**
 * Alerts
 */
/*---
title:   Alerts
name:    variable_color_scheme_alerts
section: variable_color_scheme
---

```example:color
@color: #468847 @name: map-get($cs-alerts, positive)
@color: #3A87AD @name: map-get($cs-alerts, neutral)
@color: #c09853 @name: map-get($cs-alerts, cautious)
@color: #b94a48 @name: map-get($cs-alerts, negative)
```
*/
/**
 * Grey
 *
 * 1. You must not use this directly in your styles!
 */
/*---
title:   Grey
name:    variable_color_scheme_grey
section: variable_color_scheme
---

```example:color
@color: #F5F5F5 @name: map-get($cs-grey, a)
@color: #E9E9E9 @name: map-get($cs-grey, b)
@color: #898989 @name: map-get($cs-grey, c)
```
*/
/*---
title:   Typography
name:    variable_typography
section: variable
---
*/
/**
 * 1. Must be in px, do not use in your styles.
 * 2. You must not use this directly in your styles!
 */
/**
 * Font sizes
 */
/*---
title:   Font sizes
name:    variable_typography_font_sizes
section: variable_typography
---

<div style="font-size: 0.64em;">map-get($font-sizes, xs)</div>
<div style="font-size: 0.8em;">map-get($font-sizes, s)</div>
<div style="font-size: 1em;">map-get($font-sizes, m)</div>
<div style="font-size: 1.25em;">map-get($font-sizes, l)</div>
<div style="font-size: 1.563em;">map-get($font-sizes, xl)</div>
<div style="font-size: 1.953em;">map-get($font-sizes, xxl)</div>
<div style="font-size: 2.441em;">map-get($font-sizes, xxxl)</div>
*/
/*---
title:   Fonts
name:    variable_typography_fonts
section: variable_typography
---

Font sizes and weights are defined globally to ensure a consistent look.
*/
/**
 * If you use webfonts set the font weight variables according to the font weights the font provides.
 * E.g. you use a Google font like http://fonts.googleapis.com/css?family=Source+Sans+Pro:200,400,700
 * In this case set the font weights to 200, 400 and 700.
 * If you set a font weight in your css you must use this variables!
 */
/*---
  title:   Font A
  name:    variable_typography_fonts_font_a
  section: variable_typography_fonts
  ---

  <p style="font-family: 'Source Sans Pro', sans-serif;">
  **'Source Sans Pro', sans-serif**
  ABCDEFGHIJKLMNOPQRSTUVWXYZ
  abcdefghijklmnopqrstuvwxyz
  0123456789!?#%&$@*{(/|\)}</p>
  */
/**
 * Breakpoints
 *
 * Infos about media queries: [Brad Frost - 7 Habits of highly effective media queries](http://bradfrost.com/blog/post/7-habits-of-highly-effective-media-queries/)
 */
/**
 * min Breakpoints
 */
/**
 * max Breakpoints
 *
 * Use with care, min-width breakpoints should be preferred!
 */
/**
 * Spacing
 */
/**
 * Namespace
 */
/* No files to import found in base//*variable.scss */
.o-grid__item.u-width-3\@s {
  width: 25% !important; }

.o-grid__item.u-width-9\@s {
  width: 75% !important; }

.o-grid__item.u-width-20 {
  width: 20% !important; }

/**
 * Mixin
 *
 * If you want to **only** import the mixins from a package import them here.
 */
/*---
title: Base
---

Base styles like typography and other global default styles
(mostly unclassed HTML elements).
*/
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/**
     * 1. Set default font family to sans-serif.
     * 2. Prevent iOS and IE text size adjust after device orientation change,
     *    without disabling user zoom.
     */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
     * Remove default margin.
     */
body {
  margin: 0; }

/* HTML5 display definitions
       ========================================================================== */
/**
     * Correct `block` display not defined for any HTML5 element in IE 8/9.
     * Correct `block` display not defined for `details` or `summary` in IE 10/11
     * and Firefox.
     * Correct `block` display not defined for `main` in IE 11.
     */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
     * 1. Correct `inline-block` display not defined in IE 8/9.
     * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
     */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Prevent modern browsers from displaying `audio` without controls.
     * Remove excess height in iOS 5 devices.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
       * Address `[hidden]` styling not present in IE 8/9/10.
       */
[hidden] {
  display: none; }

/**
     * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
     */
template {
  display: none; }

/* Links
       ========================================================================== */
/**
       * Remove the gray background color from active links in IE 10.
       */
a {
  background-color: transparent; }

/**
     * Improve readability of focused elements when they are also in an
     * active/hover state.
     */
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
     */
abbr[title] {
  border-bottom: 1px dotted; }

/**
     * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
     */
b,
strong {
  font-weight: bold; }

/**
     * Address styling not present in Safari and Chrome.
     */
dfn {
  font-style: italic; }

/**
     * Address variable `h1` font-size and margin within `section` and `article`
     * contexts in Firefox 4+, Safari, and Chrome.
     */
h1 {
  font-size: 2em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin: 0.75em 0; }

/**
       * Address styling not present in IE 8/9.
       */
mark {
  background: #ff0;
  color: #000; }

/**
     * Address inconsistent and variable font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` affecting `line-height` in all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
       ========================================================================== */
/**
       * Remove border when inside `a` element in IE 8/9/10.
       */
img {
  border: 0; }

/**
     * Correct overflow not hidden in IE 9/10/11.
     */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
       ========================================================================== */
/**
       * Address margin not present in IE 8/9 and Safari.
       */
figure {
  margin: 1.5em 40px; }

/**
     * Address differences between Firefox and other browsers.
     */
hr {
  box-sizing: content-box;
  height: 0; }

/**
     * Contain overflow in all browsers.
     */
pre {
  overflow: auto; }

/**
     * Address odd `em`-unit font size rendering in all browsers.
     */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
       ========================================================================== */
/**
     * Known limitation: by default, Chrome and Safari on OS X allow very limited
     * styling of `select`, unless a `border` property is set.
     */
/**
     * 1. Correct color not being inherited.
     *    Known issue: affects color of disabled elements.
     * 2. Correct font properties not being inherited.
     * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
     * 4. Address `font-family` inconsistency between `textarea` and other form in IE 7
     * 5. Improve appearance and consistency with IE 6/7.
     */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
     * Address `overflow` set to `hidden` in IE 8/9/10/11.
     */
button {
  overflow: visible; }

/**
     * Address inconsistent `text-transform` inheritance for `button` and `select`.
     * All other form control elements do not inherit `text-transform` values.
     * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
     * Correct `select` style inheritance in Firefox.
     */
button,
select {
  text-transform: none; }

/**
     * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
     *    and `video` controls.
     * 2. Correct inability to style clickable `input` types in iOS.
     * 3. Improve usability and consistency of cursor style between image-type
     *    `input` and others.
     * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
     *    Known issue: inner spacing remains in IE 6.
     */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
     * Re-set default cursor for disabled elements.
     */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
     * Remove inner padding and border in Firefox 4+.
     */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
     * Address Firefox 4+ setting `line-height` on `input` using `!important` in
     * the UA stylesheet.
     */
input {
  line-height: normal; }

/**
       * It's recommended that you don't attempt to style these elements.
       * Firefox's implementation doesn't respect box-sizing, padding, or width.
       *
       * 1. Address box sizing set to `content-box` in IE 8/9/10.
       * 2. Remove excess padding in IE 8/9/10.
       * 3. Remove excess padding in IE 7.
       *    Known issue: excess padding remains in IE 6.
       */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Fix the cursor style for Chrome's increment/decrement buttons. For certain
     * `font-size` values of the `input`, it causes the cursor style of the
     * decrement button to change from `default` to `text`.
     */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
     * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
     */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
  /**
       * Remove inner padding and search cancel button in Safari and Chrome on OS X.
       * Safari (but not Chrome) clips the cancel button when the search input has
       * padding (and `textfield` appearance).
       */ }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * Define consistent border, margin, and padding.
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct `color` not being inherited in IE 8/9/10/11.
     * 2. Remove padding so people aren't caught out if they zero out fieldsets.
     * 3. Correct alignment displayed oddly in IE 6/7.
     */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Remove default vertical scrollbar in IE 8/9/10/11.
     */
textarea {
  overflow: auto; }

/**
     * Don't inherit the `font-weight` (applied by a rule above).
     * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
     */
optgroup {
  font-weight: bold; }

/* Tables
       ========================================================================== */
/**
     * Remove most spacing between table cells.
     */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*---
title:   Typography
name:    base_typography
section: Base
---

Font sizes must be set in em or rem - use em values for text elements and rem
values for components (if necessary). Read more about this from
[Chris Coyier - Font Size Idea: px at the Root, rem for Components, em for Text
Elements](https://css-tricks.com/rems-ems/).  
Other allowed measurement units are vw, vh and % - basically all relative units
are allowed.  
Pixel (px) values are only allowed for hairline borders - this is because
there is no other reliable way to display 1px wide lines with relative units.
*/
/*---
title:   Variable
name:    base_typography_variable
section: base_typography
order:   3
---

```css
$base-typography-responsive: true;
$base-typography-a-color: #ff9a25;
$base-typography-a-color-visited: #ff9a25;
$base-typography-a-text-decoration: none;
$base-typography-a-text-decoration-hover: underline;
```
*/
/*---
title:   Mixin
name:    base_typography_mixin
section: base_typography
order:   2
---

```css
base-typography-whitespace()
```
*/
/* extract base.css */
/* extract base_typography.css */
/*---
title:   Example
name:    base_typography_example
section: base_typography
order:   1
---
*/
html,
button,
input,
select,
textarea {
  color: #4e4e4e;
  font-family: 'Source Sans Pro', sans-serif; }

/**
 * Basic font styles.
 */
html {
  line-height: 1.5;
  font-size: 87.5%; }
  @media (min-width: 30em) {
    html {
      font-size: 100%; } }

/**
 * Set only very basic heading styles, more about styling headlines:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/
 */
h1,
h2,
h3,
h4 {
  font-size: 1.25em; }

h5,
h6 {
  font-size: 1em; }

/*---
title:   Anchor
name:    base_typography_example_anchor
section: base_typography_example
---

```example:html
<a href="#">readmore...</a>
```
*/
a {
  color: #ff9a25;
  text-decoration: none;
  cursor: pointer; }
  a:visited {
    color: #ff9a25; }
  a:hover, a:focus {
    text-decoration: underline; }

/*---
title:   Whitespace
name:    base_typography_example_whitespace
section: base_typography_example
---

```example:html
<h1>Lorem Ipsum dolor</h1>
<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
voluptua.</p>
<ul>
  <li>Lorem Ipsum dolor</li>
  <li>Consetetur sadipscing elitr</li>
</ul>
<h2>Lorem Ipsum</h2>
<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
voluptua.</p>
<h3>Lorem Ipsum</h3>
<p>Consetetur sadipscing elitr, sed diam nonumy eirmod labore et dolore magna
aliquyam erat tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
voluptua.</p>
```
*/
h1,
h2,
h3,
h4,
h5,
h6,
h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6,
p,
ul,
ol,
dl,
pre,
form,
table,
figure,
address,
fieldset,
blockquote {
  margin-top: 0;
  margin-bottom: 0; }

p:not(:first-child),
ul:not(:first-child),
ol:not(:first-child),
dl:not(:first-child),
img:not(:first-child),
pre:not(:first-child),
form:not(:first-child),
table:not(:first-child),
figure:not(:first-child),
address:not(:first-child),
fieldset:not(:first-child),
blockquote:not(:first-child),
h1:not(:first-child),
h2:not(:first-child),
h3:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child) {
  margin-top: 1.5em; }

ul,
ol,
dl {
  padding-left: 0;
  list-style-position: inside; }

/**
 * No margin top for nested lists.
 */
li ul,
li ol,
li dl {
  margin-top: 0; }

/**
 * No margin top for nested tables.
 */
table table {
  margin-top: 0; }

/**
 * Normalize default font related tags.
 */
b,
strong {
  font-weight: 700; }

small {
  font-size: 0.8em; }

/**
 * Better img behaviour.
 */
img {
  display: inline-block; }

/* end extract base_typography.css */
/* end extract base.css */
/*---
title:   Box sizing reset
name:    base_box_sizing_reset
section: Base
---

Better box model: [Paul Irish - * { Box-sizing: Border-box } FTW](http://www.paulirish.com/2012/box-sizing-border-box-ftw/)
*/
/*---
title:   Variable
name:    base_box_sizing_reset_variable
section: base_box_sizing_reset
---

```css
$base-box-sizing-reset: true;
```
*/
/* extract base.css */
/* extract base_box_sizing_reset.css */
html {
  box-sizing: border-box; }

*, *::before, *::after {
  box-sizing: inherit; }

/* end extract base_box_sizing_reset.css */
/* end extract base.css */
/*---
title:   Form
name:    base_form
section: Base
---

Basic form styling and enhancements to ensure consistency across browsers.
More complex form styles should be applied via components. Form layouts can be
achieved with a combination of the [avalanche grid object](https://www.npmjs.com/package/avalanchesass_object_grid)
and the [avalanche width utility](https://www.npmjs.com/package/avalanchesass_utility_width).
*/
/* extract base.css */
/* extract base_form.css */
/*---
title:   Example
name:    base_form_example
section: base_form
order:   1
---

```example:html
<form>
  <div>
    <label for="text">Text field</label>
    <input type="text" id="text" name="text" placeholder="Placeholder">
  </div>
  <div>
    <label for="password">Password field</label>
    <input type="password" id="password" name="password">
  </div>
  <div>
    <label for="select">Select field</label>
    <select id="select" name="select"><option>test</option></select>
  </div>
  <div>
    <label for="textarea">Select field</label>
    <textarea id="textarea" name="textarea"></textarea>
  </div>
  <div>
    <label for="radio-lorem"><input id="radio-lorem" name="radio" type="radio" value="Lorem"> Lorem</label>
    <label for="radio-ipsum"><input id="radio-ipsum" name="radio" type="radio" value="Ipsum"> Ipsum</label>
  </div>
  <div>
    <label for="checkbox-lorem"><input id="checkbox-lorem" name="checkbox" type="checkbox" value="Lorem"> Lorem</label>
    <label for="checkbox-ipsum"><input id="checkbox-ipsum" name="checkbox" type="checkbox" value="Ipsum"> Ipsum</label>
  </div>
  <div>
    <label for="file">File field</label>
    <input type="file" id="file" name="file">
  </div>
  <div>
    <label for="range">Range field</label>
    <input type="range" id="range" name="range">
  </div>
  <div>
    <input type="submit" value="Submit" class="c-button">
    <button type="submit" class="c-button">Submit</button>
   </div>
</form>
```
*/
label {
  display: inline-block; }

button,
input,
textarea {
  padding: 0.5em;
  max-width: 100%;
  border: 1px solid #898989;
  line-height: inherit;
  transition: border-color ease-in-out 0.1s; }
  button:focus,
  input:focus,
  textarea:focus {
    outline: none;
    border-color: #ff9a25; }

button,
input[type='submit'] {
  border: none; }

input[type='radio'],
input[type='checkbox'],
input[type='file'],
input[type='range'] {
  padding: 0;
  max-width: none;
  border: none; }
  input[type='radio']:focus,
  input[type='checkbox']:focus,
  input[type='file']:focus,
  input[type='range']:focus {
    box-shadow: none; }

/**
 * Set the height of file controls to match text inputs.
 */
input[type='file'] {
  display: block;
  line-height: normal; }

/**
 * Make range inputs behave like textual form controls.
 */
input[type='range'] {
  display: block;
  width: 100%; }

/**
 * Make multiple select elements height not fixed.
 */
select[multiple],
select[size] {
  height: auto; }

/**
 * Reset height for textarea`s.
 */
textarea {
  height: auto; }

/* end extract base_form.css */
/* end extract base.css */
h1,
h2,
h3,
h4 {
  color: #222; }

h4 + * {
  margin-top: 0.5em; }

/*---
title: Object
---

Non-cosmetic styles (e.g. the famous media object).
*/
/*---
title:   Grid
name:    object_grid
section: Object
---

Fluid width, responsive grid system.

**TIP:** Most likely you want to use this package in combination with the
[width utility package](https://www.npmjs.com/package/avalanchesass_utility_width).
*/
/*---
title:   Variable
name:    object_grid_variable
section: object_grid
order:   3
---

```css
$o-grid-namespace: 'o-grid';
$o-grid-flex: true;
$o-grid-flex-fallback: false;
$o-grid-spaced-vertical-default-size: m;
$o-grid-spaced-vertical-sizes: (s);
$o-grid-spaced-vertical-sizes-with-breakpoints: (nested-map);
$o-grid-collapsed-vertical: false;
$o-grid-spaced-horizontal-sizes: (s);
$o-grid-spaced-horizontal-sizes-with-breakpoints: (nested-map);
$o-grid-collapsed-horizontal: false;
```
*/
/*---
title:   Clearfix
name:    utility_clearfix
section: Utility
---

Contain floats.
*/
/*---
title:   Mixin
name:    utility_clearfix_mixin
section: utility_clearfix
order:   2
---

```css
u-clearfix()
```
*/
/*---
title:   Mixin
name:    object_grid_mixin
section: object_grid
order:   2
---

```css
// Basic grid.
o-grid([$-flex: true[, $-flex-fallback: false]])

// Vertical spacing between grid items.
o-grid-spaced-vertical([$-spacing: map-get($spacing, m)[, $-item-selector: '.o-grid__item']])

// Horizontal spacing between grid items.
o-grid-spaced-horizontal([$-spacing: map-get($spacing, m)[, $-item-selector: '.o-grid__item']])

// Basic grid item.
o-grid-item([$-flex: true[, $-flex-fallback: false]])
```
*/
/* extract object.css */
/* extract object_grid.css */
/*---
title:   Example
name:    object_grid_example
section: object_grid
order:   1
---
*/
/*---
title:   Default
name:    object_grid_example_default
section: object_grid_example
---

Default responsive grid with vertical and horizontal spacing between items.

```example:html
<div class="o-grid">
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
</div>
```
*/
.o-grid {
  display: flex;
  flex-wrap: wrap;
  margin-top: -1.5em;
  margin-left: -1.5em; }
  .o-grid > .o-grid__item {
    margin-top: 1.5em; }
  .o-grid > .o-grid__item {
    padding-left: 1.5em; }

/*---
  title:   Spacing
  name:    object_grid_example_spacing
  section: object_grid_example
  ---
  */
/*---
  title:   Vertical
  name:    object_grid_example_spacing_vertical
  section: object_grid_example_spacing
  ---

  Vertical spacing between grid items.
  */
/*---
    title:   Size: s
    name:    object_grid_example_spacing_vertical_size_s
    section: object_grid_example_spacing_vertical
    ---

```example:html
<div class="o-grid o-grid--vertical-s">
  <div class="o-grid__item" style="width: 100%;">
    <div style="background: grey; padding: 0.5em;">width: 100%</div>
  </div>
  <div class="o-grid__item" style="width: 100%;">
    <div style="background: grey; padding: 0.5em;">width: 100%</div>
  </div>
</div>
```
    */
.o-grid--vertical-s {
  margin-top: -1em; }
  .o-grid--vertical-s > .o-grid__item {
    margin-top: 1em; }

/*---
  title:   Horizontal
  name:    object_grid_example_spacing_horizontal
  section: object_grid_example_spacing
  ---

  Horizontal spacing between grid items.
  */
/*---
    title:   Size: s
    name:    object_grid_example_spacing_horizontal_size_s
    section: object_grid_example_spacing_horizontal
    ---

```example:html
<div class="o-grid o-grid--horizontal-s">
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
  <div class="o-grid__item" style="width: 50%;">
    <div style="background: grey; padding: 0.5em;">width: 50%</div>
  </div>
</div>
```
    */
.o-grid--horizontal-s {
  margin-left: -1em; }
  .o-grid--horizontal-s > .o-grid__item {
    padding-left: 1em; }

/* end extract object_grid.css */
/* end extract object.css */
/*---
title:   Island
name:    object_island
section: Object
---

Box off content - [Harry Roberts - The 'island' object](http://csswizardry.com/2011/10/the-island-object/).
*/
/*---
title:   Variable
name:    object_island_variable
section: object_island
order:   3
---

```css
$o-island-namespace: 'o-island';
$o-island-sizes: (m, xxl);
$o-island-sizes-with-breakpoints: (nested-map);
```
*/
/*---
title:   Mixin
name:    object_island_mixin
section: object_island
order:   2
---

```css
o-island([$-spacing: map-get($spacing, m)])
```
*/
/* extract object.css */
/* extract object_island.css */
/*---
title:   Example
name:    object_island_example
section: object_island
order:   1
---
*/
/*---
  title:   Size: m (default)
  name:    object_island_example_size_m
  section: object_island_example
  ---

```example:html
<div class="o-island">
  Island
</div>
```
  */
.o-island {
  padding: 1.5em; }

/*---
  title:   Size: xxl
  name:    object_island_example_size_xxl
  section: object_island_example
  ---

```example:html
<div class="o-island o-island--xxl">
  Island
</div>
```
  */
.o-island--xxl {
  padding: 4em; }

/* end extract object_island.css */
/* end extract object.css */
/*---
title:   Media
name:    object_media
section: Object
---

Media object - image on left/right, text next to it.  
[Nicole Sullivan - The media object saves hundreds of lines of code](http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code)

**TIP:** You can use this package in combination with the
[align-self utility package](https://github.com/avalanchesass/avalanche_utility_align_self).
*/
/*---
title:   Variable
name:    object_media_variable
section: object_media
order:   2
---

```css
$o-media-namespace: 'o-media';
$o-media-default-size: s;
$o-media-sizes: ();
$o-media-stacked-breakpoints: (m);
$o-media-collapsed: false;
```
*/
/* extract object.css */
/* extract object_media.css */
/*---
title:   Example
name:    object_media_example
section: object_media
order:   1
---

```example:html
<div class="o-media">
  <div class="o-media__figure">
    <img src="http://placehold.it/70x70" alt="">
  </div>
  <div class="o-media__body">
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
    voluptua. At vero eos et accusam et</p>
  </div>
</div>
<div class="o-media" style="margin-top: 1.5em;">
  <div class="o-media__body">
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
    voluptua. At vero eos et accusam et</p>
  </div>
  <div class="o-media__figure">
    <img src="http://placehold.it/70x70" alt="">
  </div>
</div>
```
*/
.o-media {
  display: flex; }
  .o-media > :last-child {
    margin-left: 1em; }

.o-media__body {
  flex: 1; }

.o-media__figure img,
.o-media__figure video {
  display: block; }

/*---
  title:   Stacked up to breakpoint: m
  name:    object_media_example_stacked_to_m
  section: object_media_example
  ---

```example:html
<div class="o-media o-media--stacked2m">
  <div class="o-media__figure">
    <img src="http://placehold.it/70x70" alt="">
  </div>
  <div class="o-media__body">
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
    eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam
    voluptua. At vero eos et accusam et</p>
  </div>
</div>
```
  */
@media (max-width: 44.9375em) {
  .o-media--stacked2m,
  .o-media--stacked2m .o-media__figure img,
  .o-media--stacked2m .o-media__figure video {
    display: initial; }
  .o-media--stacked2m > :last-child {
    margin-top: 1em;
    margin-left: initial; } }

/* end extract object_media.css */
/* end extract object.css */
.o-container, .page-eform-submit-request-appointment .c-page__main-content, .node-type-news #main {
  margin: 0 auto;
  max-width: 74em;
  padding: 0 1.5em; }

.o-container--narrow {
  max-width: 52em; }

.o-container--wide {
  max-width: 82em;
  padding: 0; }

.o-horizontal-list {
  padding-left: 0;
  list-style-type: none; }
  .o-horizontal-list li {
    display: inline-block; }

/*---
title:   Image background
name:    object_image_background
section: Object
---

```example:html
<div class="o-image-background">
  <img class="o-image-background__image" src="https://placeimg.com/790/275/arch" alt="">
  <div class="o-image-background__content">
    <div class="o-image-background__inner">
      <!-- your content -->
    </div>
  </div>
</div>
```
*/
.o-image-background {
  position: relative; }

.o-image-background__image {
  width: 100%; }

.o-image-background__content {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.o-image-background__inner {
  flex-grow: 1; }

.o-island--horizontal-condensed {
  padding-right: 0;
  padding-left: 0; }

.o-media__figure > * {
  display: block;
  margin-top: 0.4rem; }

/*---
title: Component
---

Concrete, implementation-specific piece of UI.
*/
/*---
title:   Button
name:    component_button
section: Component
---
*/
/*---
title:   Variable
name:    component_button_variable
section: component_button
order:   3
---

```css
$c-button-namespace: 'c-button';
$c-button-sizes: (m);
$c-button-full-width: true;
$c-button-font-sizes: (xl);
$c-button-cs: (b);
$c-button-cs-alerts: ();
$c-button-cs-grey: (c);
```
*/
/*---
title:   Mixin
name:    component_button_mixin
section: component_button
order:   2
---

```css
// Default button.
c-button([$-size: m[, $-cs: a]])

// Size.
c-button-size($-size)
c-button-full-width()

// Font size.
c-button-font-size($-font-size)

// Color.
c-button-color($-color, $-contrast-color)
c-button-cs([$-cs: a])
c-button-cs-alert([$-cs-alert: neutral])
c-button-cs-grey([$-cs-grey: a])
```
*/
/* extract component.css */
/* extract component_button.css */
/*---
title:   Example
name:    component_button_example
section: component_button
order:   1
---
*/
/*---
title:   Default
name:    component_button_example_default
section: component_button_example
---

```example:html
<a href="#" class="c-button">Button</a>
<button class="c-button">Button</button>
```
*/
.c-button {
  padding: 0.5em 0.75em;
  background: #ff9a25;
  display: inline-block;
  margin: 0;
  border: none;
  vertical-align: top;
  white-space: nowrap;
  font-size: 100%;
  font-family: inherit;
  cursor: pointer; }
  .c-button, .c-button:hover, .c-button:focus, .c-button:active, .c-button:visited {
    color: #fff; }
  .c-button:hover, .c-button:focus, .c-button:active {
    background: #ffb866; }
  .c-button, .c-button:hover, .c-button:focus, .c-button:active, .c-button:visited {
    outline: none;
    text-decoration: none; }

/*---
  title:   Size: full width
  name:    component_button_example_size_full_width
  section: component_button_example
  ---

```example:html
<a href="#" class="c-button c-button--full-width">Button full width</a>
```
  */
.c-button--full-width {
  width: 100%;
  text-align: center; }

/*---
    title:   Font size: xl
    name:    component_button_example_font_size_xl
    section: component_button_example
    ---

```example:html
<a href="#" class="c-button c-button--font-size-xl">Button font size xl</a>
```
    */
.c-button--font-size-xl {
  font-size: 1.563em; }

/*---
    title:   cs: b
    name:    component_button_example_cs_b
    section: component_button_example
    ---

```example:html
<a href="#" class="c-button c-button--cs-b">Button cs b</a>
```
    */
.c-button--cs-b {
  background: #F4C93B; }
  .c-button--cs-b, .c-button--cs-b:hover, .c-button--cs-b:focus, .c-button--cs-b:active, .c-button--cs-b:visited {
    color: #fff; }
  .c-button--cs-b:hover, .c-button--cs-b:focus, .c-button--cs-b:active {
    background: #f7d976; }

/*---
  title:   cs-grey: c
  name:    component_button_example_cs_grey_c
  section: component_button_example
  ---

```example:html
<a href="#" class="c-button c-button--cs-grey-c">Button cs-grey c</a>
```
  */
.c-button--cs-grey-c {
  background: #898989; }
  .c-button--cs-grey-c, .c-button--cs-grey-c:hover, .c-button--cs-grey-c:focus, .c-button--cs-grey-c:active, .c-button--cs-grey-c:visited {
    color: black; }
  .c-button--cs-grey-c:hover, .c-button--cs-grey-c:focus, .c-button--cs-grey-c:active {
    background: #606060; }

/* end extract component_button.css */
/* end extract component.css */
/*---
title:   hN
name:    component_hn
section: Component
---
*/
/*---
title:   Variable
name:    component_hn_variable
section: component_hn
order:   3
---

```css
$c-hn-namespace: 'c-h';
$c-hn-n: (nested-map);
```
*/
/*---
title:   Mixin
name:    component_hn_mixin
section: component_hn
order:   2
---

```css
c-h($-n: 1)
```
*/
/* extract component.css */
/* extract component_hn.css */
/*---
  title:   Example
  name:    component_hn_example
  section: component_hn
  ---

  Headline classes.

```example:html
<h1 class="c-h1">Headline 1</h1>
<h2 class="c-h2">Headline 2</h2>
<h3 class="c-h3">Headline 3</h3>
<h4 class="c-h4">Headline 4</h4>
<h5 class="c-h5">Headline 5</h5>
<h6 class="c-h6">Headline 6</h6>
```
  */
.c-h1 {
  font-weight: 700;
  font-size: 2.441em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.25; }

.c-h2, .c-used-equipment .c-node-used-equipment__ue-manufacturer,
.c-used-equipment .c-node-used-equipment__title-field {
  font-weight: 700;
  font-size: 1.953em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.25; }

.c-h3, .c-used-equipment .c-node-used-equipment__ue-fl-dealerprice {
  font-weight: 700;
  font-size: 1.563em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.25; }

.c-h4 {
  font-weight: 700;
  font-size: 1.25em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: 1.25; }

.c-h5 {
  font-weight: 700;
  font-size: 1em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: inherit; }

.c-h6 {
  font-weight: 400;
  font-size: 1em;
  font-family: 'Source Sans Pro', sans-serif;
  line-height: inherit; }

/* end extract component_hn.css */
/* end extract component.css */
/*---
title:   Table
name:    component_table
section: Component
---

Table classes.
*/
/*---
title:   Variable
name:    component_table_variable
section: component_table
order:   3
---

```css
$c-table-namespace: 'c-table';
$c-table-column-padding: 0.5em 0.5em;
$c-table-column-border: 1px solid #F5F5F5;
```
*/
/*---
title:   Mixin
name:    component_table_mixin
section: component_table
order:   2
---

```css
// Table.
c-table([$-column-padding: map-get($spacing, xs) map-get($spacing, s)[, $-column-border: 1px solid map-get($cs-grey, a)]])

// Table column.
c-table-column([$-padding: map-get($spacing, xs) map-get($spacing, s)[, $-border: 1px solid map-get($cs-grey, a)]])

// Numeric table column.
c-table-column-numeric()
```
*/
/* extract component.css */
/* extract component_table.css */
/*---
title:   Example
name:    component_table_example
section: component_table
order:   1
---

```example:html
<table class="c-table">
  <thead>
    <tr>
      <th>Column 1</th>
      <th>Column 1</th>
      <th>Column 1</th>
      <th class="c-table__numeric">Column 1</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
      <td class="c-table__numeric">123</td>
    </tr>
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
      <td class="c-table__numeric">123</td>
    </tr>
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
      <td class="c-table__numeric">123</td>
    </tr>
  </tbody>
</table>
```
*/
.c-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left; }
  .c-table th,
  .c-table td {
    padding: 0.5em 0.5em;
    vertical-align: top;
    border-bottom: 1px solid #F5F5F5; }

.c-table__numeric {
  text-align: right; }

/* end extract component_table.css */
/* end extract component.css */
/*---
title:   Zebra striping
name:    component_zebra_striping
section: Component
---
*/
/*---
title:   Variable
name:    component_zebra_striping_variable
section: component_zebra_striping
order:   3
---

```css
$c-zebra-striping-namespace: 'c-zebra-striping';
$c-zebra-striping-color: #F5F5F5;
```
*/
/*---
title:   Mixin
name:    component_zebra_striping_mixin
section: component_zebra_striping
order:   2
---

```css
c-zebra-striping()
```
*/
/* extract component.css */
/* extract component_zebra_striping.css */
/*---
title:   Example
name:    component_zebra_striping_example
section: component_zebra_striping
---

Zebra striping rows.

```example:html
<table style="width: 100%;">
  <tbody class="c-zebra-striping">
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
    </tr>
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
    </tr>
    <tr>
      <td>Lorem</td>
      <td>Ipsum</td>
      <td>Dolor</td>
    </tr>
  </tbody>
</table>
```
*/
.c-zebra-striping > :nth-child(odd) {
  background-color: #F5F5F5; }

/* end extract component_zebra_striping.css */
/* end extract component.css */
/*---
title:   Accordion
name:    component_accordion
section: component
---

```example:html
<div class="c-accordion">
  <div class="c-section-bar c-accordion__header is-active js-accordion-header">
    <h3 class="c-accordion__title">Default Hydraulikbagger</h3>
  </div>
  <div class="c-accordion__body is-open">
    Lorem Ipsum dolor sit amet.
  </div>
  <div class="c-section-bar c-accordion__header js-accordion-header">
    <h3 class="c-accordion__title">Default Hydraulikbagger</h3>
  </div>
  <div class="c-accordion__body">
    Lorem Ipsum dolor sit amet.
  </div>
</div>
```
*/
.has-js .c-accordion__header {
  cursor: pointer; }

.c-accordion__header:not(:first-child) {
  margin-top: 0.5em; }

.c-accordion__title {
  margin-top: 0; }

.c-accordion__header.is-active .c-accordion__icon {
  transform: rotate(90deg); }

.c-accordion__body {
  padding-top: 2em;
  padding-bottom: 2em; }
  .has-js .c-accordion__body {
    display: none; }
  .c-accordion__body.is-open {
    display: block; }

.c-icon--angle-double-right {
  width: 1em;
  height: 1em; }

.c-icon--angle-down {
  width: 1em;
  height: 1em; }

.c-icon--angle-right {
  width: 1em;
  height: 1em; }

.c-icon--arrow-right {
  width: 1em;
  height: 1em; }

.c-icon--clock-o {
  width: 1em;
  height: 1em; }

.c-icon--download {
  width: 1em;
  height: 1em; }

.c-icon--envelope-o {
  width: 1em;
  height: 1em; }

.c-icon--external-link {
  width: 1em;
  height: 1em; }

.c-icon--help-alt {
  width: 1em;
  height: 1em; }

.c-icon--home {
  width: 1em;
  height: 1em; }

.c-icon--layout-grid2-alt {
  width: 1em;
  height: 1em; }

.c-icon--layout-list-thumb-alt {
  width: 1em;
  height: 1em; }

.c-icon--link {
  width: 1em;
  height: 1em; }

.c-icon--map-marker {
  width: 0.706em;
  height: 1em; }

.c-icon--phone-square {
  width: 1em;
  height: 1em; }

.c-icon--phone {
  width: 1em;
  height: 1em; }

.c-icon--world {
  width: 1em;
  height: 1em; }

.c-icon--user {
  width: 1em;
  height: 1em; }

.c-icon--yt {
  width: 1em;
  height: 1em; }

/*---
title:   Icon
name:    component_icon
section: Component
---

```example:html
<svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg>
<svg role="img" class="c-icon c-icon--angle-right"><use xlink:href="#icon-angle-right"></use></svg>
<svg role="img" class="c-icon c-icon--angle-down"><use xlink:href="#icon-angle-down"></use></svg>
<svg role="img" class="c-icon c-icon--arrow-right"><use xlink:href="#icon-arrow-right"></use></svg>
<svg role="img" class="c-icon c-icon--clock-o"><use xlink:href="#icon-clock-o"></use></svg>
<svg role="img" class="c-icon c-icon--download"><use xlink:href="#icon-download"></use></svg>
<svg role="img" class="c-icon c-icon--envelope-o"><use xlink:href="#icon-envelope-o"></use></svg>
<svg role="img" class="c-icon c-icon--external-link"><use xlink:href="#icon-external-link"></use></svg>
<svg role="img" class="c-icon c-icon--help-alt"><use xlink:href="#icon-help-alt"></use></svg>
<svg role="img" class="c-icon c-icon--home"><use xlink:href="#icon-home"></use></svg>
<svg role="img" class="c-icon c-icon--layout-grid2-alt"><use xlink:href="#icon-layout-grid2-alt"></use></svg>
<svg role="img" class="c-icon c-icon--layout-list-thumb-alt"><use xlink:href="#icon-layout-list-thumb-alt"></use></svg>
<svg role="img" class="c-icon c-icon--link"><use xlink:href="#icon-link"></use></svg>
<svg role="img" class="c-icon c-icon--map-marker"><use xlink:href="#icon-map-marker"></use></svg>
<svg role="img" class="c-icon c-icon--phone-square"><use xlink:href="#icon-phone-square"></use></svg>
<svg role="img" class="c-icon c-icon--phone"><use xlink:href="#icon-phone"></use></svg>
<svg role="img" class="c-icon c-icon--world"><use xlink:href="#icon-world"></use></svg>
<svg role="img" class="c-icon c-icon--world"><use xlink:href="#icon-user"></use></svg>
```
```example:html
<h3><svg role="img" class="c-icon c-icon--arrow-right c-icon--float-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Lorem Ipsum</h3>
```
*/
.c-icon {
  vertical-align: middle;
  position: relative;
  top: -0.125em;
  fill: currentColor; }

.c-icon--float-right {
  top: -0.04em;
  float: right; }

.c-icon--2x {
  font-size: 2em; }

/*---
title:   Title
name:    object_title
section: Component
---

```example:html
<h3 class="c-title c-title--underline c-title--main">Title</h3>
<p>
  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
</p>
```
*/
.c-title--main, .c-homehighlights .o-grid h2 {
  text-transform: uppercase;
  font-size: 1.25em; }

.c-title--underline::after, .c-homehighlights .o-grid h2::after {
  content: '';
  height: 3px;
  background: #ff9a25;
  width: 3.75rem;
  display: block;
  margin-top: 0.75rem; }

.c-title--underline + *, .c-homehighlights .o-grid h2 + * {
  margin-top: 0.75rem; }

/*---
title:   Advertisement
name:    component_advertisement
section: Component
---

```example:html
<div class="c-advertisement" style="width: 350px;">
  <div class="o-image-background">
    <img class="o-image-background__image" src="https://placeimg.com/350/200/arch" alt="">
    <div class="o-image-background__content">
      <div class="o-image-background__inner o-island">
        <h3 class="c-advertisement__title c-title c-title--main">Lorem Ipsum dolor</h3>
      </div>
    </div>
  </div>
  <a href="#" class="c-button c-button--full-width c-button--font-size-xl c-button--multi-line has-icon"><svg role="img" class="c-button__multi-line-icon c-icon c-icon--arrow-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Lorem Ipsum<br />dolor sit</a>
</div>
```
*/
.c-advertisement__title {
  color: #fff;
  text-shadow: 0 0 0.125em #4e4e4e;
  line-height: 1.1;
  font-size: 1.563em; }

.c-advertisement .o-image-background img {
  width: 100%; }

.c-ankuendigung .o-image-background img {
  width: 100%; }

.c-view-board-members--block .o-product_grid {
  background: #f5f5f5;
  padding: 1em;
  text-align: center; }
  .c-view-board-members--block .o-product_grid h3 {
    font-size: 0.8em;
    margin-bottom: 0.5em; }
  .c-view-board-members--block .o-product_grid .c-node-board-member__board-member-job-title, .c-view-board-members--block .o-product_grid .c-node-board-member__board-member-job-title-2 {
    font-size: 0.8em; }

/*---
title:   Background
name:    utility_background
section: Utility
---

Color scheme background color.
*/
/*---
title:   Variable
name:    utility_background_variable
section: utility_background
order:   3
---

```css
$u-background-namespace: 'u-background';

$u-background-cs: (c);
$u-background-cs-alerts: ();
$u-background-cs-grey: (a);
```
*/
/*---
title:   Mixin
name:    utility_background_mixin
section: utility_background
order:   2
---

```css
// Basic.
u-background-cs([$-cs: a])

// Alerts.
u-background-cs-alert([$-cs-alert: positive])

// Grey.
u-background-cs-grey([$-cs-grey: a])
```
*/
/* extract utility.css */
/* extract utility_background.css */
/*---
title:   Example
name:    utility_background_example
section: utility_background
order:   1
---
*/
/*---
  title:   cs: c
  name:    utility_background_example_cs_c
  section: utility_background_example
  ---
```example:html
<div class="u-background-cs-c">
  cs: c
</div>
```
  */
.u-background-cs-c {
  background: #FFF4DA !important; }

/*---
  title:   cs-grey: a
  name:    utility_background_example_cs_grey_a
  section: utility_background_example
  ---
```example:html
<div class="u-background-cs-grey-a">
  cs-grey: a
</div>
```
  */
.u-background-cs-grey-a {
  background: #F5F5F5 !important; }

/* end extract utility_background.css */
/* end extract utility.css */
/*---
title:   Box
name:    component_box
section: Component
---

```example:html
<div class="c-box o-island u-background-cs-c" style="width: 350px;">
  <h3 class="c-box__title c-title c-title--underline c-title--main">Title <div class="c-box__icon"><svg role="img" class="c-icon c-icon--arrow-right"><use xlink:href="#icon-arrow-right"></use></svg></div></h3>
  <div class="c-box__section">
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem. 
    </p>
  </div>
  <div class="c-box__section c-box__actions">
    <a href="#" class="c-button c-box__button">Lorem Ipsum <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
    <a href="#" class="c-button c-box__button">Lorem Ipsum dolor <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
  </div>
</div>
```
*/
.c-box__icon {
  float: right;
  font-size: 2em;
  line-height: 1; }

.c-box__content {
  line-height: 1.3333333; }

.c-box__section:not(:first-child) {
  margin-top: 1.5em; }

.c-box__actions * + * {
  margin-top: 0.5em; }

.c-section-bar {
  padding: 1em;
  text-transform: uppercase;
  background: #E9E9E9; }
  .c-section-bar,
  .c-section-bar a,
  .c-section-bar a:visited {
    color: #222; }

/*---
title:   Breadcrumb
name:    component_breadcrumb
section: Component
---

```example:html
<div class="c-section-bar"> 
  <div class="c-breadcrumb">
    <span class="c-breadcrumb__item c-breadcrumb__item-1">Building Machines</span>
    <span class="c-breadcrumb__item-separator"> / </span>
    <a href="/en/building-machines/komatsu" class="c-breadcrumb__item c-breadcrumb__item-2">Komatsu</a>
    <span class="c-breadcrumb__item-separator"> / </span>
    <span class="c-breadcrumb__item c-breadcrumb__item-title">Hydraulikbagger</span>
  </div>
</div>
```
*/
.c-breadcrumb__item-separator {
  text-indent: -999em;
  border-left: 1px solid #898989;
  display: inline-block;
  width: 1px;
  height: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
  position: relative;
  left: 0.15em;
  transform: skewX(-10deg); }

.c-breadcrumb__item-1,
.c-breadcrumb__item-1 + .c-breadcrumb__item-separator {
  display: none; }

.c-breadcrumb__item-title {
  font-weight: 700; }

.c-button {
  text-transform: uppercase; }

.c-button--full-width {
  text-align: initial;
  /*!remove*/ }

.c-button--cs-grey-c, .c-button--cs-grey-c:hover, .c-button--cs-grey-c:focus, .c-button--cs-grey-c:active, .c-button--cs-grey-c:visited {
  color: #fff; }

/*---
title:   Multi line
name:    component_button_example_multi_line
section: component_button_example
---

```example:html
<a href="#" class="c-button c-button--multi-line has-icon"><svg role="img" class="c-icon c-icon--arrow-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Multi line button<br />with icon</a>
```
*/
.c-button--multi-line {
  line-height: 1.1;
  position: relative;
  white-space: normal;
  min-height: 4.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }

.c-button-big {
  font-size: 200%; }

.c-button-big a {
  border-radius: 32px; }

.counter {
  text-align: center; }
  .counter .counter-icon {
    display: block;
    margin: 1em 0; }
    .counter .counter-icon img {
      max-width: 50%;
      height: 70px; }
  .counter .counter-value {
    font-size: 2em; }

.c-data-lifting-moment td:not(:first-child) {
  text-align: right; }

.c-data-technical-data td:last-child {
  text-align: right; }

.node-type-used-equipment .c-page__content .c-field__label {
  font-weight: 600;
  padding-right: 0.5em;
  display: inline-block; }

.node-type-used-equipment .c-page__content .c-field__item {
  display: inline-block; }

/*---
title:   Domain switcher
name:    component_domain_switcher
section: Component
---

```example:html
<div class="c-domain-switcher">
  <button class="c-domain-switcher__selector">
    <span class="c-domain-switcher__world"><svg role="img" class="c-icon c-icon--world"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-world"></use></svg></span>
    <span class="c-domain-switcher__angle-right"><svg role="img" class="c-icon c-icon--angle-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-angle-right"></use></svg></span>
  </button>
  <div class="c-domain-switcher__list">
    <ul>
      <li><a href="http://kuhn-gruppe.com/building-machines/komatsu/hydraulikbagger">Kuhn Gruppe</a></li>
      <li><a href="http://kuhn.at/building-machines/komatsu/hydraulikbagger">Kuhn Österreich</a></li>
    </ul>
  </div>
</div>
```
*/
.c-domain-switcher {
  display: flex;
  align-items: center; }

.c-domain-switcher__selector {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  order: 2; }

.c-domain-switcher__world {
  font-size: 2em;
  margin-left: .2em; }

.c-domain-switcher__list ul {
  padding: 0;
  list-style-type: none; }

.c-domain-switcher__list li {
  font-weight: 700;
  max-width: 6em;
  display: none; }
  .c-domain-switcher__list li.is-active, .c-domain-switcher__list li:nth-child(2) {
    display: block; }

#block-domain-switcher {
  display: none; }

#sliding-popup {
  width: auto !important;
  padding: 2rem; }
  #sliding-popup .popup-content {
    width: auto !important;
    text-align: center; }
  #sliding-popup .c-button {
    background-image: none;
    background-color: #ccc;
    border-radius: 0;
    border: none;
    text-shadow: none;
    box-shadow: none;
    padding: .75rem; }
    #sliding-popup .c-button:hover {
      background-image: none;
      background-color: #ccc;
      border-radius: 0;
      border: none;
      text-shadow: none;
      box-shadow: none;
      padding: .75rem;
      opacity: .8; }
  #sliding-popup .eu-cookie-compliance-more-button {
    display: none; }
  #sliding-popup .eu-cookie-compliance-save-preferences-button {
    margin-top: 0.9rem; }
  #sliding-popup .agree-button {
    background: #ff9a25;
    color: white; }
  #sliding-popup .decline-button {
    color: white; }
  #sliding-popup .eu-cookie-compliance-save-preferences-button {
    color: black; }
  #sliding-popup .eu-cookie-compliance-categories-buttons, #sliding-popup .popup-content #popup-buttons {
    float: none !important;
    padding-left: 0 !important;
    max-width: 100%; }
  #sliding-popup .eu-cookie-compliance-categories-buttons {
    margin-top: 1rem; }
  #sliding-popup .eu-cookie-withdraw-tab {
    background: #e9e9e9 !important; }

.cookie-settings-save {
  background: #ff9a25;
  color: white;
  padding: .5rem;
  margin-top: 1rem; }

.euccx-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; }
  .euccx-toggle-wrapper div {
    margin-right: .5rem; }
  .euccx-toggle-wrapper .euccx-switch-wrapper {
    padding-top: .5rem; }

.c-file-table {
  width: 100%;
  text-align: left; }
  .c-file-table tr > :last-child {
    text-align: right; }
  .c-file-table tbody tr {
    border-top: 1px solid #ffcd92; }
  .c-file-table th, .c-file-table td {
    padding-top: 0.25em;
    padding-bottom: 0.25em; }
  .c-file-table .c-file__size {
    display: none; }

h2.c-inquiry-form,
h2.c-contact-popup-form {
  margin: 10px 20px; }

.c-inquiry-form,
.c-contact-popup-form {
  margin: 10px 20px; }

.c-form__field--name-field-inquiry-current-page {
  display: none; }

#edit-field-contact-clientemail,
#edit-field-inquiry-to-email {
  display: none !important;
  visibility: hidden !important; }

.c-block-contactpopup a {
  display: block; }

.c-block-contact-popup label,
.entityform-type-entityform-type label {
  display: block; }

.c-block-contact-popup .c-form__required-marker:before,
.entityform-type-entityform-type .c-form__required-marker:before {
  content: "*"; }

.c-block-contact-popup .captcha,
.entityform-type-entityform-type .captcha {
  margin-bottom: 1em; }

#cboxTitle {
  font-size: 0.8em; }

.c-block-epart-store label {
  display: block; }

.c-block-epart-store fieldset {
  border: none;
  padding: 0; }
  .c-block-epart-store fieldset .c-form__fieldset-content {
    border: 1px solid #ccc;
    padding: 1em; }
    .c-block-epart-store fieldset .c-form__fieldset-content label {
      margin-top: 0.5em;
      font-size: 0.8em; }

.c-block-epart-store .c-form__required-marker:before {
  content: "*"; }

.c-block-epart-store .captcha {
  margin-bottom: 1em; }

#edit-field-newsletter .c-form__label--after {
  display: inline-block; }

#edit-field-form-privacy .c-form__label--after {
  display: none; }

#edit-field-form-privacy .c-form__description {
  display: inline-block;
  font-size: 100%; }

#block-entityform-block-contact .c-form__field--name-field-contact-name label,
#block-entityform-block-contact .c-form__item--field-contact-last-name-en-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-phone-en-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-email-en-0-email label,
#block-entityform-block-contact .c-form__item--field-contact-company-en-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-phone-und-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-last-name-und-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-company-und-0-value label,
#block-entityform-block-contact .c-form__item--field-contact-message-en-0-value label {
  width: 160px; }

.domain-kuhn-at .fm712,
.domain-kuhn-at .sennebogen,
.domain-kuhn-at .closkey,
.domain-kuhn-at .ammann {
  display: none; }

.domain-kuhn-hu .fm712,
.domain-kuhn-hu .sennebogen,
.domain-kuhn-hu .closkey,
.domain-kuhn-hu .ammann {
  display: none; }

.domain-kuhn-sk .sennebogen,
.domain-kuhn-sk .powerscreen,
.domain-kuhn-sk .fm712 {
  display: none; }

.domain-kuhn-baumaschinen-de .sennebogen,
.domain-kuhn-baumaschinen-de .fm712,
.domain-kuhn-baumaschinen-de .ammann,
.domain-kuhn-baumaschinen-de .powerscreen {
  display: none; }

.domain-kuhn-gruppe-ch .ammann,
.domain-kuhn-gruppe-ch .closkey,
.domain-kuhn-gruppe-ch .fm712,
.domain-kuhn-gruppe-ch .topcon {
  display: none; }

.domain-kuhnbohemia-cz .sennebogen,
.domain-kuhnbohemia-cz .closkey,
.domain-kuhnbohemia-cz .fm712,
.domain-kuhnbohemia-cz .powerscreen {
  display: none; }

.domain-kuhn-hr .sennebogen,
.domain-kuhn-hr .closkey,
.domain-kuhn-hr .fm712,
.domain-kuhn-hr .topcon {
  display: none; }

.domain-kuhn-si .sennebogen,
.domain-kuhn-si .closkey,
.domain-kuhn-si .fm712,
.domain-kuhn-si .topcon {
  display: none; }

#block-views-exp-used-equipment-block-2 label.c-view__widget--label {
  display: none; }

#block-views-exp-used-equipment-block-2 input, #block-views-exp-used-equipment-block-2 select {
  width: 68%; }

#block-views-exp-used-equipment-block-2 label.c-form__label {
  width: 30%;
  text-transform: none; }

#block-views-exp-used-equipment-block-2 .c-view__widget--filter-field_ue_fl_capacity_value .bef-select-as-checkboxes, #block-views-exp-used-equipment-block-2 .c-view__widget--filter-field_ue_fl_capacity_value .c-form__item--textfield, #block-views-exp-used-equipment-block-2 .c-view__widget--filter-field_ue_fl_liftingheight_value .bef-select-as-checkboxes, #block-views-exp-used-equipment-block-2 .c-view__widget--filter-field_ue_fl_liftingheight_value .c-form__item--textfield {
  width: 100%; }

#block-views-exp-used-equipment-block-2 .c-form__description {
  display: none; }

@media (max-width: 720px) {
  #block-views-exp-used-equipment-block-2 input, #block-views-exp-used-equipment-block-2 select {
    width: 100%; }
  #block-views-exp-used-equipment-block-2 label.c-form__label {
    width: 100%; } }

form#bauma-entityform-edit-form .c-form__required-marker:before,
form#bauma-de-entityform-edit-form .c-form__required-marker:before,
form#bauma-ch-entityform-edit-form .c-form__required-marker:before {
  content: "*"; }

@media all and (max-width: 1024px) {
  form#bauma-entityform-edit-form label,
  form#bauma-de-entityform-edit-form label,
  form#bauma-ch-entityform-edit-form label {
    display: block; }
  form#bauma-entityform-edit-form .c-form__item--checkbox label,
  form#bauma-de-entityform-edit-form .c-form__item--checkbox label,
  form#bauma-ch-entityform-edit-form .c-form__item--checkbox label {
    display: inline-block; } }

@media all and (min-width: 1024px) {
  form#bauma-entityform-edit-form .c-form__item,
  form#bauma-de-entityform-edit-form .c-form__item,
  form#bauma-ch-entityform-edit-form .c-form__item {
    width: 100%; }
  form#bauma-entityform-edit-form label,
  form#bauma-de-entityform-edit-form label,
  form#bauma-ch-entityform-edit-form label {
    display: block; }
  form#bauma-entityform-edit-form .c-form__item--checkbox label,
  form#bauma-de-entityform-edit-form .c-form__item--checkbox label,
  form#bauma-ch-entityform-edit-form .c-form__item--checkbox label {
    display: inline-block; } }

/*---
title:   Hero
name:    component_hero
section: Component
---
*/
/*---
title:   Example
name:    component_hero_example
section: component_hero
---

```example:html
<div class="c-hero" style="background-image: url(http://placehold.it/800x400);">
  <div class="c-hero__content">
    <h1>Lorem Ipsum</h1>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
  </div>
</div>
```
*/
.c-hero {
  position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  text-align: center;
  color: #fff;
  background-size: cover; }
  @media (min-width: 45em) {
    .c-hero {
      height: 46.375em; } }
  @media (max-width: 45em) {
    .c-hero {
      background-position: center; } }

.c-hero--narrow {
  /*height: 31em;*/ }

.c-hero--align-left {
  text-align: left; }

.c-hero__content {
  width: 100%; }
  .c-hero--narrow .c-hero__content {
    margin-top: 1.5em; }

.c-hero__main-title {
  font-size: 5em;
  text-transform: uppercase;
  text-shadow: 0 0 0.0125em #4e4e4e;
  font-weight: 700;
  line-height: 1; }

.c-hero__sec-title {
  font-size: 3.5em;
  font-weight: 700;
  line-height: 1;
  color: #F4C93B; }

.c-hero__third-title {
  text-transform: uppercase; }
  .c-hero__third-title a,
  .c-hero__third-title a:visited {
    color: #fff; }

/*---
title:   Icon prefix
name:    component_icon_prefix
section: Component
---

```example:html
<div style="padding-left:2.875rem;">
  <div class="c-icon-prefix-wrap c-section-bar">
    <h3>Lorem Ipsum</h3>
    <div class="c-icon-prefix">
      <svg role="img" class="c-icon c-icon--layout-list-thumb-alt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-layout-list-thumb-alt"></use></svg>
    </div>
  </div>
</div>
```
*/
.c-icon-prefix-wrap {
  position: relative; }

.c-icon-prefix {
  position: absolute;
  top: 0;
  left: -2.875rem;
  width: 2.875rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F5F5; }
  @media all and (max-width: 1280px) {
    .c-icon-prefix {
      left: auto;
      right: 0; } }

/*---
title:   Intro
name:    component_intro
section: Component
---

```example:html
<div class="c-intro">
  <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.</p>
</div>
<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.</p>
```
*/
.c-intro {
  font-size: 1.25em; }

.update-item {
  padding: 0.5em;
  color: #fff;
  background: #cecece; }
  .update-item .c-node-kuhn-update__title-field {
    margin-bottom: 0.5em;
    font-weight: bold; }
  .update-item .c-button {
    margin: 0.5em 0 0 0;
    text-align: center;
    cursor: default; }
    .update-item .c-button a {
      padding: 0 1em;
      color: #fff;
      font-size: 1.3em; }
      .update-item .c-button a:hover {
        color: #ffc; }
  .update-item .c-button:hover, .update-item .c-button:focus, .update-item .c-button:active {
    background: #ff9a25; }

/*---
title:   Language switcher
name:    component_language_switcher
section: Component
---

```example:html
<ul class="c-language-switcher">
  <li class="c-language-switcher__item">
    <a href="/en/building-machines/komatsu/hydraulikbagger" class="is-active" xml:lang="en">EN</a>
  </li>
  <li class="c-language-switcher__item">
    <span class="c-link-list__link" xml:lang="de">DE</span>
  </li>
</ul>
```
*/
.c-language-switcher {
  padding-left: 0;
  list-style-type: none; }

.c-language-switcher__item {
  display: inline-block; }
  .c-language-switcher__item a {
    padding-right: 0.25em;
    padding-left: 0.25em; }
    .c-language-switcher__item a.is-active, .c-language-switcher__item a:visited.is-active {
      background: #222;
      color: #fff; }
      .has-hero .c-language-switcher__item a.is-active, .has-hero .c-language-switcher__item a:visited.is-active {
        background: #fff;
        color: #222; }

.c-main-header {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  line-height: 1.25; }
  .c-main-header,
  .c-main-header h1,
  .c-main-header h2,
  .c-main-header h3,
  .c-main-header h4,
  .c-main-header button,
  .c-main-header a,
  .c-main-header a:visited {
    color: #222; }
  .has-hero .c-main-header {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to bottom, #222 0, transparent 100%); }
    .has-hero .c-main-header,
    .has-hero .c-main-header h1,
    .has-hero .c-main-header h2,
    .has-hero .c-main-header h3,
    .has-hero .c-main-header h4,
    .has-hero .c-main-header button,
    .has-hero .c-main-header a,
    .has-hero .c-main-header a:visited {
      color: #fff; }

.c-main-header__logo {
  display: flex;
  align-items: center;
  text-transform: uppercase; }

.c-main-header__site-name {
  margin-left: 1em; }
  @media (max-width: 45em) {
    .c-main-header__site-name {
      display: none; } }

.c-main-header__details {
  display: flex;
  align-items: stretch;
  justify-content: flex-end; }

.c-main-header__detail {
  padding: 0.5em; }
  .c-main-header__detail:not(:first-child) {
    border-left: 1px solid #222; }
  .has-hero .c-main-header__detail {
    border-left-color: #fff; }
  .c-main-header__detail #block-views-locations-block-1 a {
    display: block; }
  @media (max-width: 45em) {
    .c-main-header__detail #block-views-locations-block-1 a {
      font-size: 2em;
      font-weight: bold;
      display: inline-block; }
      .c-main-header__detail #block-views-locations-block-1 a span {
        display: none; } }

.c-main-header__contact {
  text-align: right; }
  .domain-kuhn-gruppe-com .c-main-header__contact {
    padding-top: 1.1em; }

.c-main-header__language {
  display: flex;
  align-items: center; }
  .c-main-header__language .c-language-switcher {
    margin-top: 0; }

#block-kuhn-system-searchform {
  float: right; }

#block-views-locations-block-1 {
  float: right;
  margin-left: 10px; }

@keyframes colorchange {
  0% {
    background: #fff4da; }
  25% {
    background: #ffe181; }
  50% {
    background: #ffc179; }
  75% {
    background: #ffe181; }
  100% {
    background: #fff4da; } }

.c-main-header {
  padding-top: 0 !important; }

.c-page_banner {
  -webkit-animation: colorchange 10s infinite;
  -moz-animation: colorchange 10s infinite;
  -ms-animation: colorchange 10s infinite;
  -o-animation: colorchange 10s infinite;
  animation: colorchange 10s infinite;
  display: inline-block;
  width: 100%;
  padding: 12px 0; }
  .c-page_banner .o-container, .c-page_banner .page-eform-submit-request-appointment .c-page__main-content, .page-eform-submit-request-appointment .c-page_banner .c-page__main-content, .c-page_banner .node-type-news #main, .node-type-news .c-page_banner #main {
    text-align: center; }
  .c-page_banner p {
    display: inline-block;
    margin-top: 8px;
    margin-right: 20px;
    color: black; }
  .c-page_banner a.c-button {
    color: white; }

@media only screen and (max-width: 720px) {
  .c-page_banner .o-container, .c-page_banner .page-eform-submit-request-appointment .c-page__main-content, .page-eform-submit-request-appointment .c-page_banner .c-page__main-content, .c-page_banner .node-type-news #main, .node-type-news .c-page_banner #main {
    text-align: none; }
  .c-page_banner p {
    width: 100%;
    margin-bottom: 8px; }
  .c-page_banner a.c-button {
    width: 100%; } }

body.section-baumaschinen .c-yt-channel-switcher,
body.section-ladetechnik .c-yt-channel-switcher {
  padding-top: 19px; }

body.section-baumaschinen .c-main-header__facebook,
body.section-ladetechnik .c-main-header__facebook {
  padding-top: 19px; }

/* HACKY BECAUSE MENU THEMING SUCKS (in Drupal)! */
.nav {
  list-style-type: none;
  padding: 0; }

#block-menu-block-2 {
  display: none; }

@media (min-width: 45em) {
  .c-main-menu {
    text-align: right; } }

.c-main-menu > .is-active-trail {
  border-bottom: 3px solid #ff9a25; }

.c-main-menu .c-menu__list--level1 {
  display: inline-block;
  text-align: left; }

.c-main-menu .c-menu__list {
  list-style-type: none;
  padding-left: 0; }

.c-main-menu .c-menu__item--level1 {
  display: inline-block; }
  .c-main-menu .c-menu__item--level1:not(:first-child) {
    margin-left: 1.5em; }

.c-main-menu .c-menu__link--level1 {
  display: block;
  border-bottom: 3px solid transparent;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 1.25em; }
  @media (min-width: 30em) {
    .c-main-menu .c-menu__link--level1 {
      font-size: 1.1em; } }
  .c-main-menu .c-menu__link--level1.is-active, .c-main-menu .c-menu__link--level1:hover, .c-main-menu .c-menu__link--level1:focus {
    border-bottom-color: #ff9a25;
    text-decoration: none; }

@media (min-width: 45em) {
  .c-main-menu .nav--subnav {
    z-index: 100; }
    .c-main-menu .nav--subnav li:hover {
      background: #ff9a25; }
      .c-main-menu .nav--subnav li:hover li:hover {
        background: #eee;
        border-left: 3px solid #ff9a25;
        padding-left: 13px; }
        .c-main-menu .nav--subnav li:hover li:hover a {
          color: black; }
      .c-main-menu .nav--subnav li:hover > a {
        color: white; }
  .c-main-menu .nav--subnav {
    position: absolute;
    /*padding-top: (map-get($spacing, xs) / 2);
      padding-bottom: (map-get($spacing, xs) / 2);*/
    padding: .5em;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    background: #fff;
    max-width: 18em;
    box-shadow: 0 0.375em 0.75em rgba(78, 78, 78, 0.175);
    margin-top: 0; }
    .c-main-menu .nav--subnav .c-menu-kuhn-gruppe-com__item .c-menu__link {
      display: block;
      padding: 0.25em 0.5em;
      color: #4e4e4e;
      text-decoration: none;
      cursor: pointer; }
    .c-main-menu .nav--subnav .c-menu__link--level2 {
      border-bottom: 1px solid #ccc; }
      .c-main-menu .nav--subnav .c-menu__link--level2:last-child {
        border: 0; }
    .c-main-menu .nav--subnav a,
    .c-main-menu .nav--subnav a:visited {
      display: block;
      padding: 0.25em 0.5em;
      color: #4e4e4e;
      text-decoration: none; }
    .c-main-menu .nav--subnav .nav--subnav {
      position: static;
      box-shadow: none;
      font-size: .95em; }
      .c-main-menu .nav--subnav .nav--subnav li {
        padding-left: 16px; }
  .c-main-menu .c-menu__item:hover .nav--subnav,
  .c-main-menu .c-menu__item:focus .nav--subnav {
    visibility: visible;
    opacity: 1; } }

@media (max-width: 45em) {
  .c-main-menu {
    display: none; } }

.nolink {
  cursor: default; }
  .nolink > .c-menu__link {
    cursor: default; }
  .nolink:hover {
    text-decoration: none !important; }

.c-block-location-pages-block-1 .gmap-map {
  height: 220px !important; }

.c-mega-menu {
  position: absolute;
  right: 0;
  left: 0;
  min-height: 29em;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  z-index: 100; }
  *:hover > .c-mega-menu {
    visibility: visible;
    opacity: 1; }

.c-mega-menu__main-cat {
  position: relative;
  text-transform: uppercase;
  border-bottom: 1px solid #E9E9E9; }

.c-mega-menu__main-cat-item {
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em; }
  .c-mega-menu__main-cat-item:not(:first-child) {
    padding-left: 1em;
    border-left: 1px solid #E9E9E9; }

.c-mega-menu__main-cat-link {
  display: block;
  padding: 1em; }
  .c-mega-menu__main-cat-link, .c-mega-menu__main-cat-link:visited {
    color: #222 !important; }
  .c-mega-menu__main-cat-link.is-active, .c-mega-menu__main-cat-link:hover, .c-mega-menu__main-cat-link:focus {
    color: #fff !important;
    background: #ff9a25;
    text-decoration: none; }

.c-mega-menu__sub-cat {
  position: relative;
  width: 100%;
  padding-right: 100px;
  text-transform: none;
  border-bottom: 1px solid #E9E9E9; }
  .c-mega-menu__sub-cat.application-area-list {
    display: none; }

.c-mega-menu__sub-cat-item {
  display: inline-block;
  padding-top: 0.5em;
  padding-bottom: 0.5em; }

.c-mega-menu__sub-cat-link {
  display: block;
  padding: 0.5em 1em; }
  .c-mega-menu__sub-cat-link, .c-mega-menu__sub-cat-link:visited {
    color: #4e4e4e !important; }
  .c-mega-menu__sub-cat-link.is-active, .c-mega-menu__sub-cat-link:hover, .c-mega-menu__sub-cat-link:focus {
    color: #fff !important;
    background: #898989;
    text-decoration: none; }

.c-mega-menu__mini-teaser {
  display: none;
  min-height: 18rem;
  position: relative;
  overflow: auto;
  padding-top: 1.5em;
  padding-bottom: 1.5em; }
  .c-mega-menu__mini-teaser.is-active {
    display: block; }
  .c-mega-menu__mini-teaser li {
    font-size: 0.8em; }

.c-view-product-main-pages__footer {
  position: absolute;
  top: 2.5em;
  right: 0;
  height: 220px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .c-view-product-main-pages__footer h3, .c-view-product-main-pages__footer p {
    margin-top: .25em;
    color: black !important; }
  .c-view-product-main-pages__footer a {
    color: #ff9a25 !important; }
  .c-view-product-main-pages__footer .c-view-product-main-pages__row {
    width: 240px; }
  .c-view-product-main-pages__footer .c-mega-menu__man-img {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 60px; }

.c-mini-teaser-list li {
  text-align: center;
  width: 48%; }
  @media all and (min-width: 500px) {
    .c-mini-teaser-list li {
      width: 31%; } }
  @media all and (min-width: 1100px) {
    .c-mini-teaser-list li {
      width: 18%; } }

.c-mini-teaser-list a, .c-mini-teaser-list a:visited {
  color: #4e4e4e !important; }

.c-mini-teaser-list {
  width: 100%; }
  @media all and (min-width: 500px) {
    .c-mini-teaser-list {
      padding-right: 240px; } }

/* Christians take on a mobile menu using mmenu */
#block-menu-block-1 {
  display: none; }

#block-menu-menu-domain-selector {
  color: #000; }
  #block-menu-menu-domain-selector > ul {
    padding-left: 40px;
    padding-top: 0; }
    #block-menu-menu-domain-selector > ul:before {
      background: url("../logo.png");
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      width: 100%;
      height: 60px;
      display: block;
      position: relative;
      z-index: 100;
      margin: 10px 0 20px 0; }
  #block-menu-menu-domain-selector ul.c-menu__list > ul.mm-list {
    padding: 0;
    margin-left: 0;
    margin-right: 0; }
  #block-menu-menu-domain-selector .c-menu-domain-selector__link {
    padding: 0 !important; }
  #block-menu-menu-domain-selector .mm-list > li:not(.mm-subtitle):not(.mm-label):not(.mm-search):not(.mm-noresults):after {
    margin-left: 0 !important; }
  #block-menu-menu-domain-selector li.main-menu-item {
    display: none; }
    #block-menu-menu-domain-selector li.main-menu-item.last-main-menu-item {
      margin-bottom: 2em; }
    @media (max-width: 720px) {
      #block-menu-menu-domain-selector li.main-menu-item {
        display: block; } }
  #block-menu-menu-domain-selector .mm-list > li > a, #block-menu-menu-domain-selector .mm-list > li > span {
    padding-left: 0;
    padding: 10px !important; }

.c-domain-switcher__selector i {
  display: none;
  font-size: 2em; }

@media (max-width: 45em) {
  .c-domain-switcher__selector .c-domain-switcher__world, .c-domain-switcher__selector .c-domain-switcher__angle-right {
    display: none; }
  .c-domain-switcher__selector i {
    display: block; } }

@media (max-width: 720px) {
  body.is-not-front .c-main-header__details {
    height: 80px; }
  body.is-not-front .c-yt-channel-switcher {
    padding-top: 24px !important; }
  body.is-not-front .c-main-header__facebook {
    padding-top: 24px !important; }
  body.is-not-front .c-view-locations--block-1 .c-view-locations__content a {
    display: none !important; }
    body.is-not-front .c-view-locations--block-1 .c-view-locations__content a.u-color--yellow {
      display: block !important; }
    body.is-not-front .c-view-locations--block-1 .c-view-locations__content a.u-text-transform--uppercase, body.is-not-front .c-view-locations--block-1 .c-view-locations__content a.c-node-product-highlight__link {
      display: block !important; }
  body.is-not-front .c-view-locations__content a.u-color--yellow {
    display: block !important; }
  body.is-not-front .c-view-locations__content a.u-text-transform--uppercase, body.is-not-front .c-view-locations__content a.c-node-product-highlight__link {
    display: block !important; } }

.c-view-news.slick--slider:after {
  content: " ";
  width: 79px;
  height: 47px;
  background-image: url("../img/icon-drag.png");
  display: block;
  margin: 0 auto;
  background-repeat: no-repeat; }

.c-view-news.slick--slider .slick-prev::before,
.c-view-news.slick--slider .slick-next::before {
  color: #696969; }

button.slick-arrow {
  position: absolute;
  top: 192px; }
  @media (max-width: 30em) {
    button.slick-arrow {
      top: 172px; } }

button.slick-next.slick-arrow {
  z-index: 100;
  left: 0;
  width: 50px;
  height: 50px; }

button.slick-prev.slick-arrow {
  z-index: 100;
  right: 0;
  width: 50px;
  height: 50px; }

/*
 * original: http://codepen.io/AldoFerrari/pen/raayXJ
 */
/* Varaibles - config */
/* SCSS*/
.org-chart {
  display: block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
  /**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
  /* 1 column */
  /* 2 column */
  /* 3 column */
  /* DEPARTMENTS COLUMNs */ }
  .org-chart.cf:before, .org-chart.cf:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */ }
  .org-chart.cf:after {
    clear: both; }
  .org-chart.cf {
    *zoom: 1; }
  .org-chart ul {
    padding: 0;
    margin: 0;
    list-style: none; }
    .org-chart ul li {
      position: relative; }
    .org-chart ul span {
      display: block;
      border: 0 solid #E9E9E9;
      text-align: center;
      overflow: hidden;
      font-size: .8em;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
      transition: all linear .1s;
      background: #898989;
      color: #fff;
      padding: 1em .5em; }
  .org-chart .board {
    display: block;
    position: relative; }
    .org-chart .board:before {
      content: "";
      display: block;
      position: absolute;
      height: 230px;
      width: 0px;
      border-left: 2px solid #E9E9E9;
      margin-left: 49%;
      top: 30px; }
  .org-chart ul.columnOne {
    height: 130px;
    position: relative;
    width: 100%;
    display: block;
    clear: both; }
    .org-chart ul.columnOne li {
      width: 30%;
      margin: 0px auto;
      top: 20px; }
  .org-chart ul.columnTwo {
    position: relative;
    width: 100%;
    display: block;
    height: 90px;
    clear: both; }
    .org-chart ul.columnTwo li:first-child {
      width: 30%;
      float: left; }
    .org-chart ul.columnTwo li {
      width: 30%;
      float: right; }
    .org-chart ul.columnTwo:before {
      content: "";
      display: block;
      position: relative;
      width: 80%;
      height: 10px;
      border-top: 2px solid #E9E9E9;
      margin: 0 auto;
      top: 40px; }
  .org-chart ul.columnThree {
    position: relative;
    width: 100%;
    display: block;
    clear: both; }
    .org-chart ul.columnThree li:first-child {
      width: 30%;
      float: left;
      margin-left: 0; }
    .org-chart ul.columnThree li {
      width: 30%;
      margin-left: 5%;
      float: left; }
    .org-chart ul.columnThree li:last-child {
      width: 30%;
      float: right;
      margin-left: 0; }
    .org-chart ul.columnThree:before {
      content: "";
      display: block;
      position: relative;
      width: 80%;
      height: 10px;
      border-top: 2px solid #E9E9E9;
      margin: 0 auto;
      top: 40px; }
  .org-chart .departments {
    width: 100%;
    display: block;
    clear: both; }
    .org-chart .departments:before {
      content: "";
      display: block;
      width: 79.6%;
      height: 22px;
      border-top: 2px solid #E9E9E9;
      border-left: 2px solid #E9E9E9;
      border-right: 2px solid #E9E9E9;
      top: 0px;
      margin-left: 4px; }
  .org-chart .department {
    border-left: 2px solid #E9E9E9;
    width: 30.8%;
    float: left;
    margin: 0px 4px; }
    .org-chart .department a {
      color: #fff;
      display: block; }
    .org-chart .department:after {
      content: "";
      position: absolute;
      display: block;
      width: 10px;
      height: 22px;
      border-left: 2px solid #E9E9E9;
      left: -1%;
      top: -22px; }
    .org-chart .department:first-child:after {
      display: none; }
    .org-chart .department:last-child:after {
      display: none; }
    .org-chart .department.central:after {
      display: none; }
    .org-chart .department span {
      border-left: 1px solid #E9E9E9; }
    .org-chart .department li {
      padding-left: 25px;
      border-bottom: 2px solid #E9E9E9;
      height: 80px; }
    .org-chart .department li span {
      top: 38px;
      position: absolute;
      z-index: 1;
      width: 95%;
      height: auto;
      vertical-align: middle;
      right: 0px;
      line-height: 14px;
      border: 0 solid #E9E9E9; }
    .org-chart .department .sections {
      margin-top: -20px; }
    .org-chart .department.department--building span {
      background: #ff9a25; }
    .org-chart .department.department--loading span {
      background: #F4C93B; }
    .org-chart .department.department--emco span {
      background: #e30615; }
  .org-chart .department--emco {
    border-left-color: transparent; }
    .org-chart .department--emco:before {
      content: '';
      position: absolute;
      border-right: 2px solid #E9E9E9;
      height: 100%;
      margin-left: 50%; }
    .org-chart .department--emco .section {
      border-bottom-color: transparent; }

/* MEDIA QUERIES */
@media all and (max-width: 1023px) {
  .org-chart .board {
    margin: 0px;
    width: 100%; }
    .org-chart .board:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 49%;
      left: 0;
      border-bottom: 2px solid #E9E9E9; }
  .org-chart .departments:before {
    border: none; }
  .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-bottom: 40px;
    margin-top: -23px; }
    .org-chart .department li {
      height: 70px; }
    .org-chart .department:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 60px;
      border-left: 2px solid #E9E9E9;
      z-index: 1;
      top: -45px;
      left: 0%;
      margin-left: -2px; }
    .org-chart .department:after {
      display: none; }
  .org-chart .department:first-child:before {
    display: none; }
  .org-chart .department--emco {
    border-left-color: #E9E9E9; }
    .org-chart .department--emco:before {
      display: none; }
    .org-chart .department--emco .section {
      border-bottom-color: #E9E9E9; }
  .org-chart ul.columnOne li {
    width: 100%; } }

/*--------- TO BE REMOVED FROM YOUR CSS --*/
/* this is just to display the behaviour of responsive on codepen */
.responsive-content {
  width: 1023px;
  margin: 0px auto; }
  .responsive-content .org-chart .board {
    margin: 0px;
    width: 100%; }
    .responsive-content .org-chart .board:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 49%;
      left: 0;
      border-bottom: 2px solid #E9E9E9; }
  .responsive-content .org-chart .departments:before {
    border: none; }
  .responsive-content .org-chart .department {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-bottom: 40px;
    margin-top: -23px; }
    .responsive-content .org-chart .department li {
      height: 70px; }
    .responsive-content .org-chart .department:before {
      content: "";
      display: block;
      position: absolute;
      width: 15px;
      height: 60px;
      border-left: 2px solid #E9E9E9;
      z-index: 1;
      top: -45px;
      left: 0%;
      margin-left: -2px; }
    .responsive-content .org-chart .department:after {
      display: none; }
  .responsive-content .org-chart .department:first-child:before {
    display: none; }
  .responsive-content .org-chart .department--emco {
    border-left-color: #E9E9E9; }
    .responsive-content .org-chart .department--emco:before {
      display: none; }
    .responsive-content .org-chart .department--emco .section {
      border-bottom-color: #E9E9E9; }
  .responsive-content .org-chart ul.columnOne li {
    width: 100%; }

@media (min-width: 45em) {
  .c-page__content {
    padding-right: 1.5em; } }

@media (min-width: 45em) {
  .c-page__section-bar {
    margin-right: -1.5em; } }

.c-page__sidebar {
  background: #F5F5F5; }
  @media (min-width: 45em) {
    .c-page__sidebar {
      padding-top: 3.5em;
      padding-right: 1.5em; } }

.c-page__content {
  padding-bottom: 5em; }

#sliding-popup {
  background: #e9e9e9 !important; }

.c-popup-content {
  display: flex;
  font-size: 0.9em;
  padding: 0.5em 0;
  align-items: center;
  justify-content: center; }
  .c-popup-content #popup-buttons {
    padding-left: 2em; }
  .c-popup-content .c-button {
    text-transform: none; }

a.popup-modal {
  display: none !important; }

.mfp-hide {
  display: none !important; }

.mfp-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); }
  .mfp-wrap .mfp-inline-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 600px; }

.white-popup-block {
  position: relative;
  background: #fff;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.7); }
  @media (max-width: 720px) {
    .white-popup-block {
      width: 500px; } }
  @media (max-width: 520px) {
    .white-popup-block {
      width: 320px; } }

.popup-modal-dismiss {
  position: absolute;
  top: -15px;
  right: -15px;
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid #ff8f21;
  background: #fff;
  text-indent: -99999px;
  color: transparent; }
  .popup-modal-dismiss:before {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 2px;
    background: #ff8f21;
    top: 12px;
    left: 5px;
    transform: rotate(45deg); }
  .popup-modal-dismiss:after {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 2px;
    background: #ff8f21;
    top: 12px;
    left: 5px;
    transform: rotate(-45deg); }

.more-info a {
  display: block;
  font-weight: 600;
  font-size: 20px;
  color: #222222; }

.user-logged-in .more-info {
  display: block; }

.page-eform-submit-request-appointment .c-page__main-content {
  padding-bottom: 100px; }
  .page-eform-submit-request-appointment .c-page__main-content p {
    font-size: 18px; }
  .page-eform-submit-request-appointment .c-page__main-content .c-form__item--checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 630px; }
    .page-eform-submit-request-appointment .c-page__main-content .c-form__item--checkbox input {
      margin-right: 3px; }
  .page-eform-submit-request-appointment .c-page__main-content .form-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 40px; }
    @media (max-width: 970px) {
      .page-eform-submit-request-appointment .c-page__main-content .form-flex .pre-form-content {
        margin-bottom: 50px; } }

.c-view-products--grid-by-term .o-grid__item .o-product_grid {
  border: 1px solid #898989;
  height: 100%;
  position: relative; }
  .c-view-products--grid-by-term .o-grid__item .o-product_grid a {
    text-decoration: none; }
    .c-view-products--grid-by-term .o-grid__item .o-product_grid a:hover, .c-view-products--grid-by-term .o-grid__item .o-product_grid a:focus {
      text-decoration: none; }
  .c-view-products--grid-by-term .o-grid__item .o-product_grid h3 {
    padding: 0.2em 1em 0.5em 1em; }
  .c-view-products--grid-by-term .o-grid__item .o-product_grid img {
    display: block;
    margin: 0.5em auto 4em auto; }
  .c-view-products--grid-by-term .o-grid__item .o-product_grid span {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.5em 0;
    background-color: #F5F5F5;
    opacity: 0.7; }
    .c-view-products--grid-by-term .o-grid__item .o-product_grid span a {
      padding: 0 1em;
      font-size: 0.8em;
      display: block;
      color: #222; }

.c-block-manufacturers-block-1 .c-view__row a {
  display: block; }

.c-node-used-equipment__ue-kom-entry-id div {
  display: inline-block;
  margin-right: 10px; }

.c-node-pre-built-truck__pre-truck-image .c-node-pre-built-truck__pre-truck-image__item:not(:first-child) {
  padding: 2px 2px 2px 0;
  display: inline-block; }

.c-node-pre-built-truck__pre-truck-image .c-node-pre-built-truck__pre-truck-image__item:not(:nth-child(-1n+2)) {
  padding: 2px; }

/*---
title:   Title
name:    object_title
section: Component
---
*/
#block-block-7 {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  #block-block-7 .config-ad,
  #block-block-7 .shop-ad {
    text-align: center;
    width: 50%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  #block-block-7 h3 {
    display: block;
    width: 400px;
    margin: 0 auto;
    padding-bottom: 15px;
    font-size: 25px; }
  #block-block-7 .config-ad {
    background: #F5F5F5;
    background-image: url(../img/cogs.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover; }
  #block-block-7 .shop-ad {
    background: #FF9A25;
    background-image: url(../img/cart.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain; }
    #block-block-7 .shop-ad h3 {
      color: #fff; }
    #block-block-7 .shop-ad .c-button {
      background: #fff;
      color: #FF9A25; }

@media (max-width: 720px) {
  #block-block-7 {
    flex-direction: column; }
    #block-block-7 .config-ad,
    #block-block-7 .shop-ad {
      width: 100%; } }

.c-read-more {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700; }

@media (max-width: 1024px) {
  #block-kuhn-system-searchform {
    width: 27px !important;
    height: 27px;
    background-color: #ff9a25;
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: -34px;
    margin-top: 6px; }
    #block-kuhn-system-searchform form {
      display: none; }
    #block-kuhn-system-searchform a {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 1.2em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
      #block-kuhn-system-searchform a.search-link::before {
        content: "";
        display: inline-block; } }

@media (max-width: 480px) {
  #block-kuhn-system-searchform {
    width: 24px !important;
    height: 24px;
    background-color: #ff9a25;
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: -31px;
    margin-top: 6px; }
    #block-kuhn-system-searchform form {
      display: none; }
    #block-kuhn-system-searchform a {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 1.2em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased; }
      #block-kuhn-system-searchform a.search-link::before {
        content: "";
        display: inline-block; } }

@media (max-width: 914px) {
  #block-kuhn-system-searchform {
    width: 30%; } }

/*SEARCH RESULT*/
.c-page__main-content .c-view-search {
  /*search form*/
  /*results*/ }
  .c-page__main-content .c-view-search h1 {
    font-size: 50px;
    padding-top: 25px; }
  .c-page__main-content .c-view-search .c-form #edit-s input {
    width: 100%; }
  .c-page__main-content .c-view-search .c-view-search__row {
    border-top: 1px solid #d3d7d9; }
    .c-page__main-content .c-view-search .c-view-search__row:first-child {
      border-top: none; }
    .c-page__main-content .c-view-search .c-view-search__row .views-field-title-field {
      font-size: 1.75rem; }
    .c-page__main-content .c-view-search .c-view-search__row .views-field-type {
      color: #848d95;
      font-size: .8em; }
    .c-page__main-content .c-view-search .c-view-search__row .views-field-search-api-excerpt {
      margin-top: 20px;
      font-size: 18px; }

@media (min-width: 1200px) {
  .c-page__main-content .c-view-search {
    padding: 0; } }

@media (min-width: 800px) {
  /*SEARCH RESULT*/
  .c-page__main-content .c-view-search {
    padding: 0 30px;
    /*search form*/ }
    .c-page__main-content .c-view-search .c-view__widget--submit {
      margin-top: 0 !important; }
    .c-page__main-content .c-view-search .c-form {
      display: inline; }
      .c-page__main-content .c-view-search .c-form .c-view__widget--submit {
        text-align: end;
        margin-left: 0px; } }

@media (max-width: 799px) {
  /*SEARCH RESULT*/
  .c-page__main-content .c-view-search {
    padding: 0 20px;
    /*search form*/ }
    .c-page__main-content .c-view-search .c-form {
      display: flex; }
      .c-page__main-content .c-view-search .c-form .c-view__widget--submit {
        margin-left: 10px; } }

#block-kuhn-system-searchform {
  width: 150px; }
  #block-kuhn-system-searchform form input {
    height: 25px;
    margin-top: 4px; }

#block-kuhn-system-searchform {
  width: 150px; }
  #block-kuhn-system-searchform form input {
    border-radius: 32px;
    padding: .96em 1em; }

.c-view-search {
  padding: 0; }

.c-view-search__row {
  margin: 30px 0; }

.is-front.domain-kuhn-at #block-kuhn-system-searchform {
  display: none; }

.is-front.domain-kuhn-hu #block-kuhn-system-searchform {
  display: none; }

/*---
title:   Section teaser
name:    component_section_teaser
section: Component
---

```example:html
<div class="c-section-teaser o-image-background">
  <img class="o-image-background__image" src="https://placeimg.com/790/275/arch" alt="">
  <div class="o-image-background__content">
    <div class="o-image-background__inner">
      <h1 class="c-section-teaser__title">Lorem Ipsum dolor</h1>
      <a href="#" class="c-section-teaser__button c-button">Lorem ipsum dolor <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
    </div>
  </div>
</div>
```
*/
.c-section-teaser {
  text-align: center; }

.c-section-teaser__title {
  color: #fff;
  text-transform: uppercase;
  font-size: 3em;
  text-shadow: 0 0 0.0125em #4e4e4e; }

.c-section-teaser__button {
  margin-top: 1em; }

.c-site-name {
  max-width: 12em;
  line-height: 1; }

.c-site-group__link {
  position: relative;
  margin: .75em 0 .75em 1em; }
  .c-site-group__link:after {
    content: "\f08e";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-left: 4px;
    font-size: 0.7em;
    position: absolute;
    top: 2px; }
  .c-site-group__link span {
    font-size: 1em; }
  @media (max-width: 45em) {
    .c-site-group__link {
      display: none; } }

.c-slideshow__title {
  text-transform: uppercase;
  font-size: 4em;
  font-weight: 700;
  line-height: 1; }

.c-slideshow__content {
  clear: both;
  display: block; }

.node-type-spare-parts .c-hero {
  height: 47vh; }

@media (min-width: 45em) {
  .node-type-spare-parts .c-hero {
    height: 28.375em; } }

.c-view-spare-parts-imported__content {
  overflow: auto; }

.c-view-spare-parts-imported .c-table, .c-spare-parts--imported .c-table {
  margin-top: 1em;
  margin-right: 3em;
  width: auto;
  float: left; }
  .c-view-spare-parts-imported .c-table td, .c-spare-parts--imported .c-table td {
    padding: 0; }
    .c-view-spare-parts-imported .c-table td .c-field, .c-spare-parts--imported .c-table td .c-field {
      margin: 0.3em 0; }
      .c-view-spare-parts-imported .c-table td .c-field .c-field__label, .c-spare-parts--imported .c-table td .c-field .c-field__label {
        min-width: 135px; }

.spare_parts_imported .c-block-blockify-page-title {
  display: none; }

.c-view-spare-parts-imported .c-view__header {
  font-size: 1.2em;
  margin: 1em 0; }

.c-view-spare-parts-imported .c-view__filters .c-view__widget {
  display: inline-block;
  margin-right: 2em;
  margin-top: 0 !important;
  vertical-align: top; }

.c-view-spare-parts-imported .c-view__filters .c-view__widget--submit {
  margin-top: 1.5em !important; }

.c-view-spare-parts-imported__filters .c-form__label--before {
  display: none; }

/*---
title:   Swipe teaser
name:    object_swipe_teaser
section: Component
---

```example:html
<div class="c-swipe-teaser o-media" style="width: 350px;">
  <div class="c-swipe-teaser__figure o-media__figure">
    <img src="https://placeimg.com/120/100/arch" alt="">
    <small>12. Dezember 2016</small>
  </div>
  <div class="c-swipe-teaser__body o-media__body o-island">
    <h3 class="c-swipe-teaser__title">Lorem Ipsum dolor sit</h3>
    <div class="c-swipe-teaser__content">
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore e...<br />
        <a href="#" class="c-icon c-icon--arrow-right-condensed"><span class="u-visually-hidden">read more</span></a>
      </p>
    </div>
  </div>
</div>
<div class="c-swipe-teaser o-media" style="width: 350px; margin-top: 1.5em;">
  <div class="c-swipe-teaser__figure o-media__figure">
    <img src="https://placeimg.com/120/100/arch" alt="">
    <small>12. Dezember 2016</small>
  </div>
  <div class="c-swipe-teaser__body o-media__body o-island">
    <h3 class="c-swipe-teaser__title">Lorem Ipsum dolor sit</h3>
    <div class="c-swipe-teaser__download">
      <p>Download <small>(PDF 3,5MB)</small></p>
      <div class="c-swipe-teaser__actions">
        <a href="#" class="c-icon c-icon--eye"><span class="u-visually-hidden">view</span></a>
        <a href="#" class="c-icon c-icon--download"><span class="u-visually-hidden">download</span></a>
        <a href="#" class="c-icon c-icon--clock"><span class="u-visually-hidden">??? no idea what this icon is supposed to mean ???</span></a>
      </a>
    </div>
  </div>
</div>
```
*/
.c-swipe-teaser__title {
  color: #ff9a25; }

.c-swipe-teaser__body {
  border-bottom: 3px solid #ff9a25;
  background: #fff; }

.c-swipe-teaser__content {
  margin-top: 1em; }

.c-swipe-teaser__actions {
  margin-top: 0.5em; }
  .c-swipe-teaser__actions * + * {
    margin-left: 0.5em; }

.c-table th {
  text-transform: uppercase; }

.c-table td {
  border-bottom: initial;
  /*!remove*/ }

.c-table a {
  color: #4e4e4e; }

.c-table th.views-align-right, .c-table td.views-align-right {
  text-align: right; }

/*---
title:   Teaser
name:    component_teaser
section: Component
---

```example:html
<div class="c-teaser" style="width: 350px;">
  <img src="https://placeimg.com/350/250/arch" alt="">
  <h3 class="c-teaser__title c-title c-title--main">Title</h3>
  <div class="c-teaser__content">
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
    </p>
    <a href="#" class="c-teaser__read-more">Lorem Ipsum</a>
  </div>
</div>
```
*/
.c-teaser {
  font-size: 0.8em; }

.c-teaser__title {
  margin-top: 1em; }

.c-teaser__content {
  line-height: 1.714; }
  .c-teaser__title + .c-teaser__content {
    margin-top: 1em; }

.c-teaser__read-more {
  text-transform: uppercase;
  font-weight: 700; }

/*---
title: Utility
---

Utility classes that do very specific things (e.g. clearfix).
*/
/*---
title:   Width
name:    utility_width
section: Utility
---

Width classes.
*/
/*---
title:   Variable
name:    utility_width_variable
section: utility_width
order:   3
---

```css
$u-width-namespace: 'u-width';
$u-width-item-count: 12;
$u-width-breakpoints: (s, m, l);
```
*/
/*---
title:   Mixin
name:    utility_width_mixin
section: utility_width
order:   2
---

```css
u-width($-size[, $-item-count: 12])
```
*/
/* extract utility.css */
/* extract utility_width.css */
/*---
title:   Example
name:    utility_width_example
section: utility_width
order:   1
---

```example:html
<div style="text-align: center;">
  <div class="u-width-1" style="background: #fff;padding: 0.5em;">1</div>
  <div class="u-width-2" style="background: #fff;padding: 0.5em;">2</div>
  <div class="u-width-3" style="background: #fff;padding: 0.5em;">3</div>
  <div class="u-width-4" style="background: #fff;padding: 0.5em;">4</div>
  <div class="u-width-5" style="background: #fff;padding: 0.5em;">5</div>
  <div class="u-width-6" style="background: #fff;padding: 0.5em;">6</div>
  <div class="u-width-7" style="background: #fff;padding: 0.5em;">7</div>
  <div class="u-width-8" style="background: #fff;padding: 0.5em;">8</div>
  <div class="u-width-9" style="background: #fff;padding: 0.5em;">9</div>
  <div class="u-width-10" style="background: #fff;padding: 0.5em;">10</div>
  <div class="u-width-11" style="background: #fff;padding: 0.5em;">11</div>
  <div class="u-width-12" style="background: #fff;padding: 0.5em;">12</div>
</div>
```
*/
.u-width-1 {
  width: 8.3333333% !important; }

.u-width-2 {
  width: 16.6666667% !important; }

.u-width-3 {
  width: 25% !important; }

.u-width-4 {
  width: 33.3333333% !important; }

.u-width-5 {
  width: 41.6666667% !important; }

.u-width-6 {
  width: 50% !important; }

.u-width-7 {
  width: 58.3333333% !important; }

.u-width-8 {
  width: 66.6666667% !important; }

.u-width-9 {
  width: 75% !important; }

.u-width-10 {
  width: 83.3333333% !important; }

.u-width-11 {
  width: 91.6666667% !important; }

.u-width-12 {
  width: 100% !important; }

@media (min-width: 30em) {
  .u-width-1\@s {
    width: 8.3333333% !important; }
  .u-width-2\@s {
    width: 16.6666667% !important; }
  .u-width-3\@s {
    width: 25% !important; }
  .u-width-4\@s {
    width: 33.3333333% !important; }
  .u-width-5\@s {
    width: 41.6666667% !important; }
  .u-width-6\@s {
    width: 50% !important; }
  .u-width-7\@s {
    width: 58.3333333% !important; }
  .u-width-8\@s {
    width: 66.6666667% !important; }
  .u-width-9\@s {
    width: 75% !important; }
  .u-width-10\@s {
    width: 83.3333333% !important; }
  .u-width-11\@s {
    width: 91.6666667% !important; }
  .u-width-12\@s {
    width: 100% !important; } }

@media (min-width: 45em) {
  .u-width-1\@m {
    width: 8.3333333% !important; }
  .u-width-2\@m {
    width: 16.6666667% !important; }
  .u-width-3\@m, .u-width-3_at_m {
    width: 25% !important; }
  .u-width-4\@m, .u-width-4_at_m {
    width: 33.3333333% !important; }
  .u-width-5\@m, .u-width-5_at_m {
    width: 41.6666667% !important; }
  .u-width-6\@m, .u-width-6_at_m {
    width: 50% !important; }
  .u-width-7\@m, .u-width-7_at_m {
    width: 58.3333333% !important; }
  .u-width-8\@m, .u-width-8_at_m {
    width: 66.6666667% !important; }
  .u-width-9\@m, .u-width-9_at_m {
    width: 75% !important; }
  .u-width-10\@m {
    width: 83.3333333% !important; }
  .u-width-11\@m {
    width: 91.6666667% !important; }
  .u-width-12\@m {
    width: 100% !important; } }

@media (min-width: 60em) {
  .u-width-1\@l {
    width: 8.3333333% !important; }
  .u-width-2\@l {
    width: 16.6666667% !important; }
  .u-width-3\@l {
    width: 25% !important; }
  .u-width-4\@l {
    width: 33.3333333% !important; }
  .u-width-5\@l {
    width: 41.6666667% !important; }
  .u-width-6\@l {
    width: 50% !important; }
  .u-width-7\@l {
    width: 58.3333333% !important; }
  .u-width-8\@l {
    width: 66.6666667% !important; }
  .u-width-9\@l {
    width: 75% !important; }
  .u-width-10\@l {
    width: 83.3333333% !important; }
  .u-width-11\@l {
    width: 91.6666667% !important; }
  .u-width-12\@l {
    width: 100% !important; } }

/* end extract utility_width.css */
/* end extract utility.css */
.element-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }
  .element-invisible + * {
    margin-top: 0; }

.element-invisible.is-focusable:active,
.element-invisible.is-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.u-font-size-s {
  font-size: 0.8em; }

.u-outline-none {
  outline: none; }

.u-padding-left-xs {
  padding-left: 0.5em !important; }

.u-padding-top-xs {
  padding-top: 0.5em !important; }

.u-spacing-section {
  margin-top: 2rem !important; }

.u-spacing-top-xs {
  margin-top: 0.5em !important; }

.u-spacing-top-s {
  margin-top: 1em !important; }

.u-spacing-top-m {
  margin-top: 1.5em !important; }

.u-spacing-base-whitespace:not(:first-child) {
  margin-top: 1.5em; }

.u-spacing-wrap > :not(:first-child) {
  margin-top: 1.5em !important; }

.u-text-align-center {
  text-align: center; }

.u-text-transform-uppercase {
  text-transform: uppercase; }

.is-front .c-page-title,
.page-taxonomy-term .c-page-title,
body[class*="node-type"] .c-page-title {
  display: none; }

#flexslider-1 {
  overflow: hidden; }

img {
  width: auto;
  max-width: 100%;
  height: auto; }

.c-bean-hotline a, .c-bean-hotline a:visited {
  color: #ff9a25 !important; }

/*
 * Remove the gap between audio, canvas, iframes,
 * images, videos and the bottom of their containers:
 * https://github.com/h5bp/html5-boilerplate/issues/440
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

.TEMP-POSTSCRIPT {
  text-align: center;
  background: #000; }

.c-legal {
  padding: 1em 0;
  background: #ff9a25; }
  .c-legal a,
  .c-legal a:visited {
    color: #fff; }

/**
 * Flexslider
 */
.slides {
  padding: 0; }

p:not(:first-child),
ul:not(:first-child),
ol:not(:first-child),
dl:not(:first-child),
img:not(:first-child),
pre:not(:first-child),
form:not(:first-child),
table:not(:first-child),
figure:not(:first-child),
address:not(:first-child),
fieldset:not(:first-child),
blockquote:not(:first-child),
h1:not(:first-child),
h2:not(:first-child),
h3.c-accordion__title:not(:first-child),
h4:not(:first-child),
h5:not(:first-child),
h6:not(:first-child) {
  margin-top: 0; }

.o-grid--xs {
  margin-left: -map-get((xs: 0.5em, s: 1em, m: 1.5em, l: 2em, xl: 3em, xxl: 4em, xxxl: 5em), xs) !important; }

.c-main-hometeaser {
  /*margin-top: 1.5em;*/ }

.path-location body {
  background: green; }

/* Warum -1.5em gesetzt ist auf o-grid????
Fixes it  */
.o-grid {
  margin-top: 0; }

#edit-category .c-view__widget--label {
  display: none; }

.node-type-news #main {
  margin-bottom: 2em; }
  .node-type-news #main h2 {
    font-size: 1.7em; }
  .node-type-news #main .c-node-news__body {
    margin: 2em 0; }
  .node-type-news #main .file {
    margin-top: 2em;
    display: block; }

.is-front.domain-kuhn-hu .c-postscript {
  margin-top: 10px; }

.c-field-collection-item-pg-youtube-video__youtube-embed-code {
  max-width: 35em;
  margin: 0 auto; }

.c-view-product-main-pages__footer .c-mega-menu__man-img {
  margin-bottom: 35px; }

.mfp-content .c-node-popup__popup-picture img {
  margin-bottom: .5rem; }

.node--977479 .c-hero__content {
  display: none; }

@media all and (min-width: 1024px) {
  .node--982468 .c-node-page__body,
  .node--982583 .c-node-page__body,
  .node--982470 .c-node-page__body,
  .node--982469 .c-node-page__body,
  .node--982584 .c-node-page__body,
  .node--982585 .c-node-page__body,
  .node--982586 .c-node-page__body {
    width: 50%;
    float: left; }
  .node--982468 .c-paragraphs-item-block,
  .node--982583 .c-paragraphs-item-block,
  .node--982470 .c-paragraphs-item-block,
  .node--982469 .c-paragraphs-item-block,
  .node--982584 .c-paragraphs-item-block,
  .node--982585 .c-paragraphs-item-block,
  .node--982586 .c-paragraphs-item-block {
    width: 45%;
    float: left;
    margin-left: 5%;
    margin-top: -60px !important; } }

.oh-display-times {
  padding-left: 3em; }

h3 {
  text-transform: uppercase;
  line-height: 1em;
  margin-top: .75em; }

.u-h4--line:after, .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed h4:after {
  content: "___";
  display: block;
  color: #ff9a25;
  margin-bottom: 1em; }

.c-paragraphs-item-section,
.c-paragraphs-item-teaser-text-only {
  margin-top: 4em;
  margin-bottom: 4em; }

.c-paragraphs-item-teaser-text-only {
  text-align: center; }

.c-paragraphs-item-teaser-text-only__pg-headline {
  margin-top: 0; }

.slick--slider {
  padding-top: 2em;
  padding-bottom: 0;
  cursor: move; }

.c-block-news-block,
.c-block-news-block-carousel-1 {
  position: relative;
  margin-top: 3em;
  background: #F5F5F5 url("../img/icon-drag.png") bottom center no-repeat; }
  .c-block-news-block:after,
  .c-block-news-block-carousel-1:after {
    position: absolute;
    top: 0;
    content: " ";
    background: url(../img/greyshadow.png) top left repeat-y;
    width: 113px;
    height: 100%; }

.c-block-news-block,
.c-block-news-block-carousel-2 {
  position: relative;
  margin-top: 3em;
  background: #F5F5F5 url("../img/icon-drag.png") bottom center no-repeat; }
  .c-block-news-block:after,
  .c-block-news-block-carousel-2:after {
    position: absolute;
    top: 0;
    content: " ";
    background: url(../img/greyshadow.png) top left repeat-y;
    width: 113px;
    height: 100%; }

.c-news__item-wrapper--right {
  margin-right: 1em; }

.c-news__item-wrapper--left,
.c-news__item-wrapper--right {
  float: left;
  cursor: default; }
  .c-news__item-wrapper--left .c-node-news__body,
  .c-news__item-wrapper--right .c-node-news__body {
    text-align: right; }

.c-news__item-wrapper--right {
  background: #fff;
  border-bottom: 3px solid #ff9a25;
  width: 20em;
  padding: 1em; }

.c-news__header {
  margin-bottom: 2em; }

.u-color--black {
  color: black !important; }

.c-news__item--body .c-file__icon {
  display: none; }

.c-news__item--body a {
  color: #000 !important; }

.c-news__item--download {
  height: 1em; }

.c-node-news__news-date {
  font-size: .85em;
  margin-top: .5em; }

.tipsy {
  font-size: 1em; }

.u-text-align--right {
  text-align: right !important; }

.node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed {
  /*
    .o-grid__item:first-child > .c-paragraphs-item-textarea:first-child {
      text-align: left;
    }
    */ }
  .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .c-paragraphs-item-textarea:last-child {
    text-align: center; }
  .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty {
    background: url(../img/checkmark.png) center center no-repeat; }
  .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child .o-grid__item:first-child h2 {
    margin-top: 64px; }
    .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child .o-grid__item:first-child h2:before {
      content: " ";
      position: absolute;
      margin-top: -64px;
      margin-left: -18px;
      width: 64px;
      height: 64px;
      background: url(../img/clock.png) center center no-repeat; }
  .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child h2 {
    margin-top: 64px; }
    .node-type-landing-page .c-node-landing-page .c-paragraphs-item-section.o-island.o-island--xxl.o-island--horizontal-condensed .o-grid .o-grid__item .c-paragraphs-item-grid-fifty-fifty:last-child h2:before {
      content: " ";
      position: absolute;
      margin-top: -64px;
      margin-left: -18px;
      width: 64px;
      height: 64px;
      background: url(../img/tools.png) center center no-repeat; }

.mm-menu.mm-right {
  background: white; }
  .mm-menu.mm-right .c-menu__list a,
  .mm-menu.mm-right .c-menu__list span {
    color: black !important; }
  .mm-menu.mm-right .c-menu-domain-selector__item {
    font-weight: bold;
    text-transform: uppercase; }

.mm-menu .mm-list > li > a.mm-subopen:after,
.mm-menu .mm-list > li > a.mm-subclose:before {
  border-color: black !important; }

.mm-menu.mm-right {
  min-width: 140px !important;
  max-width: 300px !important; }
  .mm-menu.mm-right .c-menu-domain-selector__item .c-menu__item--level2 {
    padding: 0; }
    .mm-menu.mm-right .c-menu-domain-selector__item .c-menu__item--level2 a {
      padding: 0;
      padding-left: 10px;
      color: #ff9a25 !important; }

html.mm-right.mm-opening .mm-slideout {
  -webkit-transform: translate(-80%, 0);
  -moz-transform: translate(-80%, 0);
  -ms-transform: translate(-80%, 0);
  -o-transform: translate(-80%, 0);
  transform: translate(-80%, 0); }

@media all and (max-width: 175px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-140px, 0);
    -moz-transform: translate(-140px, 0);
    -ms-transform: translate(-140px, 0);
    -o-transform: translate(-140px, 0);
    transform: translate(-140px, 0); } }

@media all and (min-width: 550px) {
  html.mm-right.mm-opening .mm-slideout {
    -webkit-transform: translate(-300px, 0) !important;
    -moz-transform: translate(-300px, 0) !important;
    -ms-transform: translate(-300px, 0) !important;
    -o-transform: translate(-300px, 0) !important;
    transform: translate(-300px, 0) !important; } }

#gmapjs2 {
  height: 500px; }

.u-text-align--center {
  text-align: center !important; }

.u-text-transform--uppercase, .c-node-product-highlight__link {
  text-transform: uppercase !important; }

.c-input--address input {
  width: 80%;
  max-width: 500px; }

.c-node-location {
  font-style: normal; }
  .c-node-location a {
    text-transform: uppercase;
    font-weight: bold; }

.c-view-locations {
  margin-bottom: 0em; }

.u-spacing-bottom--xl {
  margin-bottom: 3em; }

.u-spacing-top--xl {
  margin-top: 3em; }

.u-color--yellow {
  color: #ff9a25 !important; }

.group-footer-map {
  max-width: 100%;
  max-height: 265px; }

.c-block-locations-footer,
.c-block-locations-footer-group {
  background: #000;
  color: #fff; }
  .c-block-locations-footer a,
  .c-block-locations-footer-group a {
    color: #fff; }
  .c-block-locations-footer .c-title,
  .c-block-locations-footer-group .c-title {
    font-size: 1em;
    font-weight: normal;
    margin-bottom: .5em;
    color: #fff; }
  .c-block-locations-footer .u-vertical-align--center,
  .c-block-locations-footer-group .u-vertical-align--center {
    display: flex;
    align-items: center;
    justify-content: center; }

.c-officehours {
  margin-top: 1em;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  margin-bottom: 1em;
  padding-top: .75em;
  padding-bottom: .75em; }

.oh-display {
  padding-left: 1.5em; }

.oh-wrapper:before {
  position: absolute;
  content: "\f017";
  font-family: FontAwesome; }

.u-display-inline,
.u-display-inline-block {
  display: inline-block; }

.c-field__label .u-display-inline,
.c-field__label .u-display-inline-block {
  font-weight: bold; }

.c-view-locations--page-all {
  margin-bottom: 3em; }

.is-front .c-main-footer {
  margin-top: 1.5em; }

.c-page {
  background: #fff; }

.c-main-toolbar {
  background: #000;
  height: 40px;
  display: flex; }
  .c-main-toolbar ul li {
    float: left;
    list-style-type: none;
    padding-bottom: 0; }
    .c-main-toolbar ul li:hover {
      background: #ff9a25; }
    .c-main-toolbar ul li a {
      display: inline-block;
      border-right: 1px solid #f3f3f3;
      padding: 1em;
      padding-top: .5em;
      padding-bottom: .5em;
      color: white; }

.u-position-fixed {
  position: fixed; }

.c-node-product-highlight__link {
  font-weight: bold; }

.c-view-used-equipment .c-view__table td:nth-child(2) .c-field {
  margin-top: 24px;
  display: block !important; }

.c-used-equipment .c-page__sidebar {
  text-align: right;
  padding: 1.3em; }

.c-used-equipment .c-node-used-equipment__ue-gallery {
  margin-top: 1em; }

.c-used-equipment .c-node-used-equipment__ue-gallery__item {
  float: left;
  border: 1px solid #ccc;
  background: #fff;
  margin: .1em;
  padding: .1em; }

.c-used-equipment .c-field__label {
  text-transform: uppercase; }

.c-used-equipment .c-node-used-equipment__ue-manufacturer,
.c-used-equipment .c-node-used-equipment__title-field {
  display: inline; }
  .c-used-equipment .c-node-used-equipment__ue-manufacturer:after,
  .c-used-equipment .c-node-used-equipment__title-field:after {
    content: " ";
    display: block; }

.c-used-equipment .c-node-used-equipment__title-field:after {
  content: " ";
  display: block; }

.c-used-equipment .c-node-used-equipment__ue-fl-liftingheight,
.c-used-equipment .c-node-used-equipment__ue-fl-capacity,
.c-used-equipment .c-node-used-equipment__ue-fl-techcond,
.c-used-equipment .c-node-used-equipment__ue-fl-optcond,
.c-used-equipment .c-node-used-equipment__ue-hours,
.c-used-equipment .c-node-used-equipment__ue-year {
  display: inline-block; }
  .c-used-equipment .c-node-used-equipment__ue-fl-liftingheight .c-field__item,
  .c-used-equipment .c-node-used-equipment__ue-fl-capacity .c-field__item,
  .c-used-equipment .c-node-used-equipment__ue-fl-techcond .c-field__item,
  .c-used-equipment .c-node-used-equipment__ue-fl-optcond .c-field__item,
  .c-used-equipment .c-node-used-equipment__ue-hours .c-field__item,
  .c-used-equipment .c-node-used-equipment__ue-year .c-field__item {
    font-weight: bold;
    margin-right: .5em; }

.c-used-equipment .c-node-used-equipment__ue-fl-dealerprice {
  font-weight: bold; }

.c-used-equipment .group-specs-table {
  margin-top: 1.5em;
  display: table; }
  .c-used-equipment .group-specs-table .c-field {
    display: table-row; }
    .c-used-equipment .group-specs-table .c-field .c-field__label,
    .c-used-equipment .group-specs-table .c-field .c-field__item {
      display: table-cell; }
    .c-used-equipment .group-specs-table .c-field .c-field__item {
      font-weight: bold;
      text-align: right; }

.c-node-product__product-brochure__label {
  margin-top: 1em; }

.node-type-product .group-specs-table {
  margin-top: 1.5em;
  display: table; }
  .node-type-product .group-specs-table span.file {
    display: block; }
  .node-type-product .group-specs-table .c-field {
    display: table-row; }
    .node-type-product .group-specs-table .c-field .c-field__label,
    .node-type-product .group-specs-table .c-field .c-field__item {
      display: table-cell; }
    .node-type-product .group-specs-table .c-field .c-field__item {
      font-weight: bold;
      text-align: right; }

.c-table .c-node-used-equipment__ue-fl-capacity,
.c-table .c-node-used-equipment__ue-hours,
.c-table .c-node-used-equipment__ue-fl-dealerprice,
.c-table .c-node-used-equipment__ue-fl-custprice {
  text-align: right; }

.c-table .c-view__field--view-node {
  font-weight: bold; }

.c-table th {
  border-bottom: 3px solid #000;
  padding: 0 .5em; }

.c-table .c-node-used-equipment__ue-year {
  text-align: center; }

.c-view__pager {
  text-align: center;
  border-top: 2px solid #ccc; }

.c-pager {
  display: inline-block;
  text-align: center;
  height: 2em;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  margin-bottom: 3em; }
  .c-pager .c-pager__item {
    float: left;
    list-style-type: none;
    margin-right: .75em;
    padding: .5em;
    background: #f3f3f3; }

@media (min-width: 45em) {
  .is-not-front .c-main-content {
    margin-bottom: 1.5em; } }

.c-page__sidebar {
  padding-bottom: 1.5em; }

#mm-blocker {
  background: rgba(0, 0, 0, 0.7) !important; }

.septem-menu {
  z-index: 10000 !important; }

.c-mega-menu__sub-cat-link {
  color: #4e4e4e !important; }

.flexslider {
  position: relative; }

.flex-direction-nav {
  position: absolute;
  top: 40%;
  width: 100%; }
  .flex-direction-nav li {
    list-style-type: none; }
  .flex-direction-nav .flex-nav-prev {
    position: absolute;
    left: 20px;
    color: #fff;
    visibility: hidden; }
    .flex-direction-nav .flex-nav-prev a:before {
      visibility: visible;
      position: absolute;
      z-index: 0;
      left: 0px;
      font-family: "Themify";
      content: "\e64a";
      font-size: 2em;
      color: white; }
  .flex-direction-nav .flex-nav-next {
    position: absolute;
    visibility: hidden;
    right: 20px;
    color: #fff; }
    .flex-direction-nav .flex-nav-next a:after {
      visibility: visible;
      position: absolute;
      z-index: 0;
      right: 0px;
      font-family: "Themify";
      font-size: 2em;
      color: white;
      content: "\e649"; }

.c-hero__sec-title,
.c-hero__main-title,
.c-hero__third-title,
.c-slideshow__title {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: 700;
  line-height: 1;
  background: rgba(0, 0, 0, 0.6);
  display: inline;
  padding: .1em;
  padding-right: .3em;
  padding-left: .3em;
  float: left;
  clear: both; }

@media (max-width: 45em) {
  .c-hero__content {
    position: absolute;
    bottom: 80px; } }

@media (max-width: 45em) {
  .c-hero__content .c-slideshow__title {
    font-size: 2em; } }

.c-hero__sec-title {
  margin-top: 0.2em; }

.c-page__sidebar .c-paragraphs-item-info-box a {
  font-weight: bold; }

.c-mega-menu__imc {
  position: absolute;
  right: 1em;
  top: 1em;
  text-align: right;
  z-index: 10; }
  .c-mega-menu__imc img {
    transform: rotate(10deg);
    width: 75%; }

.c-block--exp-used-equipment-block-1 {
  padding: 1em;
  padding-top: 0;
  margin-top: 1em;
  margin-bottom: .5em; }
  .c-block--exp-used-equipment-block-1 .c-view__widget--filter-field_ue_kom_komatsu_category_tid {
    display: inline-block;
    margin: .5em; }
  .c-block--exp-used-equipment-block-1 .c-form__item--country label {
    margin-top: 1rem;
    font-size: 20px; }
  .c-block--exp-used-equipment-block-1 .c-form__item--country select {
    font-size: 24px;
    padding: 0.25rem;
    margin-top: 1.1rem;
    margin-left: 2rem;
    /* width: 100%; */
    padding-left: 0.5rem;
    padding-right: 3rem;
    font-weight: bold; }
  .c-block--exp-used-equipment-block-1 .c-view__widget--submit {
    display: inline-block; }

.c-paragraphs-item-download-__pg-download {
  margin-top: 2em; }

.group-specs-table {
  width: 100%; }

.c-paragraphs-item-download-__pg-download__item,
.group-specs-table .file {
  background: #f3f3f3;
  margin: .5em;
  margin-left: 0;
  padding: .5em;
  padding-left: 1em;
  padding-right: 1em; }
  .c-paragraphs-item-download-__pg-download__item span.file-size,
  .group-specs-table .file span.file-size {
    float: right; }

.u-float--right {
  float: right !important; }

.u-text-transform--lowercase {
  text-transform: lowercase; }
  .u-text-transform--lowercase:before {
    content: "("; }
  .u-text-transform--lowercase:after {
    content: ")"; }

.c-block--exp-used-equipment-block-2 {
  padding: 2em;
  margin-bottom: 2em; }
  .c-block--exp-used-equipment-block-2 input {
    padding: .2em;
    width: auto;
    max-width: auto; }
  .c-block--exp-used-equipment-block-2 .c-view__widget--label,
  .c-block--exp-used-equipment-block-2 .c-form__label {
    width: 19%;
    font-weight: bold;
    text-transform: capitalize; }

.c-view__widget--filter-field_ue_fl_masttype_value > label,
.c-view__widget--filter-field_ue_fl_enginetype_value > label,
.c-form__item--field-ue-fl-liftingheight-value-max > label,
.c-form__item--field-ue-fl-capacity-value-max > label {
  display: none; }

.c-view__widget--filter-field_ue_fl_capacity_value,
.c-view__widget--filter-field_ue_fl_liftingheight_value {
  text-transform: lowercase; }
  .c-view__widget--filter-field_ue_fl_capacity_value input,
  .c-view__widget--filter-field_ue_fl_liftingheight_value input {
    width: 78%; }
  .c-view__widget--filter-field_ue_fl_capacity_value .bef-select-as-checkboxes,
  .c-view__widget--filter-field_ue_fl_capacity_value .c-form__item--textfield,
  .c-view__widget--filter-field_ue_fl_liftingheight_value .bef-select-as-checkboxes,
  .c-view__widget--filter-field_ue_fl_liftingheight_value .c-form__item--textfield {
    display: inline-block; }

.c-form__item--bef-checkbox {
  display: inline-block;
  padding: .5em; }

.vertical-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.u-border-bottom--gray {
  border-bottom: 1px solid #ccc;
  padding: 1em;
  padding-bottom: 2em; }

.u-list-indent li {
  padding-left: 2em;
  margin-left: 1em; }

.o-media__figure-rev {
  order: 1; }

.c-field-collection-item-pg-accordion-items__content-blocks__item {
  margin-bottom: 2em; }

.c-node-job__job-category {
  padding-bottom: .5em;
  margin-bottom: .5em;
  border-bottom: 1px solid #ccc;
  margin-top: 2em; }

.c-node-job {
  background: #F5F5F5;
  padding: 1em;
  padding-bottom: .5em;
  margin-bottom: .5em;
  margin-top: 1.5em;
  border-bottom: 1px dotted #ccc; }

.c-node-job:last-child {
  border: 0; }

.c-node-job__job-download {
  padding: 1em;
  margin-top: 1.5em;
  border-top: 1px solid #ff9a25; }

.c-node-location__location-contact-person .c-location-contact-person-label {
  display: inline-block; }

.c-node-location__location-contact-person .c-location-contact-person-data {
  float: right; }

.c-node-location__location-contact-person__label {
  display: block !important; }

.c-node-location__contact-persons-coll__item {
  clear: both; }
  .c-node-location__contact-persons-coll__item .c-field {
    float: left;
    margin-right: 3px; }
  .c-node-location__contact-persons-coll__item .c-field-collection-item-contact-persons-coll__cp-position {
    font-weight: 700; }
    .c-node-location__contact-persons-coll__item .c-field-collection-item-contact-persons-coll__cp-position:after {
      content: ": "; }
  .c-node-location__contact-persons-coll__item .c-field-collection-item-contact-persons-coll__cp-tel {
    float: right; }
  .c-node-location__contact-persons-coll__item .c-field-collection-item-contact-persons-coll__cp-email {
    clear: both; }

.c-node-location__location-office-hours {
  clear: both;
  margin-top: 1em; }

.c-bean-global-blocks__content-blocks-sidebar {
  border: 10px dashed red !important; }

.c-block-locations-footer .oh-display {
  display: inline-block; }

.c-view-pre-built-trucks--block h3 {
  float: none;
  width: 100%;
  clear: both; }

.c-view-pre-built-trucks--block .c-field__label {
  padding-right: 5px;
  font-weight: 600; }

.c-view-pre-built-trucks--block .c-node-pre-built-truck__body {
  min-height: 85px; }

.c-node--pre-built-truck .c-field__label {
  font-weight: bold;
  padding-right: 5px; }

.c-node--pre-built-truck .c-node-pre-built-truck__pre-ft-download {
  margin-top: 5px; }

.c-node--pre-built-truck .c-node-pre-built-truck__pre-fc-download {
  margin-top: 5px; }

.c-node--pre-built-truck .c-node-pre-built-truck__pre-fs-download {
  margin-top: 5px; }

.node-type-product .o-media__body table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: left; }
  .node-type-product .o-media__body table tr:nth-child(odd) {
    background-color: #F5F5F5; }
  .node-type-product .o-media__body table td:first-child {
    font-weight: 700;
    padding: 0.5em 1em; }
  .node-type-product .o-media__body table a {
    color: #4e4e4e; }

.c-page__main-content .c-view__table {
  margin-top: 2em;
  table-layout: fixed;
  width: 100%;
  white-space: nowrap; }
  .c-page__main-content .c-view__table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle; }
    .c-page__main-content .c-view__table td div {
      display: inline-block; }

.c-used-equipment--cranes .c-view__table td:nth-child(1),
.c-used-equipment--cranes .c-view__table th:nth-child(1) {
  width: 20%; }

.c-used-equipment--cranes .c-view__table td:nth-child(2),
.c-used-equipment--cranes .c-view__table th:nth-child(2) {
  width: 40%; }

.c-used-equipment--cranes .c-view__table td:nth-child(3),
.c-used-equipment--cranes .c-view__table th:nth-child(3) {
  width: 18%;
  text-align: center; }

.c-used-equipment--cranes .c-view__table td:nth-child(4),
.c-used-equipment--cranes .c-view__table th:nth-child(4) {
  width: 8%;
  text-align: center; }

.c-used-equipment--cranes .c-view__table td:nth-child(5),
.c-used-equipment--cranes .c-view__table th:nth-child(5) {
  width: 20%;
  text-align: right; }

.c-used-equipment--forklifts .c-view__table td:nth-child(1),
.c-used-equipment--forklifts .c-view__table th:nth-child(1) {
  width: 20%; }

.c-used-equipment--forklifts .c-view__table td:nth-child(2),
.c-used-equipment--forklifts .c-view__table th:nth-child(2) {
  width: 30%; }

.c-used-equipment--forklifts .c-view__table td:nth-child(3),
.c-used-equipment--forklifts .c-view__table th:nth-child(3) {
  width: 10%;
  text-align: center; }

.c-used-equipment--forklifts .c-view__table td:nth-child(4),
.c-used-equipment--forklifts .c-view__table th:nth-child(4) {
  width: 12%;
  text-align: center; }

.c-used-equipment--forklifts .c-view__table td:nth-child(5),
.c-used-equipment--forklifts .c-view__table th:nth-child(5) {
  width: 8%;
  text-align: right; }

.c-used-equipment--forklifts .c-view__table td:nth-child(6),
.c-used-equipment--forklifts .c-view__table th:nth-child(6) {
  width: 20%;
  text-align: right; }

.c-view-used-equipment__content tbody tr:hover {
  background: #FFF4DA;
  cursor: pointer; }

.c-paragraph-admin-info {
  display: none; }

div[class*="c-paragraphs"]:hover .c-paragraph-admin-info {
  position: fixed;
  width: 200px;
  z-index: 1000;
  right: 20px;
  top: 20px;
  opacity: .95;
  border-radius: 16px;
  background: black;
  color: white;
  padding: 1em;
  display: block;
  font-size: 1rem; }

.c-page__main-content .c-mini-teaser-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  list-style-type: none;
  padding-left: 0;
  width: 100%; }
  .c-page__main-content .c-mini-teaser-list li {
    margin: 1%;
    text-align: center;
    box-sizing: border-box; }

.c-page__main-content .c-mega-menu__add {
  display: none; }

.contextual-links-region {
  position: relative; }

.contextual-links-wrapper {
  display: none; }

.c-page__c-page__main-content .contextual-links-wrapper {
  display: none !important;
  visibility: hidden; }

.contextual-links-wrapper {
  display: none;
  position: absolute;
  z-index: 100;
  top: 10px;
  right: 10px;
  padding: .5em;
  background: black;
  border-radius: 1em;
  opacity: .8; }
  .contextual-links-wrapper a {
    color: white; }
  .contextual-links-wrapper .c-link-list__item--views-ui-edit,
  .contextual-links-wrapper .c-link-list__item--node-delete,
  .contextual-links-wrapper .c-link-list__item--block-configure,
  .contextual-links-wrapper .c-link-list__item--manage-display {
    display: none; }

.c-paragraphs-item-info-box__pg-info-box-download.c-button .c-file__link {
  color: white; }

.c-paragraphs-item-info-box__pg-info-box-download.c-button img,
.c-paragraphs-item-info-box__pg-info-box-download.c-button .c-file__size {
  display: none; }

.c-block-menu-domain-selector .mm-menu.mm-vertical .mm-list > li > a.mm-subopen {
  padding-top: 6px; }

.c-block-menu-domain-selector .mm-menu.mm-vertical .mm-list > li > a.mm-subopen:after {
  top: 8px; }

.slick-track {
  padding-right: 2em; }

.ss-medium .ss-circle {
  width: 250px;
  height: 150px;
  box-shadow: none; }

.c-pg-youtube-video-item .c-paragraphs-item-pg-video-carousel__pg-youtube-video__item {
  display: inline-block;
  width: 33%;
  padding: 1%; }
  @media (max-width: 45em) {
    .c-pg-youtube-video-item .c-paragraphs-item-pg-video-carousel__pg-youtube-video__item {
      width: 100%; } }

.ss-not-mobile .c-node-history__history-year {
  display: none; }

@media (max-width: 45em) {
  .ss-not-mobile {
    display: none; }
    .ss-not-mobile .c-node-history__history-year {
      display: none; }
  .ss-mobile {
    text-align: center;
    margin-bottom: 1.5em; }
    .ss-mobile .c-node-history__history-year {
      display: none; } }

#mc_embed_signup input {
  margin-bottom: .5em; }

#views-exposed-form-used-equipment-block-1 {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.c-view__widget--filter-field_ue_ref_domain_tid .c-view__widget--label {
  display: none; }

.c-view__widget--filter-field_ue_ref_domain_tid {
  margin-top: 0 !important; }

.c-form__item--category label {
  margin-top: 0 !important;
  display: block; }

.c-form__item--category select {
  width: 70px; }

div#edit-field-ue-ref-domain-tid {
  margin-top: 0 !important; }

.c-yt-channel-switcher a:hover {
  text-decoration: none; }

.c-yt-channel-switcher .ti-youtube {
  font-size: 36px; }

.c-main-header__facebook a:hover {
  text-decoration: none; }

.c-main-header__facebook .ti-facebook {
  font-size: 36px; }

.configure-badge {
  /* -webkit-transform: rotate(-3deg); */
  /* transform: rotate(-3deg); */
  background-color: #ff9a25;
  color: white;
  padding: 0.5rem;
  /* border-radius: 30px; */
  /* right: 0; */
  font-size: 1rem;
  /* right: 0; */
  margin: 1.5rem 0;
  display: inline-block;
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  /* box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.2); */ }
  .configure-badge:visited {
    color: white; }

.inquiry-button {
  position: fixed;
  bottom: 65px;
  right: 0;
  background: #ff9a25;
  width: 154px;
  text-align: left !important;
  height: auto;
  color: white;
  padding: 10px;
  z-index: 100;
  text-transform: uppercase;
  transition: all;
  transform: translateX(114px);
  transition-duration: 300ms; }
  .inquiry-button:hover {
    transform: translateX(0px);
    text-decoration: none; }

.mx-auto {
  margin: 0 auto; }

.text-center {
  text-align: center; }

.rounded-full {
  border-radius: 9999px;
  overflow: hidden; }

.mt-4 {
  margin-top: 1rem; }

.mb-4 {
  margin-bottom: 1rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.c-node-sales-person__zipcodes__label {
  font-weight: bold; }

.sliding-popup-bottom {
  box-shadow: 0 -200px 15000px 50000px rgba(0, 0, 0, 0.5); }

.c-page__main-content .c-mini-teaser-list {
  justify-content: space-between;
  padding-right: 0 !important; }

.c-node-manufacturer .c-node-manufacturer__work-area {
  display: none; }

.node-type-manufacturer .c-node-manufacturer__title-field a {
  color: #fff; }

.node-type-manufacturer article .c-node-manufacturer__title-field a {
  color: #000; }

/* source-sans-pro-300 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/source-sans-pro-v21-latin-300.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-300.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/source-sans-pro-v21-latin-regular.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/source-sans-pro-v21-latin-700.woff2") format("woff2"), url("../fonts/source-sans-pro-v21-latin-700.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }
