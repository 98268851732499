@import 'title';

/*---
title:   Teaser
name:    component_teaser
section: Component
---

```example:html
<div class="c-teaser" style="width: 350px;">
  <img src="https://placeimg.com/350/250/arch" alt="">
  <h3 class="c-teaser__title c-title c-title--main">Title</h3>
  <div class="c-teaser__content">
    <p>
      Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem.
    </p>
    <a href="#" class="c-teaser__read-more">Lorem Ipsum</a>
  </div>
</div>
```
*/
.c-teaser {
  font-size: map-get($font-sizes, s);
}

.c-teaser__title {
  margin-top: map-get($spacing, s);
}

.c-teaser__content {
  line-height: 1.714;
  .c-teaser__title + & {
    margin-top: map-get($spacing, s);
  }
}

.c-teaser__read-more {
  text-transform: uppercase;
  font-weight: map-get-deep($fonts, a, weights, bold);
}
