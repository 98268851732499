/* HACKY BECAUSE MENU THEMING SUCKS (in Drupal)! */
.nav {
  list-style-type: none;
  padding: 0;
}
#block-menu-block-2 {display: none;}
.c-main-menu {
  @media (min-width: map-get($breakpoints, m)) {
    text-align: right;
  }
  & > .is-active-trail {
    border-bottom: 3px solid map-get($cs, a);
  } 
  
  .c-menu__list--level1 {
    display: inline-block;
    text-align: left;

  }
  .c-menu__list {
    list-style-type: none;
    padding-left: 0;
  }
  .c-menu__item--level1 {
    display: inline-block;
    &:not(:first-child) { 
      margin-left: map-get($spacing, m);
    }
  }
  .c-menu__link--level1 {
    display: block;
    border-bottom: 3px solid transparent;
    padding-top: map-get($spacing, xs);
    padding-bottom: map-get($spacing, xs);
    font-size: map-get($font-sizes, l);
    @media (min-width: map-get($breakpoints, s)) {
      font-size: 1.1em;
    }
    &.is-active,
    &:hover,
    &:focus {
      border-bottom-color: map-get($cs, a);
      text-decoration: none;
    }
  }
  @media (min-width: map-get($breakpoints, m)) {
    .nav--subnav {
      z-index:100;
        li:hover {

          background: map-get($cs, a);
          li:hover {
            background: #eee;
            border-left: 3px solid map-get($cs, a);
            padding-left: 13px;


            a {
              color: black;
            }
          }
          & > a {
            color: white;
          }
        }
    }
    .nav--subnav {
      .c-menu-kuhn-gruppe-com__item .c-menu__link {
    display: block;
    padding: (map-get($spacing, xs) / 2) map-get($spacing, xs);
    color: $font-base-color;
    text-decoration: none;
    cursor: pointer;
  }
      .c-menu__link--level2 {
        border-bottom: 1px solid #ccc;
        &:last-child {
          border: 0;
        }
      }
      position: absolute;
      /*padding-top: (map-get($spacing, xs) / 2);
      padding-bottom: (map-get($spacing, xs) / 2);*/
      padding: .5em;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.2s;
      background: #fff;
      max-width: 18em;
      box-shadow: 0 0.375em 0.75em rgba($font-base-color, 0.175);
      margin-top: 0;
      a,
      a:visited {
        display: block;
        padding: (map-get($spacing, xs) / 2) map-get($spacing, xs);
        color: $font-base-color;
        text-decoration: none;
      }

      .nav--subnav {
        position: static;
        box-shadow: none;

        font-size: .95em;
        li {
          padding-left: 16px;
        }

      }
    }
    .c-menu__item:hover .nav--subnav,
    .c-menu__item:focus .nav--subnav {
      visibility: visible;
      opacity: 1;
    }
  }
   @media (max-width: map-get($breakpoints, m)) {
    display: none;
  }
  
}
.nolink {
  cursor: default;
  &> .c-menu__link, {
    cursor: default;
  }
  &:hover {
    text-decoration: none !important;
  }
}
