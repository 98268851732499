.c-main-header {
  padding-top: map-get($spacing, m);
  padding-bottom: map-get($spacing, m);
  line-height: 1.25;
  &,
  h1,
  h2,
  h3,
  h4,
  button,
  a,
  a:visited {
    color: #222;
  }
  .has-hero & {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(to bottom, #222 0, transparent 100%);
    &,
    h1,
    h2,
    h3,
    h4,
    button,
    a,
    a:visited {
      color: #fff;
    }
  }
}

.c-main-header__logo {
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.c-main-header__site-name {
  margin-left: map-get($spacing, s);
  @media (max-width: map-get($breakpoints, m)) {
    display: none;
  }
}

.c-main-header__details {
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
}

.c-main-header__detail {
  padding: map-get($spacing, xs);
  &:not(:first-child) {
    border-left: 1px solid #222;
  }
  .has-hero & {
    border-left-color: #fff;
  }
  #block-views-locations-block-1 {
    a {
        display: block;
    }
    @media (max-width: map-get($breakpoints, m)) {
      a {
        font-size: 2em;
        font-weight: bold;
        display: inline-block;

        span {
          display: none;
        }
      }
    }
  }
}

.c-main-header__contact {
  text-align: right;
  .domain-kuhn-gruppe-com & {
  padding-top: 1.1em;
}
}

.c-main-header__language {
  display: flex;
  align-items: center;
  .c-language-switcher {
    margin-top: 0;
  }
}

#block-kuhn-system-searchform {
  float: right;
}

#block-views-locations-block-1 {
  float: right;
  margin-left: 10px;
}


@keyframes colorchange {
  0% {
    background: #fff4da;
  }
  25% {
    background: #ffe181;
  }
  50% {
    background: #ffc179;
  }
  75% {
    background: #ffe181;
  }
  100% {
    background: #fff4da;
  }
}
.c-main-header {
  padding-top: 0 !important;
}
.c-page_banner {
  -webkit-animation: colorchange 10s infinite;
  -moz-animation: colorchange 10s infinite;
  -ms-animation: colorchange 10s infinite;
  -o-animation: colorchange 10s infinite;
  animation: colorchange 10s infinite;
  display: inline-block;
  width: 100%;
  padding: 12px 0;
  .o-container {
    text-align: center;
  }
  p {
    display: inline-block;
    margin-top: 8px;
    margin-right: 20px;
    color: black;
  }
  a.c-button {
    color: white;
  }
}
@media only screen and (max-width: 720px) {
  .c-page_banner {
    .o-container {
      text-align: none;
    }
    p {
      width: 100%;
      margin-bottom: 8px;
    }
    a.c-button {
      width: 100%;
    }
  }
}
body.section-baumaschinen,
body.section-ladetechnik {
  .c-yt-channel-switcher {
    padding-top: 19px;
  }
	.c-main-header__facebook {
		padding-top: 19px;
	}
}
