$o-grid-spaced-vertical-sizes: (s);
$o-grid-spaced-horizontal-sizes: (s);

.o-grid__item.u-width-3\@s {
	width: 25% !important;
}
.o-grid__item.u-width-9\@s {
	width: 75% !important; 
}

.o-grid__item.u-width-20 {
	width: 20% !important;
}
 