@import 'avalanchesass_component_button';

// @import 'object/image_background';

/*---
title:   Section teaser
name:    component_section_teaser
section: Component
---

```example:html
<div class="c-section-teaser o-image-background">
  <img class="o-image-background__image" src="https://placeimg.com/790/275/arch" alt="">
  <div class="o-image-background__content">
    <div class="o-image-background__inner">
      <h1 class="c-section-teaser__title">Lorem Ipsum dolor</h1>
      <a href="#" class="c-section-teaser__button c-button">Lorem ipsum dolor <svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg></a>
    </div>
  </div>
</div>
```
*/
.c-section-teaser {
  text-align: center;
}

.c-section-teaser__title {
  color: #fff;
  text-transform: uppercase;
  font-size: 3em;
  text-shadow: 0 0 0.0125em $font-base-color;
}

.c-section-teaser__button {
  margin-top: map-get($spacing, s);
}
