/*---
title:   Image background
name:    object_image_background
section: Object
---

```example:html
<div class="o-image-background">
  <img class="o-image-background__image" src="https://placeimg.com/790/275/arch" alt="">
  <div class="o-image-background__content">
    <div class="o-image-background__inner">
      <!-- your content -->
    </div>
  </div>
</div>
```
*/
.o-image-background {
  position: relative;
}

.o-image-background__image {
  width: 100%;
}

.o-image-background__content {
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.o-image-background__inner {
  flex-grow: 1;
}
