#sliding-popup {
	width: auto !important;
	.popup-content {
		width: auto !important;
		text-align: center;
	}
  padding: 2rem;
  .c-button {
    background-image: none;
    background-color: #ccc;
    border-radius: 0;
    border: none;
    text-shadow: none;
    box-shadow: none;
    padding: .75rem;
    &:hover {
      background-image: none;
    background-color: #ccc;
    border-radius: 0;
    border: none;
    text-shadow: none;
    box-shadow: none;
    padding: .75rem;
    opacity: .8;
    }
  }
  .eu-cookie-compliance-more-button {
		display: none;
  }
	.eu-cookie-compliance-save-preferences-button {
		margin-top: 0.9rem;
	}
  .agree-button,  {
    background: #ff9a25;
    color: white;
  }
  .decline-button,  {
    color: white;
  }
  .eu-cookie-compliance-save-preferences-button {
    color: black;
  }
  .eu-cookie-compliance-categories-buttons, .popup-content #popup-buttons {
    float: none !important;
    padding-left: 0 !important;
    max-width: 100%;
  }
  .eu-cookie-compliance-categories-buttons {
    margin-top: 1rem;
  }

  .eu-cookie-withdraw-tab {
    background: #e9e9e9 !important;
  }
}

.cookie-settings-save {
  background: #ff9a25;
  color: white;
  padding: .5rem;
  margin-top: 1rem;
}

.euccx-toggle-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  div {
    margin-right: .5rem;
  }
  .euccx-switch-wrapper {
    padding-top: .5rem;
  }
}
