.c-button {
  text-transform: uppercase;
}

.c-button--full-width {
  text-align: initial;/*!remove*/
}

.c-button--cs-grey-c {
  &,
  &:hover,
  &:focus,
  &:active,
  &:visited {
    color: #fff;
  }
}

/*---
title:   Multi line
name:    component_button_example_multi_line
section: component_button_example
---

```example:html
<a href="#" class="#{$c-button-namespace} #{$c-button-namespace}--multi-line has-icon"><svg role="img" class="c-icon c-icon--arrow-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Multi line button<br />with icon</a>
```
*/
.c-button--multi-line {
  line-height: 1.1;
  position: relative;
  white-space: normal;
  min-height: 4.9375rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.c-button-big {
	font-size: 200%;
}

.c-button-big a {
	border-radius: 32px;
}
