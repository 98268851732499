.counter {
	text-align: center;
	.counter-icon {
		display: block;
		margin: 1em 0;
    	img {
    		max-width: 50%;
    		height: 70px;
    	}
	}
	.counter-value {
		font-size: 2em;
	}
}