.update-item {
	padding: 0.5em;
	color: #fff;
	// border: 1px solid #ccc; 
	background: #cecece;
	.c-node-kuhn-update__title-field {
		margin-bottom: 0.5em; 
		font-weight: bold;
	}
	.update-cover{
		
	}
	.c-button {
		margin: 0.5em 0 0 0;
		text-align: center;
		cursor: default;
		a {
			padding: 0 1em;
			color: #fff;
			font-size: 1.3em;
			&:hover {
				color: #ffc;
			}
		}
	}
	.c-button:hover, .c-button:focus, .c-button:active {
		background: #ff9a25;
	}
}