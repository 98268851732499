.node-type-spare-parts {
	.c-hero {
		height: 47vh;
	}
	@media (min-width: 45em) {
		.c-hero {
    	height: 28.375em;
		}
	}
}

.c-view-spare-parts-imported__content {overflow: auto;}

.c-view-spare-parts-imported, .c-spare-parts--imported{
  .c-table {
  	margin-top:1em;
  	margin-right: 3em;
  	width: auto;
  	float: left;

  	td {
  		padding: 0;
  		.c-field {
  			margin: 0.3em 0;
  		
  		  .c-field__label{
  			  min-width: 135px;
  		  }
  	    } 
  	}
  }
}

.spare_parts_imported {
  .c-block-blockify-page-title {
    display: none;
  }
}
.c-view-spare-parts-imported {
  .c-view__header {
    font-size: 1.2em; 
    margin: 1em 0;
  }
  .c-view__filters {
    .c-view__widget {
      display: inline-block;
      margin-right: 2em;
      margin-top: 0 !important;
      vertical-align: top;
    }
    .c-view__widget--submit {
        margin-top: 1.5em !important;
    }
  }
}

.c-view-spare-parts-imported__filters {
  .c-form__label--before {
    display: none;
  }
}