/*---
title:   Domain switcher
name:    component_domain_switcher
section: Component
---

```example:html
<div class="c-domain-switcher">
  <button class="c-domain-switcher__selector">
    <span class="c-domain-switcher__world"><svg role="img" class="c-icon c-icon--world"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-world"></use></svg></span>
    <span class="c-domain-switcher__angle-right"><svg role="img" class="c-icon c-icon--angle-right"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-angle-right"></use></svg></span>
  </button>
  <div class="c-domain-switcher__list">
    <ul>
      <li><a href="http://kuhn-gruppe.com/building-machines/komatsu/hydraulikbagger">Kuhn Gruppe</a></li>
      <li><a href="http://kuhn.at/building-machines/komatsu/hydraulikbagger">Kuhn Österreich</a></li>
    </ul>
  </div>
</div>
```
*/
.c-domain-switcher {
  display: flex;
  align-items: center;
}

.c-domain-switcher__selector {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  padding: 0;
  order: 2;
}

.c-domain-switcher__world {
  font-size: 2em;
  margin-left: .2em;
}

.c-domain-switcher__list {
  ul {
    padding: 0;
    list-style-type: none;
  }
  li {
    font-weight: map-get-deep($fonts, a, weights, bold);
    max-width: 6em;
    display: none;
    &.is-active,
    &:nth-child(2) /*TEMPORARY!! REMOVE WHEN ACTIVE STATE WORKS*/ {
      display: block;
    }
  }
}

#block-domain-switcher {
  display: none;
}
