.c-mini-teaser-list {
  li {
    text-align: center;
    width: 48%;

    @media all and (min-width: 500px) {
      width: 31%;
    }

    @media all and (min-width: 1100px) {
      width: 18%;
    }
  }
  a {
    &,
    &:visited {
      color: $font-base-color !important;
    }
  }
}


.c-mini-teaser-list {
  width: 100%; 
  @media all and (min-width: 500px) {
    padding-right:240px; 
  }
}