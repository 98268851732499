@import 'section_bar';

/*---
title:   Breadcrumb
name:    component_breadcrumb
section: Component
---

```example:html
<div class="c-section-bar"> 
  <div class="c-breadcrumb">
    <span class="c-breadcrumb__item c-breadcrumb__item-1">Building Machines</span>
    <span class="c-breadcrumb__item-separator"> / </span>
    <a href="/en/building-machines/komatsu" class="c-breadcrumb__item c-breadcrumb__item-2">Komatsu</a>
    <span class="c-breadcrumb__item-separator"> / </span>
    <span class="c-breadcrumb__item c-breadcrumb__item-title">Hydraulikbagger</span>
  </div>
</div>
```
*/
.c-breadcrumb__item-separator {
  text-indent: -999em;
  border-left: 1px solid #898989;
  display: inline-block;
  width: 1px;
  height: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
  position: relative;
  left: 0.15em;
  transform: skewX(-10deg);
}

.c-breadcrumb__item-1 {
  &,
  & + .c-breadcrumb__item-separator {
    display: none;
  }
}

.c-breadcrumb__item-title {
  font-weight: map-get-deep($fonts, a, weights, bold);
}
