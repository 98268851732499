/*---
title:   Language switcher
name:    component_language_switcher
section: Component
---

```example:html
<ul class="c-language-switcher">
  <li class="c-language-switcher__item">
    <a href="/en/building-machines/komatsu/hydraulikbagger" class="is-active" xml:lang="en">EN</a>
  </li>
  <li class="c-language-switcher__item">
    <span class="c-link-list__link" xml:lang="de">DE</span>
  </li>
</ul>
```
*/
.c-language-switcher {
  padding-left: 0;
  list-style-type: none;
}

.c-language-switcher__item {
  display: inline-block;
  a {
    padding-right: 0.25em;
    padding-left: 0.25em;
    &.is-active,
    &:visited.is-active {
      background: #222;
      color: #fff;
      .has-hero & {
        background: #fff;
        color: #222;
      }
    }
  }
}
