/*---
title: kuhn-gruppe.com
---

This is the style guide for kuhn-gruppe.com
*/
/*---
title: Function
---

Custom SASS functions.
*/
/* No files to import found in function//*.scss */
/**
 * Variable
 *
 * If you want to **only** import the variables from a package import them here.
 */
@import 'variable';
// Base
// e.g. @import 'avalanchesass_base_typography/variable';
/* No files to import found in base//*variable.scss */
// Object
// e.g. @import 'avalanchesass_object_grid/variable';
@import 'object/grid_variable';
@import 'object/island_variable';
@import 'object/media_variable';

// Component
// e.g. @import 'avalanchesass_component_button/variable';
@import 'component/button_variable';
@import 'component/zebra_striping_variable';

// Utility
// e.g. @import 'avalanchesass_utility_clearfix/variable';
@import 'utility/background_variable';
@import 'utility/width_variable';

/**
 * Mixin
 *
 * If you want to **only** import the mixins from a package import them here.
 */
// Base
// e.g. @import 'avalanchesass_base_typography/mixin';
// Object
// e.g. @import 'avalanchesass_object_grid/mixin';
// Component
// e.g. @import 'avalanchesass_component_button/mixin';
// Utility
// e.g. @import 'avalanchesass_utility_clearfix/mixin';



/*---
title: Base
---

Base styles like typography and other global default styles
(mostly unclassed HTML elements).
*/
@import 'normalize/import-now';
// Import your dependencies here.
// e.g. @import 'avalanchesass_base_typography';
@import 'avalanchesass_base_typography';
@import 'avalanchesass_base_box_sizing_reset';
@import 'avalanchesass_base_form';
@import 'base/typography_extend';

/*---
title: Object
---

Non-cosmetic styles (e.g. the famous media object).
*/
// Import your dependencies here.
// e.g. @import 'avalanchesass_object_grid';
@import 'avalanchesass_object_grid';
@import 'avalanchesass_object_island';
@import 'avalanchesass_object_media';
@import 'object/container';
@import 'object/grid_variable';
@import 'object/horizontal_list';
@import 'object/image_background';
@import 'object/island_extend';
@import 'object/island_variable';
@import 'object/media_extend';
@import 'object/media_variable';

/*---
title: Component
---

Concrete, implementation-specific piece of UI.
*/
// Import your dependencies here.
// e.g. @import 'avalanchesass_component_button';
@import 'avalanchesass_component_button';
@import 'avalanchesass_component_hn';
@import 'avalanchesass_component_table';
@import 'avalanchesass_component_zebra_striping';
@import 'component/accordion';
@import 'component/advertisement';
@import 'component/boardmember';
@import 'component/box';
@import 'component/breadcrumb';
@import 'component/button_extend';
@import 'component/button_variable';
@import 'component/counter';
@import 'component/data_lifting_moment';
@import 'component/data_technical_data';
@import 'component/domain_switcher';
@import 'component/eucookiecompliance';
@import 'component/file_table';
@import 'component/form';
@import 'component/hero';
@import 'component/home_teaser';
@import 'component/icon_prefix';
@import 'component/icon';
@import 'component/intro';
@import 'component/kuhn-update';
@import 'component/language_switcher';
@import 'component/main_header';
@import 'component/main_menu';
@import 'component/maps';
@import 'component/mega_menu';
@import 'component/mini_teaser_list';
@import 'component/mobile_menu';
@import 'component/news_carousel';
@import 'component/organisation_chart';
@import 'component/page';
@import 'component/popup';
@import 'component/product_grid';
@import 'component/products';
@import 'component/promoblock';
@import 'component/read_more';
@import 'component/search';
@import 'component/section_bar';
@import 'component/section_teaser';
@import 'component/site_name';
@import 'component/slideshow';
@import 'component/spare_parts';
@import 'component/swipe_teaser';
@import 'component/table_extend';
@import 'component/teaser';
@import 'component/title';
@import 'component/zebra_striping_variable';

/*---
title: Utility
---

Utility classes that do very specific things (e.g. clearfix).
*/
// Import your dependencies here.
// e.g. @import 'avalanchesass_utility_clearfix';
@import 'avalanchesass_utility_background';
@import 'avalanchesass_utility_width';
@import 'utility/background_variable';
@import 'utility/element_invisible';
@import 'utility/font_size';
@import 'utility/outline';
@import 'utility/padding';
@import 'utility/spacing';
@import 'utility/text_align';
@import 'utility/text_transform';
@import 'utility/width_variable';

@import 'shame';
@import 'roland';
