.c-view-products--grid-by-term  {
	.o-grid__item { 
		.o-product_grid {
			border: 1px solid map-get($cs-grey, c);
			height: 100%;
			position: relative;
			a {
				text-decoration: none;
				&:hover, &:focus {
					text-decoration: none;
				}
			}
			h3 {
				padding: 0.2em 1em 0.5em 1em; 
			}
			img {
				display: block;
				margin: 0.5em auto 4em auto;
			}
			span {
				position: absolute;
				bottom: 0;
				left: 0; 
				right: 0; 
				padding: 0.5em 0; 
				background-color: map-get($cs-grey, a); 
				opacity: 0.7;
				a {
					padding: 0 1em; 
					font-size: map-get($font-sizes, s);
					display: block; 
					color: $font-headline-color;
				}
			}

		}
	}
}