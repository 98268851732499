
@media (max-width: 1024px) {
  #block-kuhn-system-searchform {
    width: 27px !important;
    height: 27px;
    background-color: #ff9a25;
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: -34px;
    margin-top: 6px;
    form {
      display: none;
    }
    a {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 1.2em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      &.search-link {
        &::before {
          content: "";
          display: inline-block;
        } 
      }
    }
  }
}
@media (max-width: 480px) {
  #block-kuhn-system-searchform {
    width: 24px !important;
    height: 24px;
    background-color: #ff9a25;
    padding: 4px;
    border-radius: 4px;
    position: absolute;
    margin-left: -31px;
    margin-top: 6px;
    form {
      display: none;
    }
    a {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 1.2em;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      &.search-link {
        &::before {
          content: "";
          display: inline-block;
        }
      }
    }
  }
}
@media (max-width: 914px) {
  #block-kuhn-system-searchform {
    width: 30%;
  }
}

/*SEARCH RESULT*/
.c-page__main-content{
  .c-view-search{

    h1{
      font-size: 50px;
      padding-top: 25px;
    }

    /*search form*/
    .c-form{
      #edit-s{
        input{
          width: 100%;
        }
      }
    }
    /*results*/
    .c-view-search__row{
      border-top: 1px solid #d3d7d9;
      &:first-child{
        border-top: none;
      }

      .views-field-title-field{
        font-size: 1.75rem;
      }
      .views-field-type{
        color: #848d95;
        font-size: .8em;
      }
      .views-field-search-api-excerpt{
          margin-top: 20px;
          font-size: 18px;
      }
    }
  }
}

@media (min-width: 1200px){
  .c-page__main-content{
    .c-view-search{
      padding: 0;
    }
  }
}

@media (min-width: 800px){
  /*SEARCH RESULT*/
  .c-page__main-content{
    .c-view-search{
      padding: 0 30px;

      //margin is set in another css
      .c-view__widget--submit{
        margin-top: 0 !important;
      }

      /*search form*/
      .c-form{
        display: inline;
        .c-view__widget--submit{
          text-align: end;
          margin-left: 0px;
        }
      }
    }
  }
}

@media (max-width: 799px){
    /*SEARCH RESULT*/
    .c-page__main-content{
      .c-view-search{
        padding: 0 20px;
  
        /*search form*/
        .c-form{
          display: flex;
          .c-view__widget--submit{
            margin-left: 10px;
          }
        }
      }
    }
}

  #block-kuhn-system-searchform {
    width: 150px;
    form {
      input {
        height: 25px;
        margin-top: 4px;
      }
    }
  }


#block-kuhn-system-searchform {
  width: 150px;
  form {
    input {
      border-radius: 32px;
      padding: .96em 1em;
    }
  }
}

.c-view-search {
  padding: 0;
}

.c-view-search__row {
  margin: 30px 0;

}


// Custom code for Search apperance	
#block-kuhn-system-searchform {
	.is-front.domain-kuhn-at & {
		display: none;
	}
	.is-front.domain-kuhn-hu & {
		display: none;
	}
}
