@import '../../icons/svg-symbols.css';

/*---
title:   Icon
name:    component_icon
section: Component
---

```example:html
<svg role="img" class="c-icon c-icon--angle-double-right"><use xlink:href="#icon-angle-double-right"></use></svg>
<svg role="img" class="c-icon c-icon--angle-right"><use xlink:href="#icon-angle-right"></use></svg>
<svg role="img" class="c-icon c-icon--angle-down"><use xlink:href="#icon-angle-down"></use></svg>
<svg role="img" class="c-icon c-icon--arrow-right"><use xlink:href="#icon-arrow-right"></use></svg>
<svg role="img" class="c-icon c-icon--clock-o"><use xlink:href="#icon-clock-o"></use></svg>
<svg role="img" class="c-icon c-icon--download"><use xlink:href="#icon-download"></use></svg>
<svg role="img" class="c-icon c-icon--envelope-o"><use xlink:href="#icon-envelope-o"></use></svg>
<svg role="img" class="c-icon c-icon--external-link"><use xlink:href="#icon-external-link"></use></svg>
<svg role="img" class="c-icon c-icon--help-alt"><use xlink:href="#icon-help-alt"></use></svg>
<svg role="img" class="c-icon c-icon--home"><use xlink:href="#icon-home"></use></svg>
<svg role="img" class="c-icon c-icon--layout-grid2-alt"><use xlink:href="#icon-layout-grid2-alt"></use></svg>
<svg role="img" class="c-icon c-icon--layout-list-thumb-alt"><use xlink:href="#icon-layout-list-thumb-alt"></use></svg>
<svg role="img" class="c-icon c-icon--link"><use xlink:href="#icon-link"></use></svg>
<svg role="img" class="c-icon c-icon--map-marker"><use xlink:href="#icon-map-marker"></use></svg>
<svg role="img" class="c-icon c-icon--phone-square"><use xlink:href="#icon-phone-square"></use></svg>
<svg role="img" class="c-icon c-icon--phone"><use xlink:href="#icon-phone"></use></svg>
<svg role="img" class="c-icon c-icon--world"><use xlink:href="#icon-world"></use></svg>
<svg role="img" class="c-icon c-icon--world"><use xlink:href="#icon-user"></use></svg>
```
```example:html
<h3><svg role="img" class="c-icon c-icon--arrow-right c-icon--float-right c-icon--2x"><use xlink:href="#icon-arrow-right"></use></svg>Lorem Ipsum</h3>
```
*/
.c-icon {
  vertical-align: middle;
  position: relative;
  top: -0.125em;
  fill: currentColor;
}

.c-icon--float-right {
  top: -0.04em;
  float: right;
}

.c-icon--2x {
  font-size: 2em;
}
