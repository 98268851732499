.c-site-name {
  max-width: 12em;
  line-height: 1;
}

.c-site-group__link {
  position: relative;
  margin: .75em 0 .75em 1em;
  &:after {
    content: "\f08e";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    padding-left: 4px;
    font-size: 0.7em;
    position: absolute;
    top: 2px
  }
  span {
    font-size: 1em;
  }
  @media (max-width: map-get($breakpoints, m)) {
    display: none;
  }
}
