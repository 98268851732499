
/*
 * original: http://codepen.io/AldoFerrari/pen/raayXJ
 */

/* Varaibles - config */
$linePx: 2px; 
$borderPx: 3px;
$borderPx: 0;
$screen-sm: 1023px;
$color1: #92D4A8;
$color2: green;
$subcolor1: map-get($cs-grey, b);

/* SCSS*/


.org-chart{
  display:block;
  clear: both;
  margin-bottom: 30px;
  position: relative;
  &.cf:before,
  &.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
  }
  &.cf:after {
    clear: both;
  }
/**
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/
&.cf {
  *zoom: 1;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    position: relative;
  }

  span {
    display: block;
    border:  $borderPx solid $subcolor1;
    text-align: center;
    overflow: hidden;
    font-size: .8em;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: all linear .1s;
    background: map-get($cs-grey, c);
    color: #fff;
    padding: 1em .5em;

  }
}



.board{
  display: block;
  position: relative;

  &:before{
    content: "";
    display: block;
    position: absolute;
    height: 230px;
    width: 0px; 
    border-left:  $linePx solid $subcolor1;
    margin-left: 49%;
    top:  30px; 
  }
}
/* 1 column */
ul.columnOne{
  height:130px;
  position: relative;
  width: 100%;
  display: block;
  clear: both;
  li{
    width:30%;
    margin: 0px auto;
    top:20px;
  }
}

/* 2 column */
ul.columnTwo{
  position: relative;
  width: 100%;
  display: block;

  height:90px;
  clear: both;
  li:first-child{
    width:30%;
    float: left;
  }
  li{
    width:30%; 
    float: right;
  }
  &:before{
    content: "";
    display: block;
    position: relative;
    width: 80%;
    height: 10px; 
    border-top:  $linePx solid $subcolor1;
    margin: 0 auto;
    top: 40px;  
  }
}
/* 3 column */
ul.columnThree{ 
  position: relative;
  width: 100%;
  display: block;
  clear: both;
  li:first-child{
    width:30%;
    float: left;
    margin-left:0;
  }
  li{    
    width:30%; 
    margin-left:5%;
    float: left;
  }
  li:last-child{ 
    width:30%;  
    float: right;
    margin-left:0;
  }
  &:before{
    content: "";
    display: block;
    position: relative;
    width: 80%;
    height: 10px; 
    border-top:  $linePx solid $subcolor1;
    margin: 0 auto ;
    top: 40px;  
  }
}
/* DEPARTMENTS COLUMNs */
.departments{ 
  width: 100%;
  display: block;
  clear: both;
  &:before{
    content: "";
    display: block; 
    // width: 64.1%;
    width: 79.6%;
    height: 22px; 
    border-top:  $linePx solid $subcolor1;
    border-left:  $linePx solid $subcolor1;
    border-right:  $linePx solid $subcolor1;
    top:  0px;
    margin-left: 4px;
  }
}
.department{
  border-left:  $linePx solid $subcolor1;
  width: 30.8%;
  float: left;  
  margin: 0px 4px; 
  a {
    color: #fff; 
    display: block;
  }
  &:after{
    content: "";
    position:absolute;
    display: block; 
    width: 10px;
    height: 22px; 
    border-left:  $linePx solid $subcolor1;
    left: -1%;
    top:  -22px; 

  }
  &:first-child{ 
    &:after{
      display:none;
    } 
  }
  &:last-child{
    &:after{
      display:none;
    }
  }
  &.central{

    &:after{
      display:none;
    }
  }
  span{
    border-left:  1px solid $subcolor1;
  }
  li{ 
    padding-left: 25px;
    border-bottom:  $linePx solid $subcolor1;
    height: 80px;
  }
  li span{
    top: 38px;  
    position: absolute;
    z-index: 1;
    width: 95%;
    height: auto;
    vertical-align: middle;
    right:0px; 
    line-height: 14px;
    border:  $borderPx solid $subcolor1;
  }
  .sections{
    margin-top: -20px;
  }

  &.department--building span {
    background: map-get($cs, a);
  }

  &.department--loading span {
    background: map-get($cs, b);
  }

  &.department--emco span {
    background: #e30615;
  }

}


  .department--emco {
    border-left-color: transparent;
    &:before {
      content: '';
      position: absolute;
      border-right:  $linePx solid $subcolor1;
      height: 100%;
      margin-left: 50%;

    }

    .section {
      border-bottom-color: transparent;
    }
  }


}

@mixin responsiveClasses() {
  .org-chart{
    .board{
      margin: 0px;
      width:100%;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 49%;
        left: 0;
        border-bottom:  $linePx solid $subcolor1;
      }
    }
    .departments{
      &:before{
        border:none;
      }
    }
    .department{
      float: none;
      width: 100%;
      margin-left: 0;
      padding-bottom: 40px;
      margin-top: -23px;

      li {
        height: 70px
      }
      &:before{
        content: "";
        display: block;
        position: absolute;
        width: 15px;
        height: 60px;
        border-left:  $linePx solid $subcolor1;
        z-index: 1;
        top: -45px;
        left: 0%;
        margin-left: -2px;
      }
      &:after{
        display: none;
      }
    }
    .department:first-child:before{ display: none;}


    .department--emco {
      border-left-color: $subcolor1;
      &:before {
        display: none;
      }

      .section {
        border-bottom-color: $subcolor1;
      }
    }

    ul.columnOne li {
      width: 100%;
    }
  }
}

/* MEDIA QUERIES */
@media all and (max-width: $screen-sm){
  @include responsiveClasses();
}


/*--------- TO BE REMOVED FROM YOUR CSS --*/
/* this is just to display the behaviour of responsive on codepen */
.responsive-content{
  width: $screen-sm;
  margin:0px auto;
  @include responsiveClasses();

}