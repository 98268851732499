/*---
title:   Hero
name:    component_hero
section: Component
---
*/
/*---
title:   Example
name:    component_hero_example
section: component_hero
---

```example:html
<div class="c-hero" style="background-image: url(http://placehold.it/800x400);">
  <div class="c-hero__content">
    <h1>Lorem Ipsum</h1>
    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo</p>
  </div>
</div>
```
*/
.c-hero {
	position: relative;
  display: flex;
  height: 100vh;
  align-items: center;
  text-align: center;
  color: #fff;
  background-size: cover;
  @media (min-width: map-get($breakpoints, m)) {
    height: 46.375em;
  }
  @media (max-width: map-get($breakpoints, m)) {
    background-position: center;  
  }
}

.c-hero--narrow {
  /*height: 31em;*/
}

.c-hero--align-left {
  text-align: left;
}

.c-hero__content {
  width: 100%;
  .c-hero--narrow & {
    margin-top: map-get($spacing, m);
  }
}

.c-hero__main-title {
  font-size: 5em;
  text-transform: uppercase;
  text-shadow: 0 0 0.0125em $font-base-color;
  font-weight: map-get-deep($fonts, a, weights, bold);
  line-height: 1;
}

.c-hero__sec-title {
  font-size: 3.5em;
  font-weight: map-get-deep($fonts, a, weights, bold);
  line-height: 1;
  color: map-get($cs, b);
}

.c-hero__third-title {
  text-transform: uppercase;
  a,
  a:visited {
    color: #fff;
  }
}
