@import 'avalanchesass_object_island';
//@import 'avalanchesass_object_media';
//@import 'avalanchesass_utility_visible_ding';
//@import 'avalanchesass_component_tooltip';

@import 'icon';

/*---
title:   Swipe teaser
name:    object_swipe_teaser
section: Component
---

```example:html
<div class="c-swipe-teaser o-media" style="width: 350px;">
  <div class="c-swipe-teaser__figure o-media__figure">
    <img src="https://placeimg.com/120/100/arch" alt="">
    <small>12. Dezember 2016</small>
  </div>
  <div class="c-swipe-teaser__body o-media__body o-island">
    <h3 class="c-swipe-teaser__title">Lorem Ipsum dolor sit</h3>
    <div class="c-swipe-teaser__content">
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore e...<br />
        <a href="#" class="c-icon c-icon--arrow-right-condensed"><span class="u-visually-hidden">read more</span></a>
      </p>
    </div>
  </div>
</div>
<div class="c-swipe-teaser o-media" style="width: 350px; margin-top: 1.5em;">
  <div class="c-swipe-teaser__figure o-media__figure">
    <img src="https://placeimg.com/120/100/arch" alt="">
    <small>12. Dezember 2016</small>
  </div>
  <div class="c-swipe-teaser__body o-media__body o-island">
    <h3 class="c-swipe-teaser__title">Lorem Ipsum dolor sit</h3>
    <div class="c-swipe-teaser__download">
      <p>Download <small>(PDF 3,5MB)</small></p>
      <div class="c-swipe-teaser__actions">
        <a href="#" class="c-icon c-icon--eye"><span class="u-visually-hidden">view</span></a>
        <a href="#" class="c-icon c-icon--download"><span class="u-visually-hidden">download</span></a>
        <a href="#" class="c-icon c-icon--clock"><span class="u-visually-hidden">??? no idea what this icon is supposed to mean ???</span></a>
      </a>
    </div>
  </div>
</div>
```
*/
.c-swipe-teaser__title {
  color: map-get($cs, a);
}

.c-swipe-teaser__body {
  border-bottom: 3px solid map-get($cs, a);
  background: map-get($cs-contrast, a);
}

.c-swipe-teaser__content {
  margin-top: map-get($spacing, s);
}

.c-swipe-teaser__actions {
  margin-top: map-get($spacing, xs);
  * + * {
    margin-left: map-get($spacing, xs);
  }
}
