.o-container {
  margin: 0 auto;
  max-width: 74em;
  padding: 0 map-get($spacing, m);
}

.o-container--narrow {
  max-width: 52em;
}

.o-container--wide {
  max-width: 82em;
  padding: 0;
}
