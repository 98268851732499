h2.c-inquiry-form,
h2.c-contact-popup-form {
	margin: 10px 20px;
}

.c-inquiry-form,
.c-contact-popup-form {
	margin: 10px 20px;

}

.c-form__field--name-field-inquiry-current-page {
	display: none;
}

#edit-field-contact-clientemail,
#edit-field-inquiry-to-email {
	display: none !important;
	visibility: hidden !important;
}

.c-block-contactpopup {
	a {
		display: block;
	}
}

.c-block-contact-popup,
.entityform-type-entityform-type {
	label {
		display: block;
	}

	.c-form__required-marker {
		&:before {
			content: "*";
		}
	}

	.captcha {
		margin-bottom: 1em;
	}

}

#cboxTitle {
	font-size: 0.8em;
}

.c-block-epart-store {
	label {
		display: block;
	}

	fieldset {
		border: none;
		padding: 0;

		.c-form__fieldset-content {
			border: 1px solid #ccc;
			padding: 1em;

			label {
				margin-top: 0.5em;
				font-size: 0.8em;
			}
		}

	}

	.c-form__required-marker {
		&:before {
			content: "*";
		}
	}

	.captcha {
		margin-bottom: 1em;
	}
}

#edit-field-newsletter .c-form__label--after {
	display: inline-block;
}

#edit-field-form-privacy {
	.c-form__label--after {
		display: none;
	}

	.c-form__description {
		display: inline-block;
		font-size: 100%;
	}
}

#block-entityform-block-contact {

	.c-form__field--name-field-contact-name,
	.c-form__item--field-contact-last-name-en-0-value,
	.c-form__item--field-contact-phone-en-0-value,
	.c-form__item--field-contact-email-en-0-email,
	.c-form__item--field-contact-company-en-0-value,
	.c-form__item--field-contact-phone-und-0-value,
	.c-form__item--field-contact-last-name-und-0-value,
	.c-form__item--field-contact-company-und-0-value,
	.c-form__item--field-contact-message-en-0-value {
		label {
			width: 160px;
		}
	}
}

.domain-kuhn-at {

	.fm712,
	.sennebogen,
	.closkey,
	.ammann {
		display: none;
	}
}


.domain-kuhn-hu {

	.fm712,
	.sennebogen,
	.closkey,
	.ammann {
		display: none;
	}
}

.domain-kuhn-sk {

	.sennebogen,
	.powerscreen,
	.fm712 {
		display: none;
	}
}

.domain-kuhn-baumaschinen-de {

	.sennebogen,
	.fm712,
	.ammann,
	.powerscreen {
		display: none;
	}
}

.domain-kuhn-gruppe-ch {

	.ammann,
	.closkey,
	.fm712,
	.topcon {
		display: none;
	}
}

.domain-kuhnbohemia-cz {

	.sennebogen,
	.closkey,
	.fm712,
	.powerscreen {
		display: none;
	}
}

.domain-kuhn-hr {

	.sennebogen,
	.closkey,
	.fm712,
	.topcon {
		display: none;
	}
}

.domain-kuhn-si {

	.sennebogen,
	.closkey,
	.fm712,
	.topcon {
		display: none;
	}
}


.topcon,
.powerscreen,
.fm712,
.sennebogen,
.closkey,
.ammann {}

#block-views-exp-used-equipment-block-2 {
	label.c-view__widget--label {
		display: none;
	}
	input, select {
		width: 68%;
	}
	label.c-form__label {
		width: 30%;
		text-transform: none;
	}
	.c-view__widget--filter-field_ue_fl_capacity_value .bef-select-as-checkboxes, .c-view__widget--filter-field_ue_fl_capacity_value .c-form__item--textfield, .c-view__widget--filter-field_ue_fl_liftingheight_value .bef-select-as-checkboxes, .c-view__widget--filter-field_ue_fl_liftingheight_value .c-form__item--textfield {
		width: 100%;
	}
	.c-form__description {
		display: none;
	}
}

@media (max-width: 720px) {
	#block-views-exp-used-equipment-block-2 {
		input, select {
			width: 100%;
		}
		label.c-form__label {
			width: 100%;
		}
	}
}


form#bauma-entityform-edit-form,
form#bauma-de-entityform-edit-form,
form#bauma-ch-entityform-edit-form {
	.c-form__required-marker {
		&:before {
			content: "*";
		}
	}
}

@media all and (max-width: 1024px) {
	form#bauma-entityform-edit-form,
	form#bauma-de-entityform-edit-form,
	form#bauma-ch-entityform-edit-form {
		label {
			display: block;
		}
		.c-form__item--checkbox {
			label {
				display: inline-block;
			}
		}
	}
}
@media all and (min-width: 1024px) {
	form#bauma-entityform-edit-form,
	form#bauma-de-entityform-edit-form,
	form#bauma-ch-entityform-edit-form {
		.c-form__item {
			width: 100%;
		}
		label {
			display: block;
		}
		.c-form__item--checkbox {
			label {
				display: inline-block;
			}
		}
	}
}
