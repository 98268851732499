@import 'icon';
@import 'section_bar';

/*---
title:   Icon prefix
name:    component_icon_prefix
section: Component
---

```example:html
<div style="padding-left:2.875rem;">
  <div class="c-icon-prefix-wrap c-section-bar">
    <h3>Lorem Ipsum</h3>
    <div class="c-icon-prefix">
      <svg role="img" class="c-icon c-icon--layout-list-thumb-alt"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-layout-list-thumb-alt"></use></svg>
    </div>
  </div>
</div>
```
*/
.c-icon-prefix-wrap {
  position: relative;
}

.c-icon-prefix {
  position: absolute;
  top: 0;
  left: -2.875rem;
  width: 2.875rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: map-get($cs-grey, a);

  @media all and (max-width: 1280px) {
    left: auto;
    right: 0;
  }
}
