#sliding-popup {
  background: #e9e9e9 !important;
}

.c-popup-content {
  display: flex;
  font-size: 0.9em;
  padding: 0.5em 0;
  align-items: center;
  justify-content: center;

  #popup-buttons {
    padding-left: 2em;
  }

  .c-button {
    text-transform: none;
  }
}

// Popup Modal
a.popup-modal {
  display: none !important;
}

.mfp-hide {
  display: none !important;
}

.mfp-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);

  .mfp-inline-holder {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 600px;
  }
}

.white-popup-block {
  position: relative;
  background: #fff;
  padding: 20px;
  // width: auto;
  // max-width: 500px;
  margin: 20px auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.7);

  @media (max-width: 720px) {
    width: 500px;
  }

  @media (max-width: 520px) {
    width: 320px;
  }
}

.popup-modal-dismiss {
  position: absolute;
  top: -15px;
  right: -15px;
  display: block;
  width: 30px;
  height: 30px;
  border: 2px solid #ff8f21;
  background: #fff;
  // border-radius: 100%;
  text-indent: -99999px;
  color: transparent;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 2px;
    background: #ff8f21;
    top: 12px;
    left: 5px;
    transform: rotate(45deg);
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    width: 15px;
    height: 2px;
    background: #ff8f21;
    top: 12px;
    left: 5px;
    transform: rotate(-45deg);
  }
}

.more-info {
  // display: none;

  a {
    // position: absolute;
    // bottom: 15px;
    // left: 15px;
    // padding: 20px;
    display: block;
    font-weight: 600;
    font-size: 20px;
    color: #222222;
  }
}

.user-logged-in .more-info {
  display: block;
}

// Contact form
.page-eform-submit-request-appointment {
  .c-page__main-content {
    @extend .o-container;
    padding-bottom: 100px;

    p {
      font-size: 18px;
    }

    .c-form__item--checkbox {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 630px;

      input {
        margin-right: 3px;
      }
    }

    .form-flex {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-top: 40px;

      .pre-form-content {
        @media (max-width: 970px) {
          margin-bottom: 50px;
        }
      }
    }
  }
}

.domain-kuhn-gruppe-ch {
  // #test-modal {
  //   transform: scale(0.85);
  // }
}
