.c-file-table {
  width: 100%;
  text-align: left;
  tr > :last-child {
    text-align: right;
  }
  tbody tr {
    border-top: 1px solid mix(map-get($cs, a), #fff, 50%);
  }
  th, td {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
  }
  // HACK!
  .c-file__size {
    display: none;
  }
}
