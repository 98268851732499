/*---
title:   Title
name:    object_title
section: Component
---
*/

#block-block-7 {
  display: flex;
  justify-content: space-between;
  align-items: center;



  .config-ad,
  .shop-ad {
    text-align: center;
    width: 50%;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h3 {
    display: block;
    width: 400px;
    margin: 0 auto;
    padding-bottom: 15px;
    font-size: 25px;
  }


  .config-ad {
    background: #F5F5F5;
    background-image: url(../img/cogs.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .shop-ad {
    background: #FF9A25;
    background-image: url(../img/cart.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    h3 {
      color: #fff;
    }

    .c-button {
      background: #fff;
      color: #FF9A25;
    }
  }
}


@media(max-width: 720px) {
  #block-block-7 {
    flex-direction: column;

    .config-ad,
    .shop-ad {
      width: 100%;
    }
  }
}