h1,
h2,
h3,
h4 {
  color: #222;
}

h4 {
  + * {
    margin-top: map-get($spacing, xs);
  }
}
